import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { PresenterService, InteractiveModuleSessionService, AuthService } from '../../services';
import { InteractiveModuleSessionModel, PresenterModel, UserModel } from '../../models';
import { FormFunctions } from '../../functions';
import { Validations } from '../../constants'

@Component({
    selector: 'modal-edit-session-request',
    templateUrl: 'modal-edit-session.component.html'
})
export class ModalEditSessionComponent {
    @Input() entity: InteractiveModuleSessionModel;
    presenters: PresenterModel[] = [];
    supervisors: UserModel[] = [];
    processing: boolean = false;
    showSuccess: boolean = false;

    constructor(public formFunctions: FormFunctions, public validations: Validations, public activeModal: NgbActiveModal, public presenterService: PresenterService, public interactiveModuleSessionService: InteractiveModuleSessionService, public authService: AuthService) {
        let me = this;
    }

    ngOnInit() {
        let me = this;

        forkJoin(
            me.presenterService.FindAll(),
            me.authService.FindByRole('Supervisor')
        ).subscribe(([data1, data2]) => {
            me.presenters = data1;
            me.supervisors = data2;
        });
    }

    SubmitRequest(form, e) {
        let me = this;

        if (this.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            me.interactiveModuleSessionService.UpdatePresenter(me.entity).subscribe(data => {
                me.showSuccess = true;
                me.processing = false;
            }, err => {
                me.showSuccess = true;
                me.processing = false;
            });
        }
    }
}

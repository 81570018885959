import { InteractiveModuleModel, InteractiveModuleSessionModel, InteractiveModuleEnrolmentStudentModel } from './';

export class SchoolMyEnrolmentsProjectionModel {
    Date: Date;
    Sessions: SchoolMyEnrolmentsSessionProjectionModel[];

    constructor() {
    }
}

export class SchoolMyEnrolmentsSessionProjectionModel {
    Session: InteractiveModuleSessionModel;
    Enrolments: InteractiveModuleEnrolmentStudentModel;

    constructor() {
    }
}
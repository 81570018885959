<div class="modal-header bg-dark text-white">
  <h4 class="modal-title">Create Zoom Sessions</h4>
  <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Creating zoom records for <strong>{{sessionIds.length}}</strong> scheduled sessions</p>
  <div class="form-group">
    <label class="control-label">Zoom Account</label>
    <select class="form-control" [(ngModel)]="request.ZoomAccountId">
      <option value="0">Select a zoom account</option>
      <option *ngFor="let zoomAccount of zoomAccounts" [value]="zoomAccount.Id">{{zoomAccount.Title}}</option>
    </select>
  </div>

  <div class="form-check">
    <input class="form-check-input" type="checkbox" [(ngModel)]="request.CreateForEachSession" id="chkCreateForEachSession" name="chkCreateForEachSession">
    <label class="form-check-label" for="chkCreateForEachSession">
      Create 1 zoom session for each of the selected program sessions ({{sessionIds.length}} zoom sessions)?
    </label>
    <small>Leave this option un-ticked if you are wanting to use breakout rooms for the selected sessions</small>
  </div>

  <div class="form-group" *ngIf="!request.CreateForEachSession">
    <label class="control-label">Override Zoom Session Title</label>
    <input type="text" class="form-control" [(ngModel)]="request.TitleOverride">
    <small>The program title is the default value. Use this when scheduling sessions with breakout rooms.</small>
  </div>

  <div class="alert alert-success mt-2" *ngIf="success">
    <p>Sessions successfully created.</p>
  </div>
  <div class="alert alert-sdanger mt-2" *ngIf="error">
    <p>There was a problem creating the zoom sessions.</p>
    <div>{{errorMessage}}</div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-gateways" (click)="CreateZoomSessions()" *ngIf="!processing && !success">Create Sessions</button>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')" *ngIf="!processing">Close</button>
  <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
</div>

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import { InteractiveModuleTemplateResourceModel, GenericResponseModel } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class InteractiveModuleTemplateResourceService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindAll(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleTemplateResourceModel[]>(this.appConfig.getConfig('apiUrl') + '/interactive-module-template-resources', { withCredentials: true, headers: headers });
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleTemplateResourceModel>(this.appConfig.getConfig('apiUrl') + '/interactive-module-template-resources/' + id, { withCredentials: true, headers: headers });
    }
    FindByTemplateId(templateId: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleTemplateResourceModel>(this.appConfig.getConfig('apiUrl') + '/interactive-module-template-resources/by-template/' + templateId, { withCredentials: true, headers: headers });
    }
    Create(item: InteractiveModuleTemplateResourceModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<InteractiveModuleTemplateResourceModel>(this.appConfig.getConfig('apiUrl') + '/interactive-module-template-resources', params, { withCredentials: true, headers: headers });
    }
    Edit(item: InteractiveModuleTemplateResourceModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<InteractiveModuleTemplateResourceModel>(this.appConfig.getConfig('apiUrl') + '/interactive-module-template-resources/' + item.Id, params, { withCredentials: true, headers: headers });
    }
    Delete(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.delete<GenericResponseModel>(this.appConfig.getConfig('apiUrl') + '/interactive-module-template-resources/' + id, { withCredentials: true, headers: headers });
    }
}
// Top level pages
export * from './page-home/page-home.component';
export * from './page-login/page-login.component';
export * from './page-register/page-register.component';
export * from './page-forgot-password/page-forgot-password.component';
// Parent pages
export * from './parent';
// Student Pages
export * from './student';
// Presenter Pages
export * from './presenter';
// Learning Module pages
export * from './learning-module';
// Interactive Module pages
export * from './interactive-module';
// Administrator pages
export * from './admin';
// School pages
export * from './school';
// Supervisor pages
export * from './supervisor';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { StateService, InteractiveModuleService, PresenterService } from '../../../services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { InteractiveModuleModel, PresenterModel, GenericLookupModel } from '../../../models';
import { } from 'src/app/components';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'page-interactive-module-item',
    templateUrl: './page-interactive-module-item.component.html',
})
export class PageInteractiveModuleItemComponent implements OnInit {
    loading: boolean = true;
    modules: InteractiveModuleModel[] = [];
    filterModules: InteractiveModuleModel[] = [];
    presenters: any[] = [];
    filter: any = {};
    days: GenericLookupModel[] = [];
    times: GenericLookupModel[] = [];

    constructor(private router: Router, private activatedRoute: ActivatedRoute, public stateService: StateService, public interactiveModuleService: InteractiveModuleService, private modalService: NgbModal, public presenterService: PresenterService) {
        var me = this;
        var templateUrl = me.activatedRoute.snapshot.params.urlName;

        // Days filter
        me.days.push(new GenericLookupModel(0, 'Sunday'))
        me.days.push(new GenericLookupModel(1, 'Monday'))
        me.days.push(new GenericLookupModel(2, 'Tuesday'))
        me.days.push(new GenericLookupModel(3, 'Wednesday'))
        me.days.push(new GenericLookupModel(4, 'Thursday'))
        me.days.push(new GenericLookupModel(5, 'Friday'))
        me.days.push(new GenericLookupModel(6, 'Saturday'))
        // Time filter
        me.times.push(new GenericLookupModel('am', 'AM'));
        me.times.push(new GenericLookupModel('pm', 'PM'));

        forkJoin([
            me.interactiveModuleService.FindByTemplateUrl(templateUrl),
            me.presenterService.FindAll()
        ]).subscribe(([data1, data2]) => {
            me.modules = data1;
            me.filterModules = data1;
            me.presenters = data2.map(item => {
                return {
                    "id": item.Id,
                    "name": item.Firstname + ' ' + item.Surname
                }
            });
            me.loading = false;
        });
    }

    ngOnInit() {
        var me = this;
    }

    Filter() {
        let me = this;
        let items = me.modules;

        if (me.filter.PresenterId) {
            items = items.filter(o => (o.InteractiveModuleSessions.filter(j => j.PresenterId == me.filter.PresenterId).length > 0));
        }
        if (me.filter.Day) {
            items = items.filter(o => (o.InteractiveModuleSessions.filter(j => new Date(j.StartDateTime).getDay() == me.filter.Day).length > 0));
        }
        if (me.filter.Time) {
            items = items.filter(o => (o.InteractiveModuleSessions.filter(j => (new Date(j.StartDateTime).getHours() >= 12 ? 'pm' : 'am') == me.filter.Time).length > 0));
        }

        me.filterModules = items;
    }

    ResetFilter() {
        let me = this;
        me.filter = {};
        me.filterModules = me.modules;
    }
}

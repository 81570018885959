import { InteractiveModuleModel, UserModel, ZoomAccountModel, PresenterModel } from './';

export class InteractiveModuleSessionModel {
    Id: number;
    InteractiveModuleId: number;
    PresenterId: number;
    SupervisorUserId: number;
    ZoomAccountId: number;
    WelcomeDateTime: Date;
    StartDateTime: Date;
    FinishDateTime: Date;
    SessionLink: string;
    SessionLinkHost: string;
    ExternalSessionId: string;
    ExternalSessionCreated: boolean;

    InteractiveModule: InteractiveModuleModel;
    Presenter: PresenterModel;
    SupervisorUser: UserModel;
    ZoomAccount: ZoomAccountModel;

    constructor() {
        this.SessionLink = "";
        this.SessionLinkHost = "";
        this.ExternalSessionId = "";
    }
}
<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12">
      <h2>{{modules[0].Title}}</h2>
      <h4>Price: ${{modules[0].Price|number:'1.2-2'}}</h4>
      <p>{{modules[0].Description}}</p>

      <div class="row" *ngIf="!loading">
        <div class="col-sm-4">
          <div class="form-group">
            <websilk-select-field id="selPresenter" name="selPresenter" [bindItems]="presenters" [(ngModel)]="filter.PresenterId" labelText="Presenter" idKey="id" descKey="name" required="false" displayInline="false"></websilk-select-field>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <websilk-select-field id="selDays" name="selDays" [bindItems]="days" [(ngModel)]="filter.Day" labelText="Day" idKey="Id" descKey="Description" required="false" displayInline="false"></websilk-select-field>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <websilk-select-field id="selTime" name="selTime" [bindItems]="times" [(ngModel)]="filter.Time" labelText="Time" idKey="Id" descKey="Description" required="false" displayInline="false"></websilk-select-field>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label class="control-label">&nbsp;</label>
            <div>
              <button class="btn btn-dark" (click)="Filter()"><i class="fa fa-filter"></i> Filter</button>
              &nbsp;
              <button class="btn btn-light" (click)="ResetFilter()"><i class="fa fa-undo"></i> Reset</button>
            </div>
          </div>
        </div>
      </div>

      <h3>Available Instances</h3>
      <div class="alert alert-info" *ngIf="filterModules.length==0">
        No available instances matching your filters
      </div>
      <div *ngFor="let module of filterModules">
        <table class="table">
          <thead>
            <tr>
              <th>Day</th>
              <th>Date</th>
              <th>Start time</th>
              <th>Finish time</th>
              <th>Presenter</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let session of module.InteractiveModuleSessions">
              <td>{{session.StartDateTime|date:'EEEE'}}</td>
              <td>{{session.StartDateTime|date:'dd/MM/yyyy'}}</td>
              <td>
                {{session.StartDateTime|date:'HH:mm'}}
              </td>
              <td>
                {{session.FinishDateTime|date:'HH:mm'}}
              </td>
              <td>
                {{session.Presenter.Firstname}} {{session.Presenter.Surname}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</div>

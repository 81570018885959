import { Component, Input, Output, forwardRef, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StateService, ParentService, StudentService, YearLevelService, SchoolService } from '../../services';
import { WebsilkTextField } from '../../components';
import { ParentRegisterRequest, StudentCreateRequest, YearLevelModel, GenericLookupModel, ParentRegisterWithStudentsRequestModel, SchoolModel } from '../../models';
import { FormFunctions, CustomDateParserFormatter } from '../../functions';
import { Validations } from '../../constants'
import { forkJoin } from 'rxjs';

@Component({
    selector: 'websilk-register',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WebsilkRegisterComponent),
            multi: true
        }
    ],
    template:
        `
        <websilk-loading *ngIf="subLoading" displayText="Loading ... Please wait"></websilk-loading>
        <form #thisForm="ngForm" *ngIf="!subLoading">
            <div>
                <h4>Your details</h4>
                <websilk-text-field id="txtFirstname" name="txtFirstname" [(ngModel)]="request.Firstname" displayInline="{{inlineFormDisplay}}" labelText="First name" required="true" pattern="" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9"></websilk-text-field>
                <websilk-text-field id="txtSurname" name="txtSurname" [(ngModel)]="request.Surname" displayInline="{{inlineFormDisplay}}" labelText="Surname" required="true" pattern="" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9"></websilk-text-field>
                <websilk-text-field id="txtEmail" name="txtEmail" [(ngModel)]="request.Email" displayInline="{{inlineFormDisplay}}" labelText="Email" required="true" pattern="{{validations.EMAIL}}" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" fieldType="email" errorText="Must be a valid email address"></websilk-text-field>
                <websilk-text-field id="txtPhoneNumber" name="txtPhoneNumber" [(ngModel)]="request.PhoneNumber" displayInline="{{inlineFormDisplay}}" labelText="Contact Phone" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" errorText="Phone number required"></websilk-text-field>
                <websilk-text-field id="txtPassword" name="txtPassword" [(ngModel)]="request.Password" displayInline="{{inlineFormDisplay}}" labelText="Password" required="true" pattern="" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" fieldType="password"></websilk-text-field>
                <websilk-text-field id="txtConfirmPassword" name="txtConfirmPassword" [(ngModel)]="request.ConfirmPassword" displayInline="{{inlineFormDisplay}}" labelText="Confirm" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" [pattern]="'^'+this.request.Password+'$'" fieldType="password" errorText="Must match password"></websilk-text-field>
            </div>

            <div *ngIf="includeStudent">
                <h4>Child details</h4>
                <websilk-text-field id="txtFirstname" name="txtFirstname" [(ngModel)]="createStudent.Firstname" displayInline="{{inlineFormDisplay}}" labelText="First name" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" pattern=""></websilk-text-field>
                <websilk-text-field id="txtSurname" name="txtSurname" [(ngModel)]="createStudent.Surname" displayInline="{{inlineFormDisplay}}" labelText="Surname" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" pattern=""></websilk-text-field>
                <websilk-date-field id="dtDateOfBirth" name="dtDateOfBirth" [(ngModel)]="createStudent.DateOfBirth" displayInline="{{inlineFormDisplay}}" labelText="Date of Birth" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9"></websilk-date-field>
                <websilk-select-field id="selGender" name="selGender" [bindItems]="genders" [(ngModel)]="createStudent.Gender" labelText="Gender" idKey="Id" descKey="Description" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" displayInline="{{inlineFormDisplay}}"></websilk-select-field>
                <websilk-select-autocomplete-field id="selSchool" name="selSchool" [bindItems]="schoolList" [(ngModel)]="createStudent.SchoolId" labelText="School" idKey="Id" descKey="Name" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" displayInline="{{inlineFormDisplay}}"></websilk-select-autocomplete-field>
                <websilk-select-field id="selYearLevel" name="selYearLevel" [bindItems]="yearLevels" [(ngModel)]="createStudent.YearLevelId" labelText="Year Level" idKey="Id" descKey="Title" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" displayInline="{{inlineFormDisplay}}"></websilk-select-field>
            </div>

            <div>
              <button type="button" class="btn btn-gateways" (click)="Register(thisForm,$event)" *ngIf="!processing">Register</button>
              <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
            </div>
            <div class="alert alert-danger mt-2" *ngIf="errorMessage!=''">{{errorMessage}}</div>
          </form>
  `
})
export class WebsilkRegisterComponent implements OnInit {
    subLoading: boolean = true;
    request: ParentRegisterRequest = new ParentRegisterRequest();
    createStudent: StudentCreateRequest = new StudentCreateRequest();
    processing: boolean = false;
    yearLevels: YearLevelModel[] = [];
    schools: SchoolModel[] = [];
    schoolList: any[] = [];
    genders: GenericLookupModel[] = [];
    errorMessage: string = "";

    @Input() successRedirect: string = '';
    @Input('includeStudent') _includeStudent: string;
    get includeStudent() {
        return this._includeStudent.toLowerCase() == "true";
    };
    @Input('inlineFormDisplay') _inlineFormDisplay: string;
    get inlineFormDisplay() {
        return this._inlineFormDisplay.toLowerCase() == "true";
    };
    @Output() onSuccess: EventEmitter<any> = new EventEmitter();

    constructor(public router: Router, public stateService: StateService, public parentService: ParentService, public validations: Validations, public formFunctions: FormFunctions, public studentService: StudentService, public yearLevelService: YearLevelService, public schoolService: SchoolService) {
        let me = this;
        // Genders
        me.genders.push(new GenericLookupModel("M", "Male"));
        me.genders.push(new GenericLookupModel("F", "Female"));
        me.genders.push(new GenericLookupModel("U", "Unspecified"));
        // Data loading
        forkJoin(
            me.yearLevelService.FindAll(),
            me.schoolService.FindAll()
        ).subscribe(([data1, data2]) => {
            me.yearLevels = data1;
            me.schools = data2;

            me.schoolList = data2.map(item => {
                return {
                    "Id": item.Id,
                    "Name": item.Name + ' (' + item.State + ' ' + item.Postcode + ')'
                }
            });

            me.subLoading = false;
        });
    }

    ngOnInit() {

    }
    confirmPasswordPattern() {
        return '/^' + this.request.Password + '$/';
    }
    Register(form, e) {
        var me = this;
        me.errorMessage = "";
        if (me.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            if (me.includeStudent) {
                let parentStudentRequest = new ParentRegisterWithStudentsRequestModel();
                parentStudentRequest.ParentRequest = me.request;
                parentStudentRequest.StudentRequests = [me.createStudent];
                me.parentService.RegisterWithStudents(parentStudentRequest).subscribe(data => {
                    if (me.successRedirect != '') {
                        me.router.navigate([me.successRedirect])
                    } else {
                        // Emit the parent Id so we can create an enrolment record
                        me.onSuccess.emit([data.Id, me.request.Email, me.request.Password]);
                        me.processing = false;
                    }
                }, err => {
                    me.processing = false;
                    me.errorMessage = err.error;
                });
            } else {
                me.parentService.Register(me.request).subscribe(data => {
                    if (me.successRedirect != '') {
                        me.router.navigate([me.successRedirect])
                    } else {
                        // Emit the parent Id so we can create an enrolment record
                        me.onSuccess.emit([data.Id, me.request.Email, me.request.Password]);
                        me.processing = false;
                    }
                }, err => {
                    me.processing = false;
                    me.errorMessage = err.error;
                });
            }


        }
    }
}




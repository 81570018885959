import { Component, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StateService, AcademicFocusService } from '../../../services';
import { FormFunctions } from '../../../functions';
import { AcademicFocusModel, UpdateImageRequestModel } from '../../../models';

import { ModalUploadImageComponent } from '../../../components';

@Component({
    selector: 'page-admin-academic-focuses',
    templateUrl: './page-admin-academic-focuses.component.html',
})
export class PageAdminAcademicFocusesComponent implements OnInit {
    loading: boolean = true;
    processing: boolean = false;
    entities: AcademicFocusModel[] = [];
    entity: AcademicFocusModel = new AcademicFocusModel();

    constructor(private modalService: NgbModal, public stateService: StateService, private formFunctions: FormFunctions, public academicFocusService: AcademicFocusService) {
        var me = this;
        me.LoadEntities();
    }

    ngOnInit() {
        var me = this;
    }

    LoadEntities() {
        let me = this;
        me.loading = true;
        me.academicFocusService.FindAll().subscribe(data => {
            me.entities = data;
            me.loading = false;
        });
    }

    Edit(item: AcademicFocusModel) {
        let me = this;
        var clone = <AcademicFocusModel>me.formFunctions.DeepCopy(item);
        me.entity = clone;
    }

    GetImageUrl(item) {
        var me = this;
        let url = '';
        if (me.entity) {
            url = 'https://gateways-online-content.s3-ap-southeast-2.amazonaws.com/' + item.StorageFolderName + '/' + item.TileImageName;
        }
        return url;
    }

    ChangeImage() {
        let me = this;
        const modalRef = me.modalService.open(ModalUploadImageComponent, {
            scrollable: true,
            size: 'lg'
        });
        modalRef.componentInstance.StorageFolderName = "academic-focus-tiles";
        modalRef.result.then((result) => {
            if (result != 'exit') {
                var req = new UpdateImageRequestModel();
                req.EntityId = me.entity.Id;
                req.ImageName = result;
                req.StorageFolderName = "academic-focus-tiles";

                me.academicFocusService.UpdateImage(req).subscribe(data => {
                    me.entity = data;
                });
            }
        });
    }

    Save(form, e) {
        let me = this;
        if (me.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            if (me.entity.Id) {
                me.academicFocusService.Edit(me.entity).subscribe(data => {
                    me.processing = false;
                    me.entity = new AcademicFocusModel();
                    me.LoadEntities();
                });
            } else {
                me.academicFocusService.Create(me.entity).subscribe(data => {
                    me.processing = false;
                    me.entity = new AcademicFocusModel();
                    me.LoadEntities();
                });
            }
        }
    }
    Cancel() {
        let me = this;
        me.entity = new AcademicFocusModel();
        me.LoadEntities();
    }
}

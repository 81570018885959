import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';

import { StateService, InteractiveModuleSessionService, PresenterService, AuthService } from '../../../../services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { InteractiveModuleSessionModel, PresenterModel, UserModel } from '../../../../models';
import { FormFunctions } from '../../../../functions';
import { ModalEditSessionComponent } from '../../../../components';

@Component({
    selector: 'page-interactive-module-session-calendar',
    templateUrl: './page-interactive-module-session-calendar.component.html',
})
export class PageInteractiveModuleSessionCalendarComponent implements OnInit {
    loading: boolean = true;
    presenters: PresenterModel[] = [];
    supervisors: UserModel[] = [];
    sessions: InteractiveModuleSessionModel[] = [];
    startDate: Date;
    endDate: Date;
    filterPresenter: string = "0";
    filterSupervisor: string = "0";
    dateArray: Date[] = [];

    constructor(public stateService: StateService, public interactiveModuleSessionService: InteractiveModuleSessionService, public presenterService: PresenterService, public modalService: NgbModal, public authService: AuthService) {
        var me = this;

        forkJoin(
            me.presenterService.FindAll(),
            me.authService.FindByRole('Supervisor')
        ).subscribe(([data1, data2]) => {
            me.presenters = data1;
            me.supervisors = data2;
            me.loading = false;
        });
    }

    ngOnInit() {
        var me = this;
    }

    GetSessionsForDate(date) {
        let me = this;
        let sessions = me.sessions.filter(o => me.interactiveModuleSessionService.FormatDateString(o.StartDateTime) == me.interactiveModuleSessionService.FormatDateString(date));
        if (me.filterPresenter != "0") {
            sessions = sessions.filter(o => o.PresenterId == parseInt(me.filterPresenter));
        }
        if (me.filterSupervisor != "0") {
            sessions = sessions.filter(o => o.SupervisorUserId == parseInt(me.filterSupervisor));
        }
        return sessions;
    }

    GetDateArray() {
        let me = this;
        let startDate = new Date(me.startDate);
        let stopDate = new Date(me.endDate);

        let dateArray = new Array();
        var currentDate = startDate;

        while (currentDate <= stopDate) {
            dateArray.push(new Date(currentDate));
            currentDate = me.AddDays(currentDate, 1);
        }
        return dateArray;
    }

    AddDays = function (inp, days) {
        var date = new Date(inp);
        date.setDate(date.getDate() + days);
        return date;
    }

    FindSessions() {
        let me = this;
        me.dateArray = me.GetDateArray();
        me.interactiveModuleSessionService.FindByDateRange(me.startDate, me.endDate).subscribe(data => {
            me.sessions = data;
        }, err => {
            alert('Something went wrong')
        });
    }
    GetSessionLink(session: InteractiveModuleSessionModel) {
        return session.SessionLink;
    }
    EditSession(session: InteractiveModuleSessionModel) {
        let me = this;
        const modalRef = me.modalService.open(ModalEditSessionComponent, {
            scrollable: true
        });
        modalRef.componentInstance.entity = session;
        modalRef.result.then((result) => {
            me.FindSessions();
        });
    }
}

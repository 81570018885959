export class InteractiveInstanceFilterRequestModel {
    InSchool: boolean;
    OutOfSchool: boolean;
    Mode: string;
    YearLevelId: number
    DayNumber: number;
    TimeWindow: string;
    AcademicFocusId: number;
    PresenterId: number;
    AllTypes: boolean;
    InteractiveModuleStyleId: number;
    Delivery: string;
    State: string;

    constructor() {
        this.Mode = "";
        this.TimeWindow = "";
        this.InSchool = true;
        this.OutOfSchool = true;
        this.PresenterId = 0;
        this.AcademicFocusId = 0;
        this.YearLevelId = 0;
        this.DayNumber = 0;
        this.InteractiveModuleStyleId = 0;
        this.Delivery = "";
        this.State = "";
    }
}
import { InteractiveModuleModel, ParentModel, InteractiveModuleEnrolmentStudentModel } from './';

export class InteractiveModuleEnrolmentModel {
    Id: number;
    InteractiveModuleId: number;
    ParentId: number;
    OnAccountSchoolId: number;
    Active: boolean;
    OnAccount: boolean;
    ManualEnrolment: boolean;

    InteractiveModule: InteractiveModuleModel;
    Parent: ParentModel;
    InteractiveModuleEnrolmentStudents: InteractiveModuleEnrolmentStudentModel[];

    constructor() {
        this.InteractiveModuleEnrolmentStudents = [];
        this.Active = false;
        this.OnAccount = false;
        this.ManualEnrolment = false;
    }
}
import { InteractiveModuleTemplateModel, PresenterModel, AcademicFocusModel, InteractiveModuleSessionModel, YearLevelModel, InteractiveModuleResourceModel } from './';
import { InteractiveModuleStyleModel, LocationModel } from './';

export class InteractiveModuleModel {
    Id: number;
    InteractiveModuleTemplateId: number;
    PresenterId: number;
    SupervisorUserId: number;
    AcademicFocusId: number;
    Title: String;
    UrlName: String;
    ShortDescription: String;
    Description: String;
    Requirements: String;
    Intention: String;
    TotalSessions: number;
    Price: number;
    Published: boolean;
    InviteOnly: boolean;
    MaxEnrolmentsTotal: number;
    MaxEnrolmentsSchool: number;
    InteractiveModuleStyleId: number;
    LocationId: number;
    IsOnsite: boolean;
    DiscountForRemainingSessions: boolean;

    InteractiveModuleTemplate: InteractiveModuleTemplateModel;
    Presenter: PresenterModel;
    AcademicFocus: AcademicFocusModel;
    InteractiveModuleSessions: InteractiveModuleSessionModel[];
    YearLevels: YearLevelModel[];
    InteractiveModuleResources: InteractiveModuleResourceModel[];
    InteractiveModuleStyle: InteractiveModuleStyleModel;
    Location: LocationModel;

    constructor() {
        this.Title = "";
        this.UrlName = "";
        this.ShortDescription = "";
        this.Description = "";
        this.Requirements = "";
        this.Intention = "";
        this.InteractiveModuleSessions = [];
        this.YearLevels = [];
        this.InteractiveModuleResources = [];
        this.IsOnsite = false;
        this.DiscountForRemainingSessions = false;
    }
}
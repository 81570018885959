import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';

import { StateService, InteractiveModuleService, PresenterService, AcademicFocusService, YearLevelService, InteractiveModuleStyleService, TimetableService } from '../../../services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { InteractiveModuleModel, InteractiveModuleFutureProjectionModel, GenericLookupModel, AcademicFocusModel, YearLevelModel, InteractiveInstanceFilterRequestModel, InteractiveModuleStyleModel, Timetable, TimetableType } from '../../../models';
import { FormFunctions } from '../../../functions';

@Component({
    selector: 'page-interactive-module-list',
    templateUrl: './page-interactive-module-list.component.html',
})
export class PageInteractiveModuleListComponent implements OnInit {
    loading: boolean = true;
    templates: any[] = [];
    presenters: any[] = [];
    days: GenericLookupModel[] = [];
    times: GenericLookupModel[] = [];
    academicFocuses: AcademicFocusModel[] = [];
    yearLevels: YearLevelModel[] = [];
    styles: InteractiveModuleStyleModel[] = [];
    filter: any = {};
    timetables: Timetable[] = [];

    groupedModules: any[] = [];

    searchFilter: InteractiveInstanceFilterRequestModel;
    searching: boolean = false;
    performedSearch: boolean = false;

    constructor(public stateService: StateService, private datePipe: DatePipe, public interactiveModuleService: InteractiveModuleService, public presenterService: PresenterService, public academicFocusService: AcademicFocusService, public yearLevelService: YearLevelService, private formFunctions: FormFunctions, public interactiveModuleStyleService: InteractiveModuleStyleService, public timetableService: TimetableService) {
        var me = this;

        // Days filter
        me.days.push(new GenericLookupModel(0, 'Sunday'))
        me.days.push(new GenericLookupModel(1, 'Monday'))
        me.days.push(new GenericLookupModel(2, 'Tuesday'))
        me.days.push(new GenericLookupModel(3, 'Wednesday'))
        me.days.push(new GenericLookupModel(4, 'Thursday'))
        me.days.push(new GenericLookupModel(5, 'Friday'))
        me.days.push(new GenericLookupModel(6, 'Saturday'))
        // Time filter
        me.times.push(new GenericLookupModel('am', 'AM'));
        me.times.push(new GenericLookupModel('pm', 'PM'));

        forkJoin([
            me.presenterService.FindAll(),
            me.academicFocusService.FindAll(),
            me.yearLevelService.FindAll(),
            me.interactiveModuleStyleService.FindAll(),
            me.timetableService.FindByType(TimetableType.InteractiveModule)
        ]).subscribe(([data1, data2, data3, data4, data5]) => {
            me.presenters = data1.map(item => {
                return {
                    "id": item.Id,
                    "name": item.Firstname + ' ' + item.Surname
                }
            });

            me.academicFocuses = data2;
            me.yearLevels = data3;
            me.styles = data4;
            me.timetables = data5;

            me.loading = false;

            me.LoadSearchFilter();
        });
    }

    LoadSearchFilter() {
        let me = this;

        let sf = me.stateService.filterRequestModel;
        if (sf && sf.Delivery == null) {
            sf.Delivery = "";
        }
        if (sf && sf.State == null) {
            sf.State = "";
        }
        me.searchFilter = sf;

        me.stateService.filterRequestModel$.subscribe(data => {
            sf = data;
            if (sf && sf.Delivery == null) {
                sf.Delivery = "";
            }
            if (sf && sf.State == null) {
                sf.State = "";
            }
            me.searchFilter = sf;
        });

        if (me.searchFilter == null) {
            me.searchFilter = new InteractiveInstanceFilterRequestModel();
        } else {
            let same = JSON.stringify(me.searchFilter) == JSON.stringify(new InteractiveInstanceFilterRequestModel());
            if (!same) {
                me.ExecuteSearch();
            }
        }
    }

    ngOnInit() {
        var me = this;
    }

    PerformSearch(form, e) {
        let me = this;
        if (me.formFunctions.isFormValid(form, e)) {
            me.searchFilter.AcademicFocusId = parseInt(me.searchFilter.AcademicFocusId.toString());
            // Update the local version of the search
            me.stateService.changeFilterRequestModel(me.searchFilter);

            me.ExecuteSearch();
        }
    }

    ExecuteSearch() {
        let me = this;
        me.searching = true;
        me.interactiveModuleService.FindFutureInstancesGrouped(me.searchFilter).subscribe(data => {
            me.groupedModules = data;
            me.searching = false;
            me.performedSearch = true;
        }, err => {
            me.searching = false;
        });
    }

    GetAvailableSpots(item: InteractiveModuleFutureProjectionModel) {
        var available = item.InteractiveModule.MaxEnrolmentsTotal - item.TotalEnrolments;
        return available;
    }

    FilteredGroups() {
        let me = this;
        let items = me.groupedModules;

        if (me.filter.PresenterId) {
            items = items.filter(o => o.Instances.filter(j => j.InteractiveModule.InteractiveModuleSessions.filter(s => s.PresenterId == me.filter.PresenterId).length > 0).length > 0);
        }
        if (me.filter.AcademicFocusId) {
            items = items.filter(o => o.Template.AcademicFocusId == me.filter.AcademicFocusId);
        }

        return items;
    }

    ClearSearch() {
        let me = this;
        me.groupedModules = [];
        let emptyFilter = new InteractiveInstanceFilterRequestModel();
        me.stateService.changeFilterRequestModel(emptyFilter);
        me.performedSearch = false;
    }

    IsSessionComplete(session) {
        let now = new Date();
        let startDateTime = session.StartDateTime;

        let dYear = parseInt(this.datePipe.transform(startDateTime, 'yyyy'));
        let dMonth = parseInt(this.datePipe.transform(startDateTime, 'M')) - 1;
        let dDay = parseInt(this.datePipe.transform(startDateTime, 'd'));
        let dHour = parseInt(this.datePipe.transform(startDateTime, 'H'));
        let dMinute = parseInt(this.datePipe.transform(startDateTime, 'm'));

        let sessionStart = new Date(dYear, dMonth, dDay, dHour, dMinute, 0);
        let sDiff = (sessionStart.getTime() - now.getTime()) / 1000;

        // Session has already started, cannot enrol into it
        return sDiff <= 0;
    }
}

<div class="modal-header bg-dark text-white">
  <h4 class="modal-title">Change Image</h4>
  <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss('exit')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form #thisForm="ngForm">

    <div class="alert alert-danger" *ngIf="showError">
      There was a problem processing your request, please contact your systems administrator
    </div>

    <div *ngIf="!showSuccess && !showError">
      <div class="form-group">
        <label class="form-label">
          Image
        </label>
        <div class="d-block">
          <label class="btn btn-dark">
            <div>
              <input type="file" class="form-control" required (change)="selectFile($event)">
            </div>
          </label>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer" *ngIf="!showSuccess && !showError">
  <button type="button" class="btn btn-gateways" (click)="Create(thisForm,$event)" *ngIf="!processing">Upload</button>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('exit')" *ngIf="!processing">Close</button>
  <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
</div>
<div class="modal-footer" *ngIf="showSuccess || showError">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('exit')">Close</button>
</div>

<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12">

      <form #thisForm="ngForm">
        <div class="card">
          <div class="card-header bg-dark text-light">
            <h5 class="card-title">Create/Edit</h5>
          </div>
          <div class="card-body bg-light text-dark">
            <div class="row">
              <div class="col-6">
                <websilk-text-field id="txtTitle" name="txtTitle" [(ngModel)]="entity.Title" displayInline="false" labelText="Title" required="true" pattern=""></websilk-text-field>
                <websilk-text-field id="txtAddress1" name="txtAddress1" [(ngModel)]="entity.Address1" displayInline="false" labelText="Address 1" required="false" pattern=""></websilk-text-field>
                <websilk-text-field id="txtAddress2" name="txtAddress2" [(ngModel)]="entity.Address2" displayInline="false" labelText="Address 2" required="false" pattern=""></websilk-text-field>
              </div>
              <div class="col-6">
                <websilk-text-field id="txtSuburb" name="txtSuburb" [(ngModel)]="entity.Suburb" displayInline="false" labelText="Suburb" required="false" pattern=""></websilk-text-field>
                <div class="form-group">
                  <label for="selState" class="control-label">State</label>
                  <select class="form-control" id="selState" name="selState" [(ngModel)]="entity.State">
                    <option [value]="">Select state</option>
                    <option value="ACT">ACT</option>
                    <option value="NSW">NSW</option>
                    <option value="NT">NT</option>
                    <option value="QLD">QLD</option>
                    <option value="SA">SA</option>
                    <option value="TAS">TAS</option>
                    <option value="VIC">VIC</option>
                    <option value="WA">WA</option>
                  </select>
                </div>
                <websilk-text-field id="txtPostcode" name="txtPostcode" [(ngModel)]="entity.Postcode" displayInline="false" labelText="Postcode" required="false" pattern=""></websilk-text-field>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-sm-6 text-left">
                <button type="button" class="btn btn-gateways" (click)="Save(thisForm,$event)" *ngIf="!processing">
                  <span>Save</span>
                </button>
                <button type="button" class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i>&nbsp;Processing...</button>
              </div>
              <div class="col-sm-6 text-right">
                <button class="btn btn-dark" (click)="Cancel()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="card mt-3">
        <div class="card-header bg-dark text-light">
          <h5 class="card-title">List</h5>
        </div>
        <div class="card-body bg-light text-dark">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Title</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of entities">
                <td>{{item.Title}}</td>
                <td>{{item.Address1}} {{item.Address2}}, {{item.Suburb}}, {{item.State}} {{item.Postcode}}</td>
                <td>
                  <button class="btn btn-sm" (click)="Edit(item)"><i class="fa fa-edit"></i> Edit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>

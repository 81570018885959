import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService, AuthService, GenericDataService, SchoolService } from '../../services';
import { GenericLookupModel, UserModel, SchoolModel } from '../../models';
import { FormFunctions, CustomDateParserFormatter } from '../../functions';
import { Validations } from '../../constants'

@Component({
    selector: 'modal-admin-edit-user',
    template: `
    <div class="modal-header bg-dark text-white">
      <h4 class="modal-title">My Details</h4>
      <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form #thisForm="ngForm">
            <websilk-text-field id="txtEmail" name="txtEmail" [(ngModel)]="entity.Email" displayInline="false" labelText="Email" required="true" pattern=""></websilk-text-field>
            <websilk-text-field id="txtFirstname" name="txtFirstname" [(ngModel)]="entity.FirstName" displayInline="false" labelText="First name" required="true" pattern=""></websilk-text-field>
            <websilk-text-field id="txtSurname" name="txtSurname" [(ngModel)]="entity.Surname" displayInline="false" labelText="Surname" required="true" pattern=""></websilk-text-field>
            <div class="form-group">
                <label class="control-label" for="selSchools">School</label>
                <select id="selSchools" name="selSchools" class="form-control" [(ngModel)]="entity.SchoolId">
                    <option>None</option>
                    <option *ngFor="let school of schools" [value]="school.Id">{{school.Name}} - {{school.Suburb}}</option>
                </select>
            </div>

            <div class="alert alert-danger text-center" *ngIf="showError">
                <i class="fa fa-exclamation-triangle fa-2x"></i>
                <h5 class="">There was a problem updating details: {{errorMessage}}</h5>
            </div>
            <div class="alert alert-success text-center" *ngIf="showSuccess">
                <i class="fa fa-tick fa-2x"></i>
                <h5 class="">Details updated successfully.</h5>
            </div>
        </form>

    </div>
    <div class="modal-footer">
        <button class="btn btn-gateways" (click)="UpdateDetails(thisForm,$event)" *ngIf="!processing"><i class="fa fa-save"></i> Save</button>
        <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')" *ngIf="!processing">Close</button>
    </div>
  `
})
export class ModalAdminEditUserComponent {
    @Input() entity: UserModel;
    states: GenericLookupModel[] = [];
    loading: boolean = true;
    showSuccess: boolean = false;
    showError: boolean = false;
    processing: boolean = false;
    errorMessage: string = "";
    schools: SchoolModel[] = [];

    constructor(public activeModal: NgbActiveModal, public stateService: StateService, public validations: Validations, public formFunctions: FormFunctions, public authService: AuthService, public genericDataService: GenericDataService, public schoolService: SchoolService) {
        let me = this;

        me.states = me.genericDataService.GetStates();
        me.schoolService.FindAll().subscribe(data => {
            me.schools = data;
            me.loading = false;
        });
    }
    ngOnInit() {

    }
    UpdateDetails(form, e) {
        let me = this;

        if (this.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            me.showError = false;
            me.errorMessage = "";
            me.authService.UpdateBasic(me.entity).subscribe(data => {
                me.showSuccess = true;
                me.processing = false;
                me.entity = data;
            }, err => {
                me.processing = false;
                me.showError = true;
                me.errorMessage = err.error;
            });
        }
    }
}
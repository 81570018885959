import { Component, Input, Output, forwardRef, OnInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StateService, AuthService } from '../../services';
import { WebsilkTextField } from '../../components';
import { Authenticate } from '../../models';
import { FormFunctions } from '../../functions';

@Component({
    selector: 'websilk-login',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WebsilkLoginComponent),
            multi: true
        }
    ],
    templateUrl: './ws-login.component.html'
})
export class WebsilkLoginComponent implements OnInit {
    processing: boolean = false;
    error: string = "";
    request: Authenticate = new Authenticate();
    requestUnique: Authenticate = new Authenticate();
    @Input() successRedirect: string = '';
    @Input() displayMode: string;
    @Output() onSuccess: EventEmitter<any> = new EventEmitter();

    constructor(public router: Router, private activatedRoute: ActivatedRoute, public stateService: StateService, public authService: AuthService, public formFunctions: FormFunctions) {
        let me = this;
    }

    ngOnInit() {
        if (!this.displayMode) {
            this.displayMode = 'both';
        }
    }

    PerformLogin(form, e) {
        let me = this;
        me.error = "";

        if (me.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            me.authService.Login(me.request).subscribe(data => {
                me.stateService.changeUserModel(data);
                me.processing = false;
                if (me.successRedirect != '') {
                    me.router.navigate([me.successRedirect]);
                } else {
                    me.onSuccess.emit(data.Id);
                }
            }, err => {
                me.processing = false;
                me.error = "Invalid credentials. Please try again"
            });
        }
    }

    PerformLoginUnique(form, e) {
        let me = this;
        me.error = "";

        if (me.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            me.authService.Login(me.requestUnique).subscribe(data => {
                me.stateService.changeUserModel(data);
                me.processing = false;
                if (me.successRedirect != '') {
                    me.router.navigate([me.successRedirect]);
                } else {
                    me.onSuccess.emit(data.Id);
                }
            }, err => {
                me.processing = false;
                me.error = "Invalid credentials. Please try again"
            });
        }
    }
}




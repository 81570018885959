import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StateService, ParentService, GenericDataService } from '../../../../services';
import { FormFunctions } from '../../../../functions';
import { ParentModel, GenericLookupModel } from '../../../../models';
import { ModalEditParentComponent, ModalResetPasswordComponent } from '../../../../components';

@Component({
    selector: 'page-admin-parents',
    templateUrl: './page-admin-parents.component.html',
})
export class PageAdminParentsComponent implements OnInit {
    loading: boolean = true;
    processing: boolean = false;
    entities: ParentModel[] = [];
    filteredEntities: ParentModel[] = [];
    filterName: string = "";
    states: GenericLookupModel[] = [];


    constructor(public stateService: StateService, private formFunctions: FormFunctions, private modalService: NgbModal, public parentService: ParentService, public genericDataService: GenericDataService) {
        var me = this;
        me.states = me.genericDataService.GetStates();

        me.LoadEntities();
    }

    ngOnInit() {
        var me = this;
    }

    LoadEntities() {
        let me = this;
        me.loading = true;
        me.parentService.FindAll().subscribe(data => {
            me.entities = data;
            me.loading = false;
        });
    }

    FilterEntities() {
        let me = this;
        let items = me.entities;

        if (me.filterName.length < 3) {
            me.filteredEntities = [];
            return
        }

        if (me.filterName) {
            items = items.filter(o => (o.User?.FirstName + ' ' + o.User?.Surname).toLowerCase().includes(me.filterName.toLowerCase()));
        }

        me.filteredEntities = items;
    }

    Edit(entity: ParentModel) {
        let me = this;
        const modalRef = me.modalService.open(ModalEditParentComponent, {
            scrollable: true
        });
        modalRef.componentInstance.entity = entity;

        modalRef.result.then((result) => {
            me.LoadEntities();
        });
    }

    ResetPassword(entity: ParentModel) {
        let me = this;
        const modalRef = me.modalService.open(ModalResetPasswordComponent, {
            scrollable: true
        });
        modalRef.componentInstance.user = entity.User;

        modalRef.result.then((result) => {

        });
    }

}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormFunctions, CustomDateParserFormatter } from '../../functions';

import { StateService, StudentService, YearLevelService, SchoolService } from '../../services';

import { StudentCreateRequest, StudentModel, YearLevelModel, SchoolModel, GenericLookupModel } from '../../models';

@Component({
    selector: 'modal-edit-child',
    templateUrl: 'modal-edit-child.component.html'
})
export class ModalEditChildComponent {
    @Input() student: StudentModel;
    createStudent: StudentCreateRequest = new StudentCreateRequest();
    loading: boolean = true;
    processing: boolean = false;
    showError: boolean = false;
    showSuccess: boolean = false;

    yearLevels: YearLevelModel[] = [];
    schools: SchoolModel[] = [];
    schoolList: any[] = [];
    genders: GenericLookupModel[] = [];

    constructor(public activeModal: NgbActiveModal, private formFunctions: FormFunctions, public stateService: StateService, public studentService: StudentService, public yearLevelService: YearLevelService, public schoolService: SchoolService) {
        let me = this;

        me.genders.push(new GenericLookupModel("M", "Male"));
        me.genders.push(new GenericLookupModel("F", "Female"));
        me.genders.push(new GenericLookupModel("U", "Unspecified"));

        me.yearLevelService.FindAll().subscribe(data => {
            me.yearLevels = data;
        });

        me.schoolService.FindAll().subscribe(data => {
            me.schools = data;
            me.schoolList = data.map(item => {
                return {
                    "Id": item.Id,
                    "Name": item.Name + ' (' + item.State + ' ' + item.Postcode + ')'
                }
            });
        });
    }
    ngOnInit() {

    }
    UpdateChild(form, e) {
        var me = this;
        me.showError = false;
        me.showSuccess = false;
        if (this.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            this.studentService.Update(me.student).subscribe(data => {
                me.showSuccess = true;
                me.processing = false;
                me.student = data;
            }, err => {
                me.processing = false;
                me.showError = true;
            });
        }
    }

    GetSelectedSchool() {
        let me = this;
        if (me.student.SchoolId) {
            return me.schoolList.filter(o => o.Id == me.student.SchoolId)[0];
        }
        return null;
    }
}
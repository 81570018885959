import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { StateService, PresenterService, InteractiveModuleService, AuthService } from '../../services';
import { PresenterModel, InteractiveModuleTemplateModel, InteractiveInstanceRequest, InteractiveInstanceRequestSession, UserModel } from '../../models';
import { FormFunctions, CustomDateParserFormatter } from '../../functions';
import { Validations } from '../../constants'

@Component({
    selector: 'modal-create-interactive-instance',
    templateUrl: './modal-create-interactive-instance.component.html'
})
export class ModalCreateInteractiveInstanceComponent {
    @Input() entity: InteractiveModuleTemplateModel;
    presenters: any[] = [];
    supervisors: any[] = [];
    request: InteractiveInstanceRequest = new InteractiveInstanceRequest();
    sessions: InteractiveInstanceRequestSession[] = [];
    repeatSessions: InteractiveInstanceRequestSession[] = [];
    loading: boolean = true;
    showSuccess: boolean = false;
    showError: boolean = false;
    processing: boolean = false;

    constructor(public activeModal: NgbActiveModal, public stateService: StateService, public validations: Validations, public formFunctions: FormFunctions, public presenterService: PresenterService, public interactiveModuleService: InteractiveModuleService, public authService: AuthService) {
        let me = this;

        forkJoin(
            me.presenterService.FindAll(),
            me.authService.FindByRole('Supervisor')
        ).subscribe(([data1, data2]) => {
            // Presenters
            me.presenters = data1.map(item => {
                return {
                    "id": item.Id,
                    "name": item.Firstname + ' ' + item.Surname
                }
            });
            me.supervisors = data2.map(item => {
                return {
                    "id": item.Id,
                    "name": item.FirstName + ' ' + item.Surname
                }
            });

            me.loading = false;
        });
    }
    ngOnInit() {
        let me = this;

        me.request.InteractiveModuleTemplateId = me.entity.Id;
        me.request.Published = true;
        me.request.RepeatEvery = 0;
        me.request.Title = me.entity.Title;

        for (let i = 0; i < this.entity.TotalSessions; i++) {
            let newSession = new InteractiveInstanceRequestSession();
            me.sessions.push(me.formFunctions.DeepCopy(newSession));
            me.repeatSessions.push(me.formFunctions.DeepCopy(newSession));
        }
    }
    Create(form, e) {
        let me = this;
        if (this.formFunctions.isFormValid(form, e)) {
            me.request.Sessions = me.sessions;
            me.request.RepeatSessions = me.repeatSessions;

            me.processing = true;
            me.showError = false;
            me.showSuccess = false;

            this.interactiveModuleService.CreateFromTemplate(me.request).subscribe(data => {
                me.showSuccess = true;
                me.processing = false;
            }, err => {
                me.showSuccess = true;
                me.processing = false;
            });
        }
    }
    Time_keyUp(event: any) {
        // Always allow backspace
        if (event.key == "Backspace") {
            return;
        }
        // debugger;
        let newValue: String = event.target.value;

        // Only allow one colon, auto  add colon, and cut more than 4
        var colonCount = newValue.split(':').length - 1;
        newValue = newValue.split(':').join('');
        if (newValue.length == 2) {
            newValue = newValue + ':';
        }
        else if (newValue.length == 3) {
            newValue = newValue.substr(0, 1) + ':' + newValue.substr(1);
        }
        else if (newValue.length >= 4) {
            newValue = newValue.substr(0, 2) + ':' + newValue.substr(2, 2);
        }
        // item.SessionTime = newValue;
        event.target.value = newValue
    }
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { StateService, ParentService, StudentService, AuthService, InteractiveModuleService } from '../../../services';

import { StudentModel, ParentModel, Authenticate, InteractiveModuleModel, UserModel } from '../../../models';

@Component({
    selector: 'page-interactive-module-enrol',
    templateUrl: './page-interactive-module-enrol.component.html',
})
export class PageInteractiveModuleEnrolComponent implements OnInit {
    loading: boolean = true;
    children: StudentModel[] = [];
    schoolStudents: StudentModel[] = [];
    interactiveModule: InteractiveModuleModel;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, public stateService: StateService, public parentService: ParentService, public studentService: StudentService, public authService: AuthService, public interactiveModuleService: InteractiveModuleService) {
        var me = this;
        let id = me.activatedRoute.snapshot.params.id;

        // Get the learning module to register for
        me.interactiveModuleService.FindByIdPublic(id).subscribe(data => {
            me.interactiveModule = data;
            me.loading = false;
            me.checkLoggedIn();
        });
    }

    ngOnInit() {
        var me = this;
    }

    checkLoggedIn() {
        let me = this;
        me.authService.IsLoggedOn().subscribe(data => {
            // Already logged in so no need for this screen
            me.navigateFinalise();
        }, err => {
            // Do nothing here
        });
    }

    getCurrentUrl() {
        var me = this;
        return "/interactive-modules/enrol/" + me.interactiveModule.Id;
    }

    GetParentAndChildren() {
        var me = this;
        // Get the current parent and children (if logged on)
        me.parentService.FindMyStudents().subscribe(data => {
            me.children = data;
        });
    }

    loginCallback(userId) {
        var me = this;
        me.navigateFinalise();
    }
    registerCallback(event) {
        var me = this;

        // Need to log in the new user for the final step
        let parentId = event[0];
        let userName = event[1];
        let password = event[2];

        let authModel = new Authenticate();
        authModel.UserName = userName;
        authModel.Password = password;
        me.authService.Login(authModel).subscribe(data => {
            me.stateService.changeUserModel(data);
            me.navigateFinalise();
        });
    }
    navigateFinalise() {
        var me = this;
        me.router.navigate(['/interactive-modules/enrol/finalise', me.interactiveModule.Id]);
    }
}

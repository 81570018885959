import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ServiceHelper } from "../config";
import {
  InteractiveModuleEnrolmentModel,
  EnrolStudentRequest,
  SearchEnrolmentsRequestModel,
} from "../models";
import { AppConfig } from "../config";

@Injectable()
export class InteractiveModuleEnrolmentService {
  constructor(
    public http: HttpClient,
    public serviceHelper: ServiceHelper,
    private appConfig: AppConfig
  ) {
    var that = this;
  }
  FindById(id: number): Observable<any> {
    var headers = this.serviceHelper.GetHeaders();

    return this.http.get<InteractiveModuleEnrolmentModel>(
      this.appConfig.getConfig("apiUrl") +
        "/interactive-module-enrolments/" +
        id,
      { withCredentials: true, headers: headers }
    );
  }
  FindByInstance(id: number): Observable<any> {
    var headers = this.serviceHelper.GetHeaders();

    return this.http.get<InteractiveModuleEnrolmentModel[]>(
      this.appConfig.getConfig("apiUrl") +
        "/interactive-module-enrolments/by-instance/" +
        id,
      { withCredentials: true, headers: headers }
    );
  }
  Search(request: SearchEnrolmentsRequestModel): Observable<any> {
    var headers = this.serviceHelper.GetHeaders();

    var params = JSON.stringify(request);

    return this.http.post<InteractiveModuleEnrolmentModel[]>(
      this.appConfig.getConfig("apiUrl") +
        "/interactive-module-enrolments/search",
      params,
      { withCredentials: true, headers: headers }
    );
  }
  Export(request: SearchEnrolmentsRequestModel) {
    var headers = this.serviceHelper.GetHeaders();

    var params = JSON.stringify(request);

    return this.http
      .post(
        this.appConfig.getConfig("apiUrl") +
          "/interactive-module-enrolments/export",
        params,
        {
          withCredentials: true,
          headers: headers,
          responseType: "blob",
          observe: "response",
        }
      )
      .pipe(
        map((res: any) => {
          return new Blob([res.body]);
        })
      );
  }
  ExportInvoice(enrolmentId: number) {
    var headers = this.serviceHelper.GetHeaders();

    return this.http
      .get(
        this.appConfig.getConfig("apiUrl") +
          "/interactive-module-enrolments/export-invoice/" +
          enrolmentId,
        {
          withCredentials: true,
          headers: headers,
          responseType: "blob",
          observe: "response",
        }
      )
      .pipe(
        map((res: any) => {
          return new Blob([res.body]);
        })
      );
  }
  FindByTemplate(id: number): Observable<any> {
    var headers = this.serviceHelper.GetHeaders();

    return this.http.get<InteractiveModuleEnrolmentModel[]>(
      this.appConfig.getConfig("apiUrl") +
        "/interactive-module-enrolments/by-template/" +
        id,
      { withCredentials: true, headers: headers }
    );
  }
  Create(request: InteractiveModuleEnrolmentModel): Observable<any> {
    var headers = this.serviceHelper.GetHeaders();
    var params = JSON.stringify(request);

    return this.http.post<InteractiveModuleEnrolmentModel>(
      this.appConfig.getConfig("apiUrl") + "/interactive-module-enrolments",
      params,
      { withCredentials: true, headers: headers }
    );
  }
  Update(request: InteractiveModuleEnrolmentModel): Observable<any> {
    var headers = this.serviceHelper.GetHeaders();
    var params = JSON.stringify(request);

    return this.http.post<InteractiveModuleEnrolmentModel>(
      this.appConfig.getConfig("apiUrl") +
        "/interactive-module-enrolments/" +
        request.Id,
      params,
      { withCredentials: true, headers: headers }
    );
  }
  EnrolStudent(request: EnrolStudentRequest): Observable<any> {
    var headers = this.serviceHelper.GetHeaders();
    var params = JSON.stringify(request);

    return this.http.post<InteractiveModuleEnrolmentModel>(
      this.appConfig.getConfig("apiUrl") +
        "/interactive-module-enrolments/enrol-student",
      params,
      { withCredentials: true, headers: headers }
    );
  }
  MyEnrolments(futureOnly: boolean) {
    var headers = this.serviceHelper.GetHeaders();

    return this.http.get<InteractiveModuleEnrolmentModel[]>(
      this.appConfig.getConfig("apiUrl") +
        "/interactive-module-enrolments/my-enrolments/" +
        futureOnly,
      { withCredentials: true, headers: headers }
    );
  }
  Delete(id: number): Observable<any> {
    var headers = this.serviceHelper.GetHeaders();

    return this.http.delete<any>(
      this.appConfig.getConfig("apiUrl") +
        "/interactive-module-enrolments/" +
        id,
      { withCredentials: true, headers: headers }
    );
  }
  DeleteStudent(id: number): Observable<any> {
    var headers = this.serviceHelper.GetHeaders();

    return this.http.delete<any>(
      this.appConfig.getConfig("apiUrl") +
        "/interactive-module-enrolments/delete-student/" +
        id,
      { withCredentials: true, headers: headers }
    );
  }

  FinalEnrolStudent(request: {
    Success: boolean;
    TxnId: string;
    EnrolmentId: number;
  }): Observable<any> {
    var headers = this.serviceHelper.GetHeaders();
    var params = JSON.stringify(request);

    return this.http.post<InteractiveModuleEnrolmentModel>(
      this.appConfig.getConfig("apiUrl") +
        "/interactive-module-enrolments/enrol-student",
      params,
      { withCredentials: true, headers: headers }
    );
  }

  PrepareEnrolStudent(request: EnrolStudentRequest): Observable<{
    Intent: string;
    EnrolmentId: number;
    TransactionId: number;
    FullCredit: boolean;
    Result: any;
  }> {
    var headers = this.serviceHelper.GetHeaders();
    var params = JSON.stringify(request);

    return this.http.post<{
      Intent: string;
      EnrolmentId: number;
      TransactionId: number;
      FullCredit: boolean;
      Result: any;
    }>(
      this.appConfig.getConfig("apiUrl") +
        "/interactive-module-enrolments/prepare-enrol-student",
      params,
      { withCredentials: true, headers: headers }
    );
  }
}

import { Component, OnInit } from '@angular/core';

import { StateService, PresenterService } from '../../../services';
import { FormFunctions } from '../../../functions';
import { PresenterModel, CreatePresenterRequest } from '../../../models';

@Component({
    selector: 'page-admin-presenters',
    templateUrl: './page-admin-presenters.component.html',
})
export class PageAdminPresentersComponent implements OnInit {
    loading: boolean = true;
    processing: boolean = false;
    entities: PresenterModel[] = [];
    entity: CreatePresenterRequest = new CreatePresenterRequest();

    constructor(public stateService: StateService, private formFunctions: FormFunctions, public presenterService: PresenterService) {
        var me = this;
        me.LoadEntities();
    }

    ngOnInit() {
        var me = this;
    }

    LoadEntities() {
        let me = this;
        me.loading = true;
        me.presenterService.FindAll().subscribe(data => {
            me.entities = data;
            me.loading = false;
        });
    }

    Edit(item: PresenterModel) {
        let me = this;

        let clone = new CreatePresenterRequest();
        clone.Id = item.Id;
        clone.Firstname = item.Firstname;
        clone.Surname = item.Surname;
        clone.Email = item.User.Email;
        clone.Summary = item.Summary;

        me.entity = clone;
    }
    Save(form, e) {
        let me = this;
        if (me.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            if (me.entity.Id) {
                me.presenterService.Edit(me.entity).subscribe(data => {
                    me.processing = false;
                    me.entity = new CreatePresenterRequest();
                    me.LoadEntities();
                });
            } else {
                me.presenterService.Create(me.entity).subscribe(data => {
                    me.processing = false;
                    me.entity = new CreatePresenterRequest();
                    me.LoadEntities();
                });
            }
        }
    }
    Cancel() {
        let me = this;
        me.entity = new CreatePresenterRequest();
        me.LoadEntities();
    }
}

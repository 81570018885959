<!-- <div class="row pb-3 mb-0" style="background-color:#4a2261;">
  <div class="container">
    <div class="row">
      <div class="col-2"></div>
      <div class="col-8">
        <img class="w-100" src="/assets/images/gateways-online.png">
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</div> -->

<div class="container" *ngIf="!loading">
  <div class="row mt-3">

    <!-- LEFT COLUMN -->
    <div class="col-sm-3">
      <div class="card card-search-filter">
        <div class="card-body bg-purple text-white">
          <h5 class="mb-3">Explore our Live &amp; Interactive Programs</h5>

          <form #searchForm="ngForm">

            <div class="form-group">
              <label class="control-label" for="selDeliver">Program Delivery</label>
              <select id="selDelivery" name="selDelivery" class="form-control" [(ngModel)]="searchFilter.Delivery">
                <option value="">All programs</option>
                <option value="online">OnLine</option>
                <option value="onsite">OnSite</option>
              </select>
            </div>

            <div class="form-group">
              <label class="control-label" for="selState">State</label>
              <select id="selState" name="selState" class="form-control" [(ngModel)]="searchFilter.State">
                <option value="">All states</option>
                <option value="ACT">ACT</option>
                <option value="NSW">NSW</option>
                <option value="NT">NT</option>
                <option value="QLD">QLD</option>
                <option value="SA">SA</option>
                <option value="TAS">TAS</option>
                <option value="VIC">VIC</option>
                <option value="WA">WA</option>
              </select>
            </div>

            <div class="form-group">
              <label class="control-label" for="selAcademicFocus">Academic Focus</label>
              <select id="selAcademicFocus" name="selAcademicFocus" class="form-control" [(ngModel)]="searchFilter.AcademicFocusId">
                <option value="0">All Academic Areas</option>
                <option *ngFor="let academicFocus of academicFocuses" [value]="academicFocus.Id">{{academicFocus.Title}}</option>
              </select>
            </div>

            <div class="form-group">
              <label class="control-label" for="selProgramStyle">Program Style</label>
              <select id="selProgramStyle" name="selProgramStyle" class="form-control" [(ngModel)]="searchFilter.InteractiveModuleStyleId">
                <option value="0">All Program Styles</option>
                <option *ngFor="let style of styles" value="{{style.Id}}">{{style.Title}}</option>
              </select>
            </div>

            <div class="form-group">
              <label class="control-label" for="selYearLevel">Year level</label>
              <select id="selYearLevel" name="selYearLevel" class="form-control" [(ngModel)]="searchFilter.YearLevelId">
                <option value="0">All year levels</option>
                <option *ngFor="let yearLevel of yearLevels" value="{{yearLevel.Id}}">{{yearLevel.Title}}</option>
              </select>
            </div>

            <div class="form-group">
              <label class="control-label" for="selPresenter">Presenter</label>
              <select id="selPresenter" name="selPresenter" class="form-control" [(ngModel)]="searchFilter.PresenterId">
                <option value="0">All Presenters</option>
                <option *ngFor="let presenter of presenters" value="{{presenter.id}}">{{presenter.name}}</option>
              </select>
            </div>

            <div class="form-group">
              <label class="control-label" for="selDays">Day</label>
              <select id="selDays" name="selDays" class="form-control" [(ngModel)]="searchFilter.DayNumber">
                <option value="0">Any day</option>
                <option *ngFor="let day of days" value="{{day.Id}}">{{day.Description}}</option>
              </select>
            </div>

            <div class="form-group">
              <label class="control-label" for="selTime">Time (AM/PM)</label>
              <select id="selTime" name="selTime" class="form-control" [(ngModel)]="searchFilter.TimeWindow">
                <option value="">Any time</option>
                <option *ngFor="let time of times" value="{{time.Id}}">{{time.Description}}</option>
              </select>
            </div>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" name="chkInSchool" id="chkInSchool" [(ngModel)]="searchFilter.InSchool">
              <label class="form-check-label" for="chkInSchool">
                Include in school programs
              </label>
            </div>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" name="chkOutOfSchool" id="chkOutOfSchool" [(ngModel)]="searchFilter.OutOfSchool">
              <label class="form-check-label" for="chkOutOfSchool">
                Include out of school programs
              </label>
            </div>

          </form>

          <div class="mt-3">
            <button class="btn bg-orange text-white w-100" *ngIf="!searching" (click)="PerformSearch(searchForm,$event)"><i class="fa fa-search"></i> Search</button>
            <span class="btn btn-dark w-100" *ngIf="searching"><i class="fa fa-spinner fa-spin"></i> Searching...</span>
          </div>
          <div class="mt-2 text-center">
            <a href="javascript:void(0)" class="text-white font-weight-bold" (click)="ClearSearch()" *ngIf="!searching"><i class="fa fa-times"></i> Clear Search</a>
          </div>
        </div>
      </div>

      <div class="card card-light mt-2">
        <div class="card-body text-dark">
          <h5 class="mb-3">Timetables</h5>
          <a *ngFor="let timetable of timetables" class="d-block text-dark" target="_blank" [(href)]="timetable.FileUrl"><i class="fa fa-download"></i>&nbsp;{{timetable.Title}}</a>
        </div>
      </div>
    </div>
    <!-- /LEFT COLUMN -->

    <!-- RIGHT COLUMN -->
    <div class="col-sm-9">

      <!-- NOT YET SEARCHED -->
      <div class="row" style="margin-top:30%;" *ngIf="!performedSearch">
        <div class="col-1 text-right">
          <h5 class="text-purple">
            <i class="fa fa-arrow-left"></i>
          </h5>
        </div>
        <div class="col-5">
          <h5 class="text-purple font-weight-bold">
            Find available programs using the search function
          </h5>
          <p>The search will allow you to set specific criteria that suits your childrens learning needs</p>
        </div>
      </div>
      <!-- /NOT YET SEARCHED -->

      <!-- NO RESULTS -->
      <div class="row" style="margin-top:30%;" *ngIf="!groupedModules.length && performedSearch">
        <div class="col-12 text-center">
          <h5 class="text-purple font-weight-bold">No programs found</h5>
          <p>
            Looks like there are no programs available base on your search criteria.
            <br>
            Please refine your search criteria using the search controls to the left.
          </p>
        </div>
      </div>
      <!-- /NO RESULTS -->

      <!-- RESULTS -->
      <div class="row mb-3" *ngFor="let group of groupedModules">
        <div class="col-sm-12">
          <interactive-module-instance-group-card [model]="group"></interactive-module-instance-group-card>
        </div>
      </div>
      <!-- /RESULTS -->

    </div>
    <!-- /RIGHT COLUMN -->

  </div>
</div>

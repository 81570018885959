import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'delivery-pill',
    styleUrls: ['./delivery-pill.component.scss'],
    template:
        `
        <span class="badge delivery-pill ml-2 text-white bg-secondary" *ngIf="IsOnsite">OnSite</span>
        <span class="badge delivery-pill ml-2 text-white bg-warning" *ngIf="!IsOnsite">OnLine</span>
  `
})
export class DeliveryPill {
    @Input() IsOnsite: boolean = false;
    constructor() { }
}




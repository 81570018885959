import { LearningModuleModel, ParentModel } from './';

export class LearningModuleEnrolmentModel {
    Id: number;
    LearningModuleId: number;
    ParentId: number;
    Active: boolean;

    LearningModule: LearningModuleModel;
    Parent: ParentModel;

    constructor() {
    }
}
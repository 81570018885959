import { LearningModuleEnrolmentModel } from './';

export class PaymentTransactionModel {
    Id: number;
    LearningModuleEnrolmentId: number;
    Successful: boolean;
    ResponseCode: string;
    ResponseText: string;
    StatusCode: string;
    StatusDescription: string;
    Amount: number;
    TxnId: string;
    CardType: string;
    Pan: string;
    LearningModuleEnrolment: LearningModuleEnrolmentModel;

    constructor() {
        this.ResponseCode = "";
        this.ResponseText = "";
        this.StatusCode = "";
        this.StatusDescription = "";
        this.Amount = 0;
        this.TxnId = "";
        this.CardType = "";
        this.Pan = "";
    }
}
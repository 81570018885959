import { RoleModel, SchoolModel } from './';

export class UserModel {
    Id: number;
    Username: string;
    FirstName: string;
    Surname: string;
    SchoolId: number;
    Email: string;
    Roles: RoleModel[];
    Token: string;
    School: SchoolModel;

    constructor() {
        this.Username = "";
        this.FirstName = "";
        this.Surname = "";
        this.Email = "";
        this.Roles = [];
        this.Token = "";
    }
}
import { Component, OnInit } from '@angular/core';

import { StateService, PresenterService } from '../../../services';

import { InteractiveModuleSessionModel } from '../../../models';
import { } from 'src/app/components';

@Component({
    selector: 'page-presenter-home',
    templateUrl: './page-presenter-home.component.html',
})
export class PagePresenterHomeComponent implements OnInit {
    loading: boolean = true;
    mySessions: InteractiveModuleSessionModel[] = [];

    constructor(public stateService: StateService, public presenterService: PresenterService) {
        var me = this;
        me.loading = false;

        me.presenterService.FindMyInteractiveSessions().subscribe(data => {
            me.mySessions = data;
        });
    }

    ngOnInit() {
        var me = this;
    }

}

import { Component, Input, OnInit } from "@angular/core";
import { AppConfig } from "src/app/config";

@Component({
  selector: "stripe-checkout",
  templateUrl: "stripe-checkout.component.html",
})
export class StripeCheckoutComponent implements OnInit {
  constructor(private appConfig: AppConfig) {}

  stripe: any;
  elements: any;

  ngOnInit(): void {
    this.invokeStripe();
  }

  invokeStripe() {
    if (!window.document.getElementById("stripe-script")) {
      const script = window.document.createElement("script");
      script.id = "stripe-script";
      script.type = "text/javascript";
      script.src = "https://js.stripe.com/v3/";
      script.onload = () => {
        this.stripe = (window as any).Stripe(
          this.appConfig.getConfig("stripe_key")
        );
        this.initElement(100);
      };
      window.document.body.appendChild(script);
    } else {
      this.stripe = (window as any).Stripe(
        this.appConfig.getConfig("stripe_key")
      );
      this.initElement(100);
    }
  }

  initElement(amount: number) {
    const appearance = {
      theme: "stripe",
    };
    this.elements = this.stripe.elements({
      appearance,
      mode: "payment",
      currency: "aud",
      amount,
      paymentMethodTypes: ["card"],
    });

    const paymentElement = this.elements.create("payment", {});
    paymentElement.mount("#payment-element");
  }

  async validate() {
    return await this.elements.submit().then((result) => !!!result.error);
  }

  async updateAmount(amount: number) {
    this.elements.update({ amount });
  }

  async handleSubmit(clientSecret: string): Promise<{
    error: string;
    success: boolean;
    id: string;
  }> {
    const value = await this.stripe.confirmPayment({
      elements: this.elements,
      redirect: "if_required",
      clientSecret,
    });

    if (!!value.error) {
      if (
        value.error.type === "card_error" ||
        value.error.type === "validation_error"
      ) {
        return { error: value.error.message, success: false, id: "" };
      } else {
        return {
          error: "An unexpected error occurred.",
          success: false,
          id: "",
        };
      }
    }

    return {
      success: value.paymentIntent?.status === "succeeded" || false,
      id: value.paymentIntent?.id || "",
      error: "",
    };
  }

  toggleInputs(disabled: boolean): void {
    return;
    // DOESNT WORK AS ITS INSIDE IFRAME (WHOOPS!)
    // ["numberInput", "expiryInput", "cvcInput", "countryInput"].forEach((x) => {
    //   (document.getElementById(`Field-${x}`) as HTMLInputElement).disabled =
    //     disabled;
    // });
  }
}

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import { CreditCouponModel, GenericResponseModel } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class CreditCouponService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindAll(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<CreditCouponModel[]>(this.appConfig.getConfig('apiUrl') + '/credit-coupons', { withCredentials: true, headers: headers });
    }
    FindByUser(userId: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<CreditCouponModel[]>(this.appConfig.getConfig('apiUrl') + '/credit-coupons/by-user/' + userId, { withCredentials: true, headers: headers });
    }
    MyAvailableBalance(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<GenericResponseModel>(this.appConfig.getConfig('apiUrl') + '/credit-coupons/my-available-balance', { withCredentials: true, headers: headers });
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<CreditCouponModel>(this.appConfig.getConfig('apiUrl') + '/credit-coupons/' + id, { withCredentials: true, headers: headers });
    }
    FindByCode(code: string): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<CreditCouponModel>(this.appConfig.getConfig('apiUrl') + '/credit-coupons/by-code/' + code, { withCredentials: true, headers: headers });
    }
    RedeemCode(code: string): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<CreditCouponModel>(this.appConfig.getConfig('apiUrl') + '/credit-coupons/redeem-code/' + code, { withCredentials: true, headers: headers });
    }
    Import(request: FormData) {
        var headers = this.serviceHelper.GetUploadHeaders();
        return this.http.post<GenericResponseModel>(this.appConfig.getConfig('apiUrl') + '/credit-coupons/import', request, { withCredentials: true, headers: headers });
    }
    Create(item: CreditCouponModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<CreditCouponModel>(this.appConfig.getConfig('apiUrl') + '/credit-coupons', params, { withCredentials: true, headers: headers });
    }
    Edit(item: CreditCouponModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<CreditCouponModel>(this.appConfig.getConfig('apiUrl') + '/credit-coupons/' + item.Id, params, { withCredentials: true, headers: headers });
    }
    Delete(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.delete<GenericResponseModel>(this.appConfig.getConfig('apiUrl') + '/credit-coupons/' + id, { withCredentials: true, headers: headers });
    }
}
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'admin-top-menu',
    templateUrl: './admin-top-menu.component.html'
})
export class AdminTopMenuComponent {
    @Input() mode: string = "";

    constructor() { }
}




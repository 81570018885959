import { UserModel, ParentModel, YearLevelModel, SchoolModel } from './';

export class StudentModel {
    Id: number;
    UserId: number;
    ParentId: number;
    YearLevelId: number;
    SchoolId: number;
    DateOfBirth: Date;
    Gender: string;

    Selected: boolean;

    User: UserModel;
    Parent: ParentModel;
    YearLevel: YearLevelModel;
    School: SchoolModel;

    constructor() {
        this.Gender = "";
        this.Selected = false;
    }
}
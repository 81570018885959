import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import { ParentModel, StudentModel, ParentRegisterRequest, ParentRegisterWithStudentsRequestModel, LearningModuleEnrolmentModel } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class ParentService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindMyDetails(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<ParentModel>(this.appConfig.getConfig('apiUrl') + '/parents/my-details', { withCredentials: true, headers: headers });
    }
    FindAll(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<ParentModel[]>(this.appConfig.getConfig('apiUrl') + '/parents', { withCredentials: true, headers: headers });
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<ParentModel>(this.appConfig.getConfig('apiUrl') + '/parents/' + id, { withCredentials: true, headers: headers });
    }
    FindMyStudents(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<StudentModel[]>(this.appConfig.getConfig('apiUrl') + '/parents/my-students', { withCredentials: true, headers: headers });
    }
    FindMyEnrolments(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<LearningModuleEnrolmentModel[]>(this.appConfig.getConfig('apiUrl') + '/parents/my-enrolments', { withCredentials: true, headers: headers });
    }
    Register(request: ParentRegisterRequest): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<ParentModel[]>(this.appConfig.getConfig('apiUrl') + '/parents/register', params, { withCredentials: true, headers: headers });
    }
    RegisterWithStudents(request: ParentRegisterWithStudentsRequestModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<ParentModel[]>(this.appConfig.getConfig('apiUrl') + '/parents/register-with-students', params, { withCredentials: true, headers: headers });
    }
    Update(request: ParentModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<ParentModel[]>(this.appConfig.getConfig('apiUrl') + '/parents/' + request.Id, params, { withCredentials: true, headers: headers });
    }
}
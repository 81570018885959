export class LearningModuleUnitResourceModel {
    Id: number;
    LearningModuleUnitId: number;
    ResourceTypeId: number;
    Title: string;
    ResourceFileName: string;
    ResourceFileExtension: string;
    ResourceFileSizeKb: number;

    constructor() {
        this.Title = "";
        this.ResourceFileName = "";
        this.ResourceFileExtension = "";
        this.ResourceFileSizeKb = 0;
    }
}
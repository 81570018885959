<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12">

      <form #thisForm="ngForm">
        <div class="card">
          <div class="card-header bg-dark text-light">
            <h5 class="card-title">Create/Edit</h5>
          </div>
          <div class="card-body bg-light text-dark">
            <div class="alert alert-success" *ngIf="showSuccess">
              Your new timetable has been created.
            </div>

            <div class="alert alert-danger" *ngIf="showError">
              There was a problem processing your request, please contact your systems administrator
            </div>

            <div *ngIf="!showSuccess && !showError">
              <websilk-select-field id="selType" name="selType" [bindItems]="selTypes" [(ngModel)]="entity.Type" labelText="Timetable Type" idKey="Id" descKey="Description" required="true" displayInline="false"></websilk-select-field>
              <websilk-text-field id="txtTitle" name="txtTitle" [(ngModel)]="entity.Title" displayInline="false" labelText="Title" required="true" pattern=""></websilk-text-field>
              <websilk-text-field id="txtSortOrder" name="txtSortOrder" [(ngModel)]="entity.SortOrder" displayInline="false" labelText="Sort Order" required="true" pattern="^\d{1,3}$"></websilk-text-field>
              <div class="form-group">
                <label class="btn btn-dark">
                  <div>
                    <input type="file" class="form-control" required (change)="selectFile($event)">
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-sm-6 text-left">
                <button type="button" class="btn btn-gateways" (click)="Save(thisForm,$event)" *ngIf="!processing && !showSuccess">
                  <span>Save</span>
                </button>
                <button type="button" class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i>&nbsp;Processing...</button>
              </div>
              <div class="col-sm-6 text-right">
                <button class="btn btn-dark" (click)="Cancel()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="card mt-3">
        <div class="card-header bg-dark text-light">
          <h5 class="card-title">List</h5>
        </div>
        <div class="card-body bg-light text-dark">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Type</th>
                <th>Sort Order</th>
                <th>Title</th>
                <th>Size</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of entities">
                <td>{{formatType(item.Type)}}</td>
                <td>{{item.SortOrder}}</td>
                <td><a [(href)]="item.FileUrl" target="_blank">{{item.Title}}</a></td>
                <td>{{item.FileSize}}</td>
                <td>
                  <button class="btn btn-sm" (click)="Edit(item)"><i class="fa fa-edit"></i>&nbsp;Edit</button>
                  <button class="btn btn-sm" (click)="Delete(item)"><i class="fa fa-trash"></i>&nbsp;Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>

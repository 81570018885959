export class InteractiveInstanceRequest {
    Title: String;
    InteractiveModuleTemplateId: number;
    SupervisorUserId: number;
    ZoomAccountId: number;
    Published: boolean;
    RepeatEvery: number;
    RepeatFor: number;
    AreRepeatSessions: Boolean;
    Sessions: InteractiveInstanceRequestSession[];
    RepeatSessions: InteractiveInstanceRequestSession[];
    CreateExternalSessions: boolean;

    constructor() {
        this.Title = "";
        this.Sessions = [];
        this.RepeatSessions = [];
        this.AreRepeatSessions = false;
    }
}

export class InteractiveInstanceRequestSession {
    SessionDate: Date;
    SessionTime: string;
    PresenterId: number;
    SessionDurationMinutes: number;

    constructor() {
        this.SessionTime = "";
    }
}
import { TimetableType } from ".";

export class Timetable {
    Id: number;
    Type: TimetableType;
    Title: string;
    SortOrder: number;
    FileName: string;
    FileExtension: string;
    FileSize: number;
    FileUrl: string;

    constructor() {
        this.Id = 0;
        this.Type = 0;
        this.Title = "";
        this.SortOrder = 0;
        this.FileName = "";
        this.FileExtension = "";
        this.FileSize = 0;
        this.FileUrl = "";
    }
}
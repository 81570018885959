<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12">

      <form #thisForm="ngForm">
        <div class="card">
          <div class="card-header bg-dark text-light">
            <h5 class="card-title">Create/Edit</h5>
          </div>
          <div class="card-body bg-light text-dark">
            <div class="row">
              <div class="col-sm-7">
                <websilk-text-field id="txtTitle" name="txtTitle" [(ngModel)]="entity.Title" displayInline="false" labelText="Title" required="true" pattern=""></websilk-text-field>
              </div>
              <div class="col-sm-5">
                <img [src]="GetImageUrl(entity)">
                <div>
                  <button class="btn btn-dark" *ngIf="entity.Id" (click)="ChangeImage()">Change Image</button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <websilk-text-field id="txtSummary" name="txtSummary" [(ngModel)]="entity.Summary" displayInline="false" labelText="Title" required="false" pattern="" multiLine="true"></websilk-text-field>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-sm-6 text-left">
                <button type="button" class="btn btn-gateways" (click)="Save(thisForm,$event)" *ngIf="!processing">
                  <span>Save</span>
                </button>
                <button type="button" class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i>&nbsp;Processing...</button>
              </div>
              <div class="col-sm-6 text-right">
                <button class="btn btn-dark" (click)="Cancel()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="card mt-3">
        <div class="card-header bg-dark text-light">
          <h5 class="card-title">List</h5>
        </div>
        <div class="card-body bg-light text-dark">
          <table class="table table-striped">
            <thead>
              <tr>
                <th></th>
                <th>Title</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of entities">
                <td><img [src]="GetImageUrl(item)" width="50px"></td>
                <td>{{item.Title}}</td>
                <td>
                  <button class="btn btn-sm" (click)="Edit(item)"><i class="fa fa-edit"></i> Edit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- Grey top line -->
<!-- <div class="row" style="line-height: 40px; height: 40px; background-color: #515151; color: white;"></div> -->
<header>

  <nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
    <div class="container">
      <a class="navbar-brand" [routerLink]="['/']">
        <img src="/assets/images/gateways-online.png" height="50px" style="margin-left:-35px;">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-collapse collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/']">Home</a>
          </li>
          <li class="nav-item" *ngIf="loggedIn && isInRole('Administrators')">
            <a class="nav-link" [routerLink]="['/learning-modules']">On Demand</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/interactive-modules']">Live &amp; Interactive</a>
          </li>

          <!-- ADMIN -->
          <li class="nav-item dropdown" *ngIf="loggedIn && isInRole('Administrators')">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Administrator
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" [routerLink]="['/admin']">Dashboard</a>
              <a class="dropdown-item" [routerLink]="['/admin/lookups/academic-focuses']">Common Data</a>
              <a class="dropdown-item" [routerLink]="['/admin/lookups/credit-coupons']">Credit Coupons</a>
              <a class="dropdown-item" [routerLink]="['/admin/learning-modules']">On Demand Programs</a>
              <a class="dropdown-item" [routerLink]="['/admin/interactive-module-templates']">Live &amp; Interactive Programs</a>
            </div>
          </li>
          <!-- /ADMIN -->
          <!-- PARENT -->
          <li class="nav-item dropdown" *ngIf="loggedIn && isInRole('Parent')">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Parent menu
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" [routerLink]="['/parent']">Dashboard</a>
              <a class="dropdown-item" [routerLink]="['/parent/children']">My children</a>
              <a class="dropdown-item" [routerLink]="['/parent/enrolments']">My enrolments</a>
              <a class="dropdown-item" [routerLink]="['/parent/redeem-coupon']">My Credit Coupons</a>
            </div>
          </li>
          <!-- /PARENT -->
          <!-- STUDENT -->
          <li class="nav-item dropdown" *ngIf="loggedIn && isInRole('Student')">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Student menu
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" [routerLink]="['/student']">Dashboard</a>
            </div>
          </li>
          <!-- /STUDENT -->
          <!-- SCHOOL -->
          <li class="nav-item dropdown" *ngIf="loggedIn && isInRole('School')">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              School menu
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" [routerLink]="['/school']">Enrolments</a>
            </div>
          </li>
          <!-- /SCHOOL -->
          <!-- SUPERVISOR -->
          <li class="nav-item dropdown" *ngIf="loggedIn && isInRole('Supervisor')">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Supervisor menu
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" [routerLink]="['/supervisor']">Dashboard</a>
            </div>
          </li>
          <!-- /SUPERVISOR -->
          <!-- PRESENTER -->
          <li class="nav-item dropdown" *ngIf="loggedIn && isInRole('Presenter')">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Presenter menu
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" [routerLink]="['/presenter']">Dashboard</a>
            </div>
          </li>
          <!-- /PRESENTER -->

        </ul>

        <!-- Not Logged In -->
        <ul class="navbar-nav ml-auto" *ngIf="!loggedIn">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/login']">Sign in <i class="fa fa-sign-in"></i></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/register']">Register <i class="far fa-user-circle"></i></a>
          </li>
        </ul>
        <!-- /Not Logged In -->

        <!-- Logged In - Supervisor -->
        <ul class="navbar-nav ml-auto" *ngIf="loggedIn">
          <li class="nav-item dropdown float-right">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <strong><i class="fa fa-user-circle"></i> {{currentUser.FirstName}} {{currentUser.Surname}}</strong>
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Sign out</a>
              <div role="separator" class="dropdown-divider"></div>
              <span class=" d-block pl-4 pr-4 pt-2 pb-2">Credit Balance: ${{MyCreditBalance|number:'1.2-2'}}</span>
            </div>
          </li>
        </ul>
        <!-- /Logged In - Supervisor -->

      </div>
    </div>
  </nav>

</header>
<!-- /Grey top line -->

<!-- Logo -->
<!-- <div class="container mt-2 mb-2" *ngIf="!showBanner">
  <a [routerLink]="['/']">
    <img src="/assets/images/logo.png" class="mw-100" />
  </a>
</div> -->
<!-- /Logo -->

<!-- Heading -->
<div class="bg-gateways text-white pt-2 pb-2" *ngIf="!showBanner">
  <div class="container">
    <h1>
      {{title}}
    </h1>
  </div>
</div>
<admin-top-menu [mode]="adminMenuMode"></admin-top-menu>

<!-- <div class="home-banner" *ngIf="showBanner">
  <div class="container">
    <div class="col-sm-12 m-auto" style="height:20px;">
    </div>
  </div>
</div> -->
<!-- /Heading -->

<!-- Content -->
<div class="pb-5 container-fluid" [class.mt-2]="!showBanner">
  <router-outlet></router-outlet>
</div>
<!-- /Content -->

<!-- Footer -->
<div class="bg-dark text-white mt-3 fixed-bottom">
  <div class="container text-right">
    &copy; 2020 GATEWAYS Education
  </div>
</div>
<!-- /Footer -->

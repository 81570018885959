<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-2">
      <websilk-date-field id="dtStartDate" name="dtStartDate" [(ngModel)]="startDate" labelText="Start Date" required="true" displayInline="false"></websilk-date-field>
    </div>
    <div class="col-sm-2">
      <websilk-date-field id="dtEndDate" name="dtEndDate" [(ngModel)]="endDate" labelText="End Date" required="true" displayInline="false"></websilk-date-field>
    </div>
    <div class="col-sm-3">
      <label class="form-label">Presenter</label>
      <div>
        <select class="form-control" [(ngModel)]="filterPresenter">
          <option value="0">All Presenters</option>
          <option *ngFor="let presenter of presenters" [value]="presenter.Id">{{presenter.Firstname}} {{presenter.Surname}}</option>
        </select>
      </div>
    </div>
    <div class="col-sm-3">
      <label class="form-label">Supervisor</label>
      <div>
        <select class="form-control" [(ngModel)]="filterSupervisor">
          <option value="0">All Supervisors</option>
          <option *ngFor="let supervisor of supervisors" [value]="supervisor.Id">{{supervisor.FirstName}} {{supervisor.Surname}}</option>
        </select>
      </div>
    </div>
    <div class="col-sm-2">
      <div class="form-group">
        <label class="form-label">&nbsp;</label>
        <div>
          <button class="btn btn-dark" (click)="FindSessions()">Show Calendar</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <div class="row" *ngIf="!loading">
    <div class="col-sm-12">

      <div class="d-flex flex-row flex-nowrap overflow-auto" *ngIf="sessions.length">
        <div class="card card-block mx-2" style="min-width: 300px; width: 300px; min-height:500px;" *ngFor="let thisDate of dateArray">
          <div class="card-header text-center bg-dark text-light">
            <strong class="d-block">{{thisDate|date:'EEE'}}</strong>
            <strong class="d-block">{{thisDate|date:'dd/MM/yyyy'}}</strong>
          </div>
          <div class="card-body">

            <div class="card mb-1" *ngFor="let session of GetSessionsForDate(thisDate)">
              <div class="card-body">
                <strong class="d-block">{{session.StartDateTime|date:'h:mm aa'}} - {{session.FinishDateTime|date:'h:mm aa'}}</strong>
                <small class="d-block">{{session.InteractiveModule.Title}}</small>
                <div>
                  <small>Presenter: </small><span class="badge badge-danger">{{session.Presenter.Firstname}} {{session.Presenter.Surname}} <a class="text-white" href="javascript:void(0)" (click)="EditSession(session)"><i class="fa fa-edit"></i></a></span>
                </div>
                <div>
                  <small>Supervisor: </small><span class="badge badge-dark">{{session.SupervisorUser?.FirstName}} {{session.SupervisorUser?.Surname}} <a class="text-white" href="javascript:void(0)" (click)="EditSession(session)"><i class="fa fa-edit"></i></a></span>
                </div>
              </div>
              <div class="card-footer p-1">
                <div class="text-right">
                  <a class="btn btn-sm btn-gateways font-size-1 m-0 mr-1" [href]="session.SessionLink" target="_blank" *ngIf="session.ExternalSessionCreated">Join Session</a>
                  <a class="btn btn-sm btn-dark font-size-1 m-0" [href]="session.SessionLinkHost" target="_blank" *ngIf="session.ExternalSessionCreated">Start Session</a>
                  <small class="mr-2" *ngIf="!session.ExternalSessionCreated">Zoom session not available</small>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

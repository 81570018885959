import { Component, Input, OnInit } from '@angular/core';

import { LearningModuleModel, LearningModuleTypeModel } from '../../models';

@Component({
  selector: 'learning-module-card',
  template:
    `
    <div class="card h-100">
      <img class="card-img-top" src="/assets/data/samples/{{model.DisplayImage}}" alt="Card image cap">
      <div class="card-body position-relative pt-5">
        <div class="position-absolute w-100 p-2 bg-dark text-white top-0 mb-0 text-left pl-3 left-0">
          <i class="fa fa-book"></i><span>&nbsp;{{model.LearningModuleType.Title}}</span>
        </div>
        <h5 class="card-title mb-0">{{model.Title}}</h5>
        <small class="text-muted"><i class="fa fa-user"></i> {{model.Author}}</small>
        <p class="card-text mt-1">{{model.ShortDescription}}</p>
      </div>
      <div class="card-footer p-0">
        <a class="btn btn-lg btn-gateways btn-square-top w-100" [routerLink]="['/learning-modules/',model.UrlName]"><i class="fa fa-link"></i> View Module</a>
      </div>
    </div>
  `
})
export class LearningModuleCard {
  @Input() model: LearningModuleModel;

  constructor() { }
}




import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { StateService, InteractiveModuleSessionService, InteractiveModuleTemplateService } from '../../../../services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ModalAdminCreateZoomComponent, ModalAdminAssignSupervisorComponent } from '../../../../components';

import { InteractiveModuleTemplateModel, InteractiveModuleSessionModel } from '../../../../models';
import { } from 'src/app/components';

@Component({
    selector: 'page-admin-session-list',
    templateUrl: './page-admin-session-list.component.html',
})
export class PageAdminSessionListComponent implements OnInit {
    loading: boolean = true;
    sessions: InteractiveModuleSessionModel[] = [];
    templates: InteractiveModuleTemplateModel[] = [];
    startDate: Date;
    endDate: Date;
    filter: any;
    selectedSessionIds: number[] = [];

    constructor(public stateService: StateService, public interactiveModuleSessionService: InteractiveModuleSessionService, private modalService: NgbModal, private activatedRoute: ActivatedRoute, public interactiveModuleTemplateService: InteractiveModuleTemplateService, private datePipe: DatePipe) {
        var me = this;

        me.filter = {
            DayOfWeek: "",
            TemplateId: 0,
            TimeOfDay: ""
        };

        me.interactiveModuleTemplateService.FindAll().subscribe(data => {
            me.templates = data;
            me.loading = false;
        });

        me.activatedRoute.params.subscribe(val => {
        });
    }

    ngOnInit() {
        var me = this;
    }

    LoadSessions() {
        let me = this;
        me.interactiveModuleSessionService.FindByDateRange(me.startDate, me.endDate).subscribe(data => {
            me.sessions = data;
        }, err => {
            alert('Something went wrong')
        });
    }

    FilteredSessions() {
        let me = this;
        let items = me.sessions;

        me.datePipe.transform(Date.now(), 'yyyy-MM-dd');

        if (me.filter.TemplateId != 0) {
            items = items.filter(o => o.InteractiveModule.InteractiveModuleTemplateId == me.filter.TemplateId);
        }
        if (me.filter.DayOfWeek != "") {
            items = items.filter(o => me.datePipe.transform(o.StartDateTime, 'EEEE') == me.filter.DayOfWeek);
        }
        if (me.filter.TimeOfDay != "") {
            items = items.filter(o => me.datePipe.transform(o.StartDateTime, 'aa') == me.filter.TimeOfDay);
        }

        return items;
    }

    CreateZoomSessions() {
        let me = this;
        const modalRef = me.modalService.open(ModalAdminCreateZoomComponent, {
            scrollable: true
        });
        modalRef.componentInstance.sessionIds = me.selectedSessionIds;
        modalRef.result.then((result) => {
            me.LoadSessions();
        });
    }

    AssignSupervisor() {
        let me = this;
        const modalRef = me.modalService.open(ModalAdminAssignSupervisorComponent, {
            scrollable: true
        });
        modalRef.componentInstance.sessionIds = me.selectedSessionIds;
        modalRef.result.then((result) => {
            me.LoadSessions();
        });
    }

    IsChecked(sessionId) {
        let me = this;
        let existingIds = me.selectedSessionIds;
        let exists = existingIds.filter(o => o == sessionId).length > 0;
        return exists;
    }

    CheckChanged(e, sessionId) {
        let me = this;
        let existingIds = me.selectedSessionIds;
        let exists = existingIds.filter(o => o == sessionId).length > 0;
        // If id exists, remove it, if it doesn't exist, add it
        if (exists) {
            existingIds.splice(existingIds.findIndex(o => o == sessionId), 1);
        } else {
            existingIds.push(sessionId)
        }

        me.selectedSessionIds = existingIds;
    }
}
<div class="container">
  <div class="row">
    <div class="col-sm-4 mb-2">
      <div class="card h-100">
        <!-- <img class="card-img-top" src="https://via.placeholder.com/350x200" alt="Card image cap"> -->
        <div class="card-body">
          <h5 class="card-title">My Account</h5>
          <p class="card-text">Manage your contact details and security details.</p>
        </div>
        <div class="card-footer">
          <button class="btn btn-gateways float-left" (click)="EditDetails()">Manage Account</button>
          <button class="btn btn-dark float-right" (click)="ResetPassword()">Reset Password</button>
        </div>
      </div>
    </div>

    <div class="col-sm-4 mb-2">
      <div class="card h-100">
        <!-- <img class="card-img-top" src="https://via.placeholder.com/350x200" alt="Card image cap"> -->
        <div class="card-body">
          <h5 class="card-title">My Children</h5>
          <p class="card-text">Manage the logon accounts for your children.</p>
        </div>
        <div class="card-footer">
          <a class="btn btn-gateways w-100" [routerLink]="['/parent/children']">Manage Children</a>
        </div>
      </div>
    </div>

    <div class="col-sm-4 mb-2">
      <div class="card h-100">
        <!-- <img class="card-img-top" src="https://via.placeholder.com/350x200" alt="Card image cap"> -->
        <div class="card-body">
          <h5 class="card-title">My Enrolments</h5>
          <p class="card-text">View and management current enrolments and all enrolment history.</p>
        </div>
        <div class="card-footer">
          <a [routerLink]="['/parent/enrolments']" class="btn btn-gateways w-100">Manage Enrolments</a>
        </div>
      </div>
    </div>

    <div class="col-sm-4 mb-2">
      <div class="card h-100">
        <div class="card-body">
          <h5 class="card-title">Credit Coupons</h5>
          <p class="card-text">View my credit balance or redeem coupons.</p>
        </div>
        <div class="card-footer">
          <a [routerLink]="['/parent/redeem-coupon']" class="btn btn-gateways w-100">Manage Credit Coupons</a>
        </div>
      </div>
    </div>

  </div>
</div>

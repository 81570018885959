import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

import { StateService, ParentService, InteractiveModuleService, InteractiveModuleEnrolmentService, InteractiveModuleTemplateResourceService } from '../../../services';

import { InteractiveModuleModel, UserModel, InteractiveModuleEnrolmentModel, InteractiveModuleEnrolmentStudentModel, StudentModel, InteractiveModuleSessionModel, InteractiveModuleTemplateResourceModel } from '../../../models';

@Component({
    selector: 'page-interactive-module-instance-component',
    templateUrl: './page-interactive-module-instance.component.html',
    styleUrls: ['./page-interactive-module-instance.component.scss']
})
export class PageInteractiveInstanceComponent implements OnInit {
    instanceId: number;
    loading: boolean = true;
    entity: InteractiveModuleModel;
    currentUser: UserModel;
    enrolments: InteractiveModuleEnrolmentModel[] = [];
    enrolledStudents: InteractiveModuleEnrolmentStudentModel[] = [];
    isPresenter: boolean = false;
    isSupervisor: boolean = false;
    isParent: boolean = false;
    myEnrolments: InteractiveModuleEnrolmentModel[] = [];
    alreadyEnrolled: boolean = false;
    resources: InteractiveModuleTemplateResourceModel[] = [];

    groupedModule: any;
    allGroupedModules: any[];

    constructor(private router: Router, private datePipe: DatePipe, private activatedRoute: ActivatedRoute, public stateService: StateService, public parentService: ParentService, public interactiveModuleService: InteractiveModuleService, public interactiveModuleEnrolmentService: InteractiveModuleEnrolmentService, public interactiveModuleTemplateResourceService: InteractiveModuleTemplateResourceService) {
        var me = this;

        me.activatedRoute.params.subscribe(val => {
            let id = me.activatedRoute.snapshot.params.id;
            me.instanceId = id;

            me.LoadPageData();
        });

    }

    ngOnInit() {
        var me = this;
    }

    LoadPageData() {
        let me = this;

        me.loading = true;

        me.currentUser = me.stateService.userModel;
        me.stateService.userModel$.subscribe(data => {
            me.currentUser = data;
            me.CheckUserRoles()
        });

        me.CheckUserRoles();

        me.interactiveModuleService.FindByIdPublic(me.instanceId).subscribe(data => {
            me.entity = data;

            me.GetOtherInstances();
            me.GetEnrolments();
        });
    }

    GetResources() {
        let me = this;
        me.interactiveModuleTemplateResourceService.FindByTemplateId(me.entity.InteractiveModuleTemplateId).subscribe(data => {
            me.resources = data;
        });
    }

    CheckUserRoles() {
        let me = this;
        if (!me.currentUser) {
            me.isParent = false;
            me.isPresenter = false;
            me.isSupervisor = false;
            return;
        }

        if (me.currentUser.Roles.filter(o => o.Name == "Parent").length) {
            me.isParent = true;
        }
        if (me.currentUser.Roles.filter(o => o.Name == "Presenter").length) {
            me.isPresenter = true;
        }
        if (me.currentUser.Roles.filter(o => o.Name == "Supervisor").length) {
            me.isSupervisor = true;
        }
    }

    GetAvailableCount() {
        let me = this;
        let maxAllowed = me.entity.MaxEnrolmentsTotal;
        let totalEnrolled = me.TotalEnrolmentCount();
        let avail = maxAllowed - totalEnrolled;
        return avail;
    }

    GetOtherInstances() {
        let me = this;
        let templateId = me.entity.InteractiveModuleTemplateId;

        me.interactiveModuleService.FindFutureInstancesGroupedByTemplate(templateId).subscribe(data => {
            me.loading = false;
            me.allGroupedModules = data;
            if (data.length) {
                me.groupedModule = data[0];
            }
        });
    }

    GetImageUrl(item) {
        var me = this;
        let url = '';
        if (me.entity) {
            url = 'https://gateways-online-content.s3-ap-southeast-2.amazonaws.com/' + item.StorageFolderName + '/' + item.TileImageName;
        }
        return url;
    }

    GetResourceUrl(item) {
        var me = this;
        var url = '';
        url = 'https://gateways-online-content.s3-ap-southeast-2.amazonaws.com/' + me.entity.UrlName + '/' + item.ResourceFilename;
        return url;
    }

    GetEnrolments() {
        let me = this;
        if (me.isPresenter || me.isSupervisor) {
            me.interactiveModuleEnrolmentService.FindByInstance(me.instanceId).subscribe(data => {
                me.enrolments = data;
                me.enrolledStudents = me.GetEnrolmentStudents(data);
            });
        } else {
            me.interactiveModuleEnrolmentService.MyEnrolments(true).subscribe(data => {
                me.myEnrolments = data;
                if (me.myEnrolments.filter(o => o.InteractiveModuleId == me.entity.Id).length) {
                    me.alreadyEnrolled = true;
                }
            });
        }
    }

    GetEnrolmentStudents(data) {
        let items: InteractiveModuleEnrolmentStudentModel[] = [];
        for (let e = 0; e < data.length; e++) {
            let enrolment = data[e];
            for (let s = 0; s < enrolment.InteractiveModuleEnrolmentStudents.length; s++) {
                items.push(enrolment.InteractiveModuleEnrolmentStudents[s])
            }
        }
        return items;
    }

    TotalEnrolmentCount() {
        let me = this;
        //let students = me.enrolments.map(item => item.InteractiveModuleEnrolmentStudents).reduce((prev: InteractiveModuleEnrolmentStudentModel[], curr) => prev.concat(curr), []);
        // return students.length;

        let total = 0;
        let instances = me.groupedModule ? me.groupedModule.Instances.filter(o => o.InteractiveModule.Id == me.entity.Id) : [];
        if (instances.length) {
            let instance = instances[0];
            total = instance.TotalEnrolments;
        }
        return total;
    }

    GetResourceLink(fileName: string) {
        let me = this;
        var storageFolder = me.entity.InteractiveModuleTemplate
        var url = 'https://gateways-online-content.s3-ap-southeast-2.amazonaws.com/' + storageFolder + '/' + fileName;
    }

    IsSessionComplete(session) {
        let now = new Date();
        let startDateTime = session.StartDateTime;

        let dYear = parseInt(this.datePipe.transform(startDateTime, 'yyyy'));
        let dMonth = parseInt(this.datePipe.transform(startDateTime, 'M')) - 1;
        let dDay = parseInt(this.datePipe.transform(startDateTime, 'd'));
        let dHour = parseInt(this.datePipe.transform(startDateTime, 'H'));
        let dMinute = parseInt(this.datePipe.transform(startDateTime, 'm'));

        let sessionStart = new Date(dYear, dMonth, dDay, dHour, dMinute, 0);
        let sDiff = (sessionStart.getTime() - now.getTime()) / 1000;

        // Session has already started, cannot enrol into it
        return sDiff <= 0;
    }

}

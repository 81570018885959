import { Component, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AWSS3Service, TimetableService } from '../../../services';
import { FormFunctions } from '../../../functions';
import { GenericLookupModel, Timetable, TimetableType, UpdateImageRequestModel } from '../../../models';
import { Observable } from 'rxjs';

@Component({
    selector: 'page-admin-timetables',
    templateUrl: './page-admin-timetables.component.html',
})
export class PageAdminTimetablesComponent implements OnInit {
    loading: boolean = true;
    processing: boolean = false;
    entities: Timetable[] = [];
    entity: Timetable = new Timetable();
    selTypes: GenericLookupModel[] = [];

    selectedFiles: FileList;
    showSuccess: boolean = false;
    showError: boolean = false;

    constructor(private formFunctions: FormFunctions, private modalService: NgbModal, public timetableService: TimetableService, public aWSS3Service: AWSS3Service) {
        var me = this;
        me.LoadEntities();
    }

    ngOnInit() {
        var me = this;
        me.selTypes.push(new GenericLookupModel(TimetableType.InteractiveModule, "Interactive Modules"));
    }

    formatType(type: TimetableType): string {
        return TimetableType[type];
    }

    LoadEntities() {
        let me = this;
        me.loading = true;
        me.timetableService.FindAll().subscribe(data => {
            me.entities = data;
            me.loading = false;
        });
    }

    Edit(item: Timetable) {
        let me = this;
        var clone = <Timetable>me.formFunctions.DeepCopy(item);
        me.entity = clone;
    }

    Delete(item: Timetable) {
        let me = this;
        if (confirm('Are you sure you want to delete this item?')) {
            me.processing = true;
            me.timetableService.Delete(item.Id).subscribe(data => {
                me.processing = false;
                me.entity = new Timetable();
                me.LoadEntities();
            });
        }
    }

    Save(form, e) {
        let me = this;
        if (me.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            if (me.entity.Id) {
                me.timetableService.Edit(me.entity).subscribe(data => {
                    me.processing = false;
                    me.entity = new Timetable();
                    me.LoadEntities();
                });
            } else {
                me.Create(form, e);
            }
        }
    }

    Cancel() {
        let me = this;
        me.entity = new Timetable();
        me.LoadEntities();
        me.showSuccess = false;
        me.showError = false;
    }

    Create(form, e) {
        let me = this;

        if (this.formFunctions.isFormValid(form, e)) {

            me.processing = true;
            me.showError = false;
            me.showSuccess = false;

            const file = this.selectedFiles.item(0);
            if (!me.entity.Title) {
                me.entity.Title = file.name;
            }
            let useFilename = me.generateUUID() + "." + file.name.split('.').pop();
            me.entity.FileName = useFilename;
            me.entity.FileSize = file.size;
            me.aWSS3Service.UploadFile(me.baseFolder(me.entity), file, useFilename).on('httpUploadProgress', function (evt) {
                // evt.loaded is bytes uploaded
                // evt.total is total bytes size
            }).send(function (err, data) {
                if (err) {
                    console.log('There was an error uploading your file: ', err);
                    me.showError = true;
                    me.processing = false;
                    return false;
                }
                else {
                    me.entity.FileUrl = data.Location;
                    me.timetableService.Create(me.entity).subscribe(data => {
                        me.showSuccess = true;
                        me.processing = false;
                        me.entity = new Timetable();
                        me.LoadEntities();
                    }, err => {
                        me.showError = true;
                        me.processing = false;
                    });

                    return true;
                }
            });
        }
    }

    baseFolder(item: Timetable) {
        return `Timetables/${this.formatType(item.Type)}`;
    }

    generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if (d > 0) {//Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
    
    selectFile(event) {
        this.selectedFiles = event.target.files;
    }
}

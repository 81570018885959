import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService, AuthService } from '../../services';
import { UserModel, ResetPasswordRequestModel } from '../../models';
import { FormFunctions, CustomDateParserFormatter } from '../../functions';
import { Validations } from '../../constants'

@Component({
    selector: 'modal-add-child',
    template: `
    <div class="modal-header bg-dark text-white">
      <h4 class="modal-title">Reset Password</h4>
      <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Resetting password for <strong>{{user?.FirstName}} {{user?.Surname}}</strong></p>
        <form #thisForm="ngForm" [class.d-none]="success">
            <websilk-text-field id="txtPassword" name="txtPassword" [(ngModel)]="request.Password" labelText="Password" required="true" pattern="" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" fieldType="password"></websilk-text-field>
            <websilk-text-field id="txtConfirmPassword" name="txtConfirmPassword" [(ngModel)]="request.ConfirmPassword" labelText="Confirm" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" [pattern]="'^'+this.request.Password+'$'" fieldType="password" errorText="Must match password"></websilk-text-field>
        </form>
        <div class="alert alert-success mt-2" *ngIf="success">
            <p>Password successfully updates.</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-gateways" (click)="Update(thisForm,$event)" *ngIf="!processing && !success">Update</button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')" *ngIf="!processing">Close</button>
        <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
    </div>
  `
})
export class ModalResetPasswordComponent {
    @Input() user: UserModel;
    request: ResetPasswordRequestModel = new ResetPasswordRequestModel();
    processing: boolean = false;
    success: boolean = false;

    constructor(public activeModal: NgbActiveModal, public stateService: StateService, public validations: Validations, public formFunctions: FormFunctions, public authService: AuthService) {
        let me = this;
    }
    ngOnInit() {

    }
    Update(form, e) {
        var me = this;
        if (me.formFunctions.isFormValid(form, e)) {
            me.success = false;
            me.processing = true;
            me.request.UserId = me.user.Id;

            me.authService.UpdatePassword(me.request).subscribe(data => {
                me.success = true;
                me.processing = false;
            });
        }
    }
}
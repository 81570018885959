import { Component, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StateService, LocationService, CreditCouponService } from '../../../../services';
import { FormFunctions } from '../../../../functions';
import { CreditCouponModel } from '../../../../models';

@Component({
    selector: 'page-admin-credit-coupons',
    templateUrl: './page-admin-credit-coupons.component.html',
})
export class PageAdminCreditCouponsComponent implements OnInit {
    loading: boolean = true;
    importing: boolean = false;
    importError: string = "";
    processing: boolean = false;
    entities: CreditCouponModel[] = [];
    entity: CreditCouponModel = new CreditCouponModel();

    constructor(private modalService: NgbModal, public stateService: StateService, private formFunctions: FormFunctions, public locationService: LocationService, public creditCouponService: CreditCouponService) {
        var me = this;
        me.LoadEntities();
    }

    ngOnInit() {
        var me = this;
    }

    LoadEntities() {
        let me = this;
        me.loading = true;
        me.creditCouponService.FindAll().subscribe(data => {
            me.entities = data;
            me.loading = false;
        });
    }

    Edit(entity) {

    }

    Delete(entity) {
        let me = this;
        me.creditCouponService.Delete(entity.Id).subscribe(data => {
            me.LoadEntities();
        });
    }

    Save(form, e) {
        let me = this;
        if (me.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            if (me.entity.Id) {
                me.creditCouponService.Edit(me.entity).subscribe(data => {
                    me.processing = false;
                    me.entity = new CreditCouponModel();
                    me.LoadEntities();
                });
            } else {
                me.creditCouponService.Create(me.entity).subscribe(data => {
                    me.processing = false;
                    me.entity = new CreditCouponModel();
                    me.LoadEntities();
                });
            }
        }
    }

    Cancel() {
        let me = this;
        me.entity = new CreditCouponModel();
        me.LoadEntities();
    }

    Import(event, filUpload) {
        let me = this;

        if (!filUpload.files.length) {
            alert('Please select a file first');
            return;
        }

        me.importing = true;
        me.importError = "";
        const file = filUpload.files[0];
        const formData: FormData = new FormData();
        formData.append('files', file, file.name);

        me.creditCouponService.Import(formData).subscribe(data => {
            me.importing = false;
            me.LoadEntities();
        }, err => {
            me.importing = false;
            me.importError = err.message;
        });
    }
}

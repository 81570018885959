export class InteractiveModuleTemplateResourceModel {
    Id: number;
    InteractiveModuleTemplateId: number;
    Title: String;
    Description: String;
    ResourceFilename: String;

    constructor() {
        this.Title = "";
        this.Description = "";
        this.ResourceFilename = "";
    }
}
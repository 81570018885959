import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

import { StateService, SchoolService } from '../../../services';

import { ModalResetPasswordComponent } from '../../../components';

import { SchoolMyEnrolmentsProjectionModel, StudentModel, InteractiveModuleEnrolmentStudentModel } from '../../../models';

@Component({
    selector: 'page-school-enrolments-role',
    templateUrl: './page-school-enrolments-role.component.html',
})
export class PageSchoolEnrolmentsRoleComponent implements OnInit {
    loading: boolean = true;
    entities: InteractiveModuleEnrolmentStudentModel[] = [];
    requestDate: any;

    constructor(private activatedRoute: ActivatedRoute, public stateService: StateService, public schoolService: SchoolService, public modalService: NgbModal) {
        var me = this;

        me.requestDate = me.activatedRoute.snapshot.params.requestDate;

        me.schoolService.FindStudentRoll(me.requestDate).subscribe(data => {
            me.entities = data;
            me.loading = false;
        });
    }

    ngOnInit() {
        var me = this;
    }

    GetSessions(entity: InteractiveModuleEnrolmentStudentModel) {
        let sessions = entity.InteractiveModuleEnrolment.InteractiveModule.InteractiveModuleSessions;

        return sessions;
    }
}

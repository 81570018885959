import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StateService, LearningModuleService, LearningModuleUnitService } from '../../../services';
import { ModalBasicMessageComponent } from '../../../components';
import { LearningModuleModel, LearningModuleUnitModel, LearningModuleUnitResourceModel } from '../../../models';

export interface IMedia {
    title: string;
    src: string;
    type: string;
}

@Component({
    selector: 'page-learning-module-item',
    templateUrl: './page-learning-module-item.component.html',
    styleUrls: ['./page-learning-module-item.style.scss']
})
export class PageLearningModuleItemComponent implements OnInit {
    canAccessModule: boolean = false;
    loading: boolean = true;
    loadingUnits: boolean = true;
    learningModule: LearningModuleModel;
    moduleUnits: LearningModuleUnitModel[];
    currentIndex = 1;
    currentItem: LearningModuleUnitModel;

    constructor(private activatedRoute: ActivatedRoute, public stateService: StateService, public learningModuleService: LearningModuleService, public learningModuleUnitService: LearningModuleUnitService, private modalService: NgbModal) {
        var me = this;

        var urlName = me.activatedRoute.snapshot.params.urlName;
        me.learningModuleService.FindByUrl(urlName).subscribe(data => {
            me.learningModule = data;
            me.stateService.changePageTitle(me.learningModule.Title);
            me.loadModuleUnits();
            me.checkAccess();
            me.loading = false;
        });
    }

    ngOnInit() {
        var me = this;
        // Check if user can access media
        me.canAccessModule = false;
    }

    loadModuleUnits() {
        var me = this;
        me.learningModuleUnitService.FindByLearningModule(me.learningModule.Id).subscribe(data => {
            me.moduleUnits = data;
            me.currentItem = me.moduleUnits.filter(o => o.Idx == me.currentIndex)[0];
            me.loadingUnits = false;
        });
    }

    checkAccess() {
        let me = this;
        me.learningModuleService.CheckEnrolled(me.learningModule.Id).subscribe(data => {
            // A returned record means I am enrolled
            me.canAccessModule = data != null;
        });
    }

    onClickPlaylistItem(item: LearningModuleUnitModel) {
        var me = this;

        if (me.canAccessMedia(item)) {
            this.currentIndex = item.Idx;
            this.currentItem = item;
        } else {
            me.AccessDenied();
        }
    }
    canAccessMedia(item: LearningModuleUnitModel) {
        var me = this;
        if (item.Idx == 1) {
            return true;
        }
        return me.canAccessModule;
    }
    getMediaSource() {
        var me = this;
        // var url = '/assets/data/module-packages/' + encodeURIComponent(me.learningModule.Title) + '/' + encodeURIComponent(me.currentItem.VideoFile);
        var url = 'https://gateways-online-content.s3-ap-southeast-2.amazonaws.com/' + me.learningModule.StorageFolderName + '/' + me.currentItem.VideoFile;
        return url;
    }
    getResourceSource(resource: LearningModuleUnitResourceModel) {
        var me = this;
        var url = '/assets/data/module-packages/' + encodeURIComponent(me.learningModule.Title) + '/' + encodeURIComponent(resource.ResourceFileName);
        return url;
    }

    AccessDenied() {
        const modalRef = this.modalService.open(ModalBasicMessageComponent);
        modalRef.componentInstance.title = 'Access Denied';
        modalRef.componentInstance.content = 'You must register for this module before you can view this video. Please use the "Get Started" link to begin the process.';
    }
}

<div class="modal-header bg-dark text-white">
  <h4 class="modal-title">Add child</h4>
  <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss({success:false,record:null})">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #thisForm="ngForm">
    <h4>Parent</h4>
    <websilk-text-field id="txtParentEmail" name="txtParentEmail" [(ngModel)]="createStudent.ParentEmail" labelText="Parent Email" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" pattern="" displayInline="{{displayInline}}"></websilk-text-field>
    <websilk-text-field id="txtParentFirstname" name="txtParentFirstname" [(ngModel)]="createStudent.ParentFirstname" labelText="Parent First name" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" pattern="" displayInline="{{displayInline}}"></websilk-text-field>
    <websilk-text-field id="txtParentSurname" name="txtParentSurname" [(ngModel)]="createStudent.ParentSurname" labelText="Parent Surname" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" pattern="" displayInline="{{displayInline}}"></websilk-text-field>

    <h4>Child Details</h4>
    <websilk-text-field id="txtFirstname" name="txtFirstname" [(ngModel)]="createStudent.Firstname" labelText="First name" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" pattern="" displayInline="{{displayInline}}"></websilk-text-field>
    <websilk-text-field id="txtSurname" name="txtSurname" [(ngModel)]="createStudent.Surname" labelText="Surname" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" pattern="" displayInline="{{displayInline}}"></websilk-text-field>
    <websilk-date-field id="dtDateOfBirth" name="dtDateOfBirth" [(ngModel)]="createStudent.DateOfBirth" labelText="Date of Birth" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" displayInline="{{displayInline}}"></websilk-date-field>
    <websilk-select-field id="selGender" name="selGender" [bindItems]="genders" [(ngModel)]="createStudent.Gender" labelText="Gender" idKey="Id" descKey="Description" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" displayInline="{{displayInline}}"></websilk-select-field>
    <websilk-select-field id="selYearLevel" name="selYearLevel" [bindItems]="yearLevels" [(ngModel)]="createStudent.YearLevelId" labelText="Year Level" idKey="Id" descKey="Title" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" displayInline="{{displayInline}}"></websilk-select-field>
    <!-- <websilk-text-field id="txtPassword" name="txtPassword" [(ngModel)]="createStudent.Password" labelText="Password" required="true" pattern="" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" fieldType="password" displayInline="{{displayInline}}"></websilk-text-field>
    <websilk-text-field id="txtConfirmPassword" name="txtConfirmPassword" [(ngModel)]="createStudent.ConfirmPassword" labelText="Confirm" required="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9" [pattern]="'^'+this.createStudent.Password+'$'" fieldType="password" errorText="Must match password" displayInline="{{displayInline}}"></websilk-text-field> -->
    <websilk-select-autocomplete-field id="selSchools" name="selSchools" *ngIf="includeExtra" [bindItems]="schools" [(ngModel)]="createStudent.SchoolId" labelText="School" idKey="Id" descKey="Name" required="true" displayInline="{{displayInline}}" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-9"></websilk-select-autocomplete-field>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-gateways" (click)="Register(thisForm,$event)" *ngIf="!processing">Register</button>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close({success:false,record:null})" *ngIf="!processing">Close</button>
  <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
  <div class="alert alert-danger" *ngIf="error && !processing">There was a problem processing your request</div>
</div>

<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12">

      <form #thisForm="ngForm">
        <div class="card">
          <div class="card-header bg-dark text-light">
            <h5 class="card-title">Create/Edit</h5>
          </div>
          <div class="card-body bg-light text-dark">

            <div class="row">
              <div class="col-sm-4">
                <websilk-text-field id="txtFirstname" name="txtFirstname" [(ngModel)]="entity.Firstname" displayInline="false" labelText="First name" required="true" pattern=""></websilk-text-field>
              </div>
              <div class="col-sm-4">
                <websilk-text-field id="txtSurname" name="txtSurname" [(ngModel)]="entity.Surname" displayInline="false" labelText="Surname" required="true" pattern=""></websilk-text-field>
              </div>
              <div class="col-sm-4">
                <websilk-text-field id="txtEmail" name="txtEmail" [(ngModel)]="entity.Email" displayInline="false" labelText="Email" required="true" pattern=""></websilk-text-field>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <websilk-text-field id="txtSummary" name="txtSummary" [(ngModel)]="entity.Summary" displayInline="false" labelText="Summary" required="true" pattern="" multiLine="true"></websilk-text-field>
              </div>
            </div>

          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-sm-6 text-left">
                <button type="button" class="btn btn-gateways" (click)="Save(thisForm,$event)" *ngIf="!processing">
                  <span>Save</span>
                </button>
                <button type="button" class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i>&nbsp;Processing...</button>
              </div>
              <div class="col-sm-6 text-right">
                <button class="btn btn-dark" (click)="Cancel()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="card mt-3">
        <div class="card-header bg-dark text-light">
          <h5 class="card-title">List</h5>
        </div>
        <div class="card-body bg-light text-dark">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>First name</th>
                <th>Surname</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of entities">
                <td>{{item.Firstname}}</td>
                <td>{{item.Surname}}</td>
                <td>{{item.User?.Email}}</td>
                <td>
                  <button class="btn btn-sm" (click)="Edit(item)"><i class="fa fa-edit"></i> Edit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container mt-4">

  <div class="row mb-2">
    <div class="col-12">
      <a [routerLink]="['/parent']">&lt; back to dashboard</a>
    </div>
  </div>

  <div class="row justify-content-center" *ngIf="loading">
    <div class="col-12 col-lg-6">
      <websilk-loading></websilk-loading>
    </div>
  </div>

  <div class="row" *ngIf="!loading">


    <div class="col-12 col-lg-6">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Redeem Credit Coupon</h5>
        </div>
        <div class="card-body">
          <p>Please enter the coupon code you have received to claim your credit.</p>
          <div class="form-group">
            <!-- <label class="control-label">Coupon Code</label> -->
            <input type="text" class="form-control" placeholder="Enter credit coupon code" [(ngModel)]="couponCode">
          </div>
        </div>
        <div class="card-footer">
          <div>
            <button class="btn btn-gateways" (click)="RedeemCode()" *ngIf="!processing">Redeem</button>
            <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spinning"></i> Processing...</span>
          </div>
          <div class="alert alert-danger mt-2 text-center" *ngIf="isError">
            Could not redeem code, please contact G.A.T.E.Ways
          </div>
        </div>
      </div>

    </div>


    <div class="col-12 col-lg-6">

      <div class="alert alert-success mb-2" *ngIf="isSuccessful">
        <i class="fa fa-tick fa-2x"></i>
        <span>Credit Coupon successfully redeemed.</span>
      </div>

      <div class="card mb-2">
        <div class="card-header">
          <h5 class="card-title">Current Credit Balance</h5>
        </div>
        <div class="card-body text-center">
          <h4>${{myBalance|number:'1.2-2'}}</h4>
        </div>
      </div>

    </div>

  </div>
</div>

<div class="modal-header bg-dark text-white">
  <h4 class="modal-title">Add/Edit School</h4>
  <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #thisForm="ngForm">
    <websilk-text-field id="txtName" name="txtName" [(ngModel)]="entity.Name" displayInline="false" labelText="School name" required="true" pattern=""></websilk-text-field>
    <websilk-text-field id="txtAddress1" name="txtAddress1" [(ngModel)]="entity.Address1" displayInline="false" labelText="Address Line 1" required="true" pattern=""></websilk-text-field>
    <websilk-text-field id="txtAddress2" name="txtAddress2" [(ngModel)]="entity.Address2" displayInline="false" labelText="Address Line 2" required="false" pattern=""></websilk-text-field>
    <websilk-text-field id="txtAddress3" name="txtAddress3" [(ngModel)]="entity.Address3" displayInline="false" labelText="Address Line 3" required="false" pattern=""></websilk-text-field>
    <websilk-text-field id="txtSuburb" name="txtSuburb" [(ngModel)]="entity.Suburb" displayInline="false" labelText="Suburb" required="true" pattern=""></websilk-text-field>
    <websilk-select-field id="selState" name="selState" [bindItems]="states" [(ngModel)]="entity.State" labelText="State" idKey="Id" descKey="Description" required="true" displayInline="false"></websilk-select-field>
    <websilk-text-field id="txtPostcode" name="txtPostcode" [(ngModel)]="entity.Postcode" required="true" displayInline="false" labelText="Postcode" pattern=""></websilk-text-field>
    <websilk-text-field id="txtContactName" name="txtContactName" [(ngModel)]="entity.ContactName" displayInline="false" labelText="Contact Name" required="false" pattern=""></websilk-text-field>
    <websilk-text-field id="txtContactEmail" name="txtContactEmail" [(ngModel)]="entity.ContactEmail" displayInline="false" labelText="Contact Email" required="false" pattern="" fieldType="email"></websilk-text-field>

    <div class="alert alert-danger text-center" *ngIf="showError">
      <i class="fa fa-exclamation-triangle fa-2x"></i>
      <h5 class="">There was a problem adding this school.</h5>
    </div>
    <div class="alert alert-success text-center" *ngIf="showSuccess">
      <i class="fa fa-tick fa-2x"></i>
      <h5 class="">School updated successfully.</h5>
    </div>
  </form>

</div>
<div class="modal-footer">
  <button class="btn btn-gateways" (click)="UpdateDetails(thisForm,$event)" *ngIf="!processing"><i class="fa fa-save"></i> Save</button>
  <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')" *ngIf="!processing">Close</button>
</div>

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import { LearningModuleEnrolmentModel, EnrolStudentRequest } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class LearningModuleEnrolmentService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<LearningModuleEnrolmentModel>(this.appConfig.getConfig('apiUrl') + '/learning-module-enrolments/' + id, { withCredentials: true, headers: headers });
    }
    Create(request: LearningModuleEnrolmentModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<LearningModuleEnrolmentModel>(this.appConfig.getConfig('apiUrl') + '/learning-module-enrolments', params, { withCredentials: true, headers: headers });
    }
    Update(request: LearningModuleEnrolmentModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<LearningModuleEnrolmentModel>(this.appConfig.getConfig('apiUrl') + '/learning-module-enrolments/' + request.Id, params, { withCredentials: true, headers: headers });
    }
    EnrolStudent(request: EnrolStudentRequest): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<LearningModuleEnrolmentModel>(this.appConfig.getConfig('apiUrl') + '/learning-module-enrolments/enrol-student', params, { withCredentials: true, headers: headers });
    }
}
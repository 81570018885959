import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService, LearningModuleUnitService, AWSS3Service } from '../../services';
import { LearningModuleModel, LearningModuleUnitModel } from '../../models';
import { FormFunctions } from '../../functions';
import { Validations } from '../../constants'

@Component({
    selector: 'modal-upload-image',
    templateUrl: './modal-upload-image.component.html'
})
export class ModalUploadImageComponent {
    @Input() StorageFolderName: string;
    selectedFiles: FileList;
    loading: boolean = true;
    showSuccess: boolean = false;
    showError: boolean = false;
    processing: boolean = false;

    constructor(public activeModal: NgbActiveModal, public stateService: StateService, public validations: Validations, public formFunctions: FormFunctions, public learningModuleUnitService: LearningModuleUnitService, public aWSS3Service: AWSS3Service) {
        let me = this;
    }

    ngOnInit() {
        let me = this;
    }
    Create(form, e) {
        let me = this;

        if (this.formFunctions.isFormValid(form, e)) {

            me.processing = true;
            me.showError = false;
            me.showSuccess = false;

            const file = this.selectedFiles.item(0);
            let useFilename = me.generateUUID() + "." + file.name.split('.').pop();
            me.aWSS3Service.UploadFile(me.StorageFolderName, file, useFilename).on('httpUploadProgress', function (evt) {
                // evt.loaded is bytes uploaded
                // evt.total is total bytes size
            }).send(function (err, data) {
                if (err) {
                    console.log('There was an error uploading your file: ', err);
                    me.showError = true;
                    me.processing = false;
                    return false;
                }
                else {
                    me.activeModal.close(useFilename)
                    return true;
                }
            });

        }
    }

    generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if (d > 0) {//Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    selectFile(event) {
        this.selectedFiles = event.target.files;
    }
}
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ServiceHelper } from '../config';
import { GenericLookupModel } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class GenericDataService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    GetStates(): GenericLookupModel[] {
        let items: GenericLookupModel[] = [];

        items.push(new GenericLookupModel('ACT', 'ACT'));
        items.push(new GenericLookupModel('NSW', 'NSW'));
        items.push(new GenericLookupModel('NT', 'NT'));
        items.push(new GenericLookupModel('QLD', 'QLD'));
        items.push(new GenericLookupModel('SA', 'SA'));
        items.push(new GenericLookupModel('TAS', 'TAS'));
        items.push(new GenericLookupModel('VIC', 'VIC'));
        items.push(new GenericLookupModel('WA', 'WA'));

        return items;
    }
}
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import { PaymentTransactionModel } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class PaymentTransactionService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<PaymentTransactionModel>(this.appConfig.getConfig('apiUrl') + '/payment-transactions/' + id, { withCredentials: true, headers: headers });
    }
    Create(request: PaymentTransactionModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<PaymentTransactionModel>(this.appConfig.getConfig('apiUrl') + '/payment-transactions', params, { withCredentials: true, headers: headers });
    }
    Update(request: PaymentTransactionModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<PaymentTransactionModel>(this.appConfig.getConfig('apiUrl') + '/payment.transactions/' + request.Id, params, { withCredentials: true, headers: headers });
    }
}
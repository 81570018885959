<div class="modal-header bg-dark text-white">
  <h4 class="modal-title">New Resource</h4>
  <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form #thisForm="ngForm">

    <div class="alert alert-success" *ngIf="showSuccess">
      Your new resource has been created.
    </div>

    <div class="alert alert-danger" *ngIf="showError">
      There was a problem processing your request, please contact your systems administrator
    </div>

    <div *ngIf="!showSuccess && !showError">
      <websilk-text-field id="txtTitle" name="txtTitle" placeholderText="" [(ngModel)]="request.Title" labelText="Title" required="true" pattern="" displayInline="false" errorText=""></websilk-text-field>
      <websilk-text-field id="txtDescription" name="txtDescription" multiLine="true" placeholderText="" [(ngModel)]="request.Description" labelText="Description" required="true" pattern="" displayInline="false" errorText=""></websilk-text-field>
      <div class="form-group">
        <label class="btn btn-dark">
          <div>
            <input type="file" class="form-control" required (change)="selectFile($event)">
          </div>
        </label>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer" *ngIf="!showSuccess && !showError">
  <button type="button" class="btn btn-gateways" (click)="Create(thisForm,$event)" *ngIf="!processing">Create</button>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')" *ngIf="!processing">Close</button>
  <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
</div>
<div class="modal-footer" *ngIf="showSuccess || showError">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>

export class EnrolStudentRequest {
    ParentId: number;
    StudentIds: number[];
    OnAccount: boolean;
    ManualEnrolment: boolean;
    Amount: number;
    ModuleId: number;
    NameOnCard: string;
    CardNumber: string;
    ExpiryMonth: string;
    ExpiryYear: string;
    Cvv: string;
    RecaptchaResponse: string;
    UseCredit: boolean;

    constructor() {
        this.StudentIds = [];
        this.OnAccount = false;
        this.ManualEnrolment = false;
        this.NameOnCard = "";
        this.CardNumber = "";
        this.ExpiryMonth = "";
        this.ExpiryYear = "";
        this.Cvv = "";
        this.RecaptchaResponse = "";
        this.UseCredit = false;
    }
}
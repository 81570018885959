import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { UserModel } from '../models';

@Injectable()
export class ServiceHelper {
    GetHeaders(): any {
        var str = localStorage.getItem('user');
        var user: UserModel = JSON.parse(str);

        if (user) {
            return new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.Token });
        } else {
            return new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' });
        }
    }
    GetBlobHeaders(): any {
        var str = localStorage.getItem('user');
        var user: UserModel = JSON.parse(str);

        if (user) {
            return new HttpHeaders({ 'Authorization': 'Bearer ' + user.Token });
        } else {
            return new HttpHeaders({ 'Authorization': 'Bearer ' });
        }
    }
    GetUploadHeaders(): any {
        var str = localStorage.getItem('user');
        var user: UserModel = JSON.parse(str);

        if (user) {
            return new HttpHeaders({ 'Accept': 'application/json', 'Authorization': 'Bearer ' + user.Token });
        } else {
            return new HttpHeaders({ 'Accept': 'application/json', 'Authorization': 'Bearer ' });
        }
    }
    GetTempHeaders(): any {
        var str = localStorage.getItem('temp_user');
        var user: UserModel = JSON.parse(str);

        if (user) {
            return new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.Token });
        } else {
            return new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' });
        }
    }
    handleError(error: Response) {
        console.error("Error: HTTP status " + error.status);
        return Observable.throw(error || 'Server error: UserService');
    }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { StateService, AuthService, AnalyticsService, CreditCouponService } from './services';
import { UserModel } from './models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = '';
  loggedIn: boolean = false;
  currentUser: UserModel;
  showBanner: boolean = false;
  adminMenuMode: string = '';
  MyCreditBalance: number = 0;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title, public stateService: StateService, public authService: AuthService, public analyticsService: AnalyticsService, public creditCouponService: CreditCouponService) {
    var me = this;
    // Page Title
    me.title = me.stateService.pageTitle;
    me.stateService.pageTitle$.subscribe(data => {
      me.title = data;
    });

    me.currentUser = me.stateService.userModel;
    me.checkLoggedIn();

    me.stateService.userModel$.subscribe(data => {
      me.currentUser = data;
      me.loggedIn = data != null;
      me.checkLoggedIn();
    });

    router.events.subscribe((val) => {
      // Analytics
      if ((val instanceof NavigationEnd)) {
        this.analyticsService.routeChange(val.url);
      }
      // Set the title
      var titleArr = me.getTitle(router.routerState, router.routerState.root);
      var newTitle = titleArr[titleArr.length - 1];
      me.stateService.changePageTitle(newTitle);
      // Set the show banner
      var showBannerArr = me.getShowBanner(router.routerState, router.routerState.root);
      var newShowBanner = showBannerArr[showBannerArr.length - 1];
      me.showBanner = newShowBanner;
      // Set the Admin Menu
      var showAdminMenuArr = me.getAdminMenuMode(router.routerState, router.routerState.root);
      var newAdminMenu = showAdminMenuArr[showAdminMenuArr.length - 1];
      me.adminMenuMode = newAdminMenu;
    });
  }

  ngOnInit() {
    let me = this;
  }
  public setTitle(newTitle: string) {
    // For the H1
    this.title = newTitle;
    // For the Browser Title
    this.titleService.setTitle(newTitle);
  }
  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  getShowBanner(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.showBanner) {
      data.push(parent.snapshot.data.showBanner);
    }

    if (state && parent) {
      data.push(... this.getShowBanner(state, state.firstChild(parent)));
    }
    return data;
  }
  getAdminMenuMode(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.adminMenuMode) {
      data.push(parent.snapshot.data.adminMenuMode);
    }

    if (state && parent) {
      data.push(... this.getAdminMenuMode(state, state.firstChild(parent)));
    }
    return data;
  }
  logout() {
    let me = this;
    // Get rid of token will create logout
    me.stateService.changeUserModel(null);
    // me.authService.Logout().subscribe(data => {
    //   me.stateService.changeUserModel(null);
    // });
  }
  checkLoggedIn() {
    let me = this;
    me.authService.IsLoggedOn().subscribe(data => {
      // Logged In
      me.loggedIn = true;
      me.GetCreditBalance();
    }, err => {
      // Not logged in
      me.loggedIn = false;
      me.GetCreditBalance();
    });
  }
  isInRole(roleName: string) {
    let me = this;
    if (!me.currentUser) {
      return false;
    }

    var role = me.currentUser.Roles.filter(o => o.Name == roleName);
    return role.length > 0;
  }
  GetCreditBalance() {
    let me = this;
    me.MyCreditBalance = 0;

    if (me.loggedIn) {
      me.creditCouponService.MyAvailableBalance().subscribe(data => {
        me.MyCreditBalance = data.DecimalValue;
      });
    }

  }
}

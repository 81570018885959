export class ParentRegisterRequest {
    Firstname: string;
    Surname: string;
    Email: string;
    PhoneNumber: string;
    Password: string;
    ConfirmPassword: string;

    constructor() {
        this.Firstname = "";
        this.Surname = "";
        this.Email = "";
        this.PhoneNumber = "";
        this.Password = "";
        this.ConfirmPassword = "";
    }
}
<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">

    <div class="col-sm-4 mt-2 mb-2" *ngFor="let learningModule of learningModules">
      <learning-module-card [model]="learningModule"></learning-module-card>
    </div>

  </div>
</div>

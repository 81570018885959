import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService, AuthService, InteractiveModuleSessionService } from '../../services';
import { UserModel, AssignSupervisorRequestModel } from '../../models';
import { FormFunctions, CustomDateParserFormatter } from '../../functions';
import { Validations } from '../../constants'

@Component({
    selector: 'modal-admin-assign-supervisor',
    templateUrl: './modal-admin-assign-supervisor.component.html'
})
export class ModalAdminAssignSupervisorComponent {
    @Input() sessionIds: number[];
    request: AssignSupervisorRequestModel = new AssignSupervisorRequestModel();
    supervisors: UserModel[] = [];
    processing: boolean = false;
    success: boolean = false;
    error: boolean = false;
    errorMessage: string = "";

    constructor(public activeModal: NgbActiveModal, public stateService: StateService, public interactiveModuleSessionService: InteractiveModuleSessionService, public authService: AuthService) {
        let me = this;
    }
    ngOnInit() {
        let me = this;

        me.request.SessionIds = me.sessionIds;

        me.authService.FindByRole('Supervisor').subscribe(data => {
            me.supervisors = data;
        });

    }
    AssignSupervisor() {
        let me = this;
        me.success = false;
        me.error = false;
        me.errorMessage = "";
        me.processing = true;

        me.interactiveModuleSessionService.AssignSupervisor(me.request).subscribe(data => {
            if (data.Success) {
                me.success = true;
            }
            me.processing = false;
        }, err => {
            me.error = true;
            me.errorMessage = JSON.stringify(err);
            me.processing = false;
        });
    }
}
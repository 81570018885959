<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-2">
      <websilk-date-field id="dtStartDate" name="dtStartDate" [(ngModel)]="searchRequest.StartDate" labelText="Start Date" required="true" displayInline="false"></websilk-date-field>
    </div>
    <div class="col-sm-2">
      <websilk-date-field id="dtEndDate" name="dtEndDate" [(ngModel)]="searchRequest.EndDate" labelText="End Date" required="true" displayInline="false"></websilk-date-field>
    </div>
    <div class="col-sm-3">
      <div class="form-group">
        <label class="control-label">Program Style</label>
        <select class="form-control" [(ngModel)]="searchRequest.InteractiveProgramStyleId">
          <option value="0">Select Style</option>
          <option *ngFor="let style of styles" [value]="style.Id">{{style.Title}}</option>
        </select>
      </div>
    </div>
    <div class="col-sm-5 text-right">
      <div class="form-group">
        <label class="form-label">&nbsp;</label>
        <div>
          <button class="btn btn-dark" (click)="Search()" *ngIf="!exporting">Search Enrolments</button>
          <button class="btn btn-gateways ml-1" (click)="ExportResults()" *ngIf="!exporting">Export</button>
          <span class="btn btn-gateways ml-1" *ngIf="exporting"><i class="fa fa-spinner fa-spin"></i> Exporting....</span>
        </div>
      </div>
    </div>
    <!-- <div class="col-sm-2">
      <div class="form-group">
        <label class="form-label">&nbsp;</label>
        <div>
          <button class="btn btn-gateways ml-1" (click)="ExportResults()">Export</button>
        </div>
      </div>
    </div> -->
  </div>

  <div class="row" *ngIf="!loading">
    <div class="col-12" *ngIf="searching">
      <websilk-loading displayText="Searching for enrolments... Please wait"></websilk-loading>
    </div>
    <div class="col-12" *ngIf="!searching">

      <table class="table table-striped">
        <thead>
          <tr>
            <th>Date/Time</th>
            <th>Style</th>
            <th>Program</th>
            <th>Program Dates</th>
            <th>Parent Email</th>
            <th>Parent Name</th>
            <th>Students</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!enrolments.length">
            <td colspan="7" class="text-center">No enrolments found</td>
          </tr>
          <tr *ngFor="let enrolment of enrolments">
            <td>{{enrolment.DateCreated|date:'dd/MM/yyyy HH:mm'}}</td>
            <td>{{enrolment.InteractiveModule.InteractiveModuleStyle?.Title}}</td>
            <td>{{enrolment.InteractiveModule.Title}}</td>
            <td>
              <span class="d-block" *ngFor="let session of enrolment.InteractiveModule.InteractiveModuleSessions">{{session.StartDateTime|date:'dd/MM/yyyy HH:mm'}}</span>
            </td>
            <td>{{enrolment.Parent.User.Email}}</td>
            <td>{{enrolment.Parent.User.FirstName}} {{enrolment.Parent.User.Surname}}</td>
            <td>
              <span class="d-block" *ngFor="let student of enrolment.InteractiveModuleEnrolmentStudents">{{student.Student.User.FirstName}} {{student.Student.User.Surname}} ({{student.Student.YearLevel?.Title}})</span>
            </td>
          </tr>

        </tbody>
      </table>

    </div>
  </div>
</div>

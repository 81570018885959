<div class="container">
  <websilk-loading
    *ngIf="loading"
    displayText="Loading modules... Please wait"
  ></websilk-loading>

  <div
    class="row mt-3"
    *ngIf="!loading && interactiveModule && !enrolmentComplete"
  >
    <div class="col-sm-6">
      <div class="card bg-purple mb-2">
        <div class="card-body">
          <div class="mb-3">
            <academic-focus-pill
              [academicFocus]="
                interactiveModule.InteractiveModuleTemplate.AcademicFocus
              "
            ></academic-focus-pill>
            <in-out-school-pill
              [InSchool]="
                interactiveModule.InteractiveModuleTemplate.InviteOnly
              "
            ></in-out-school-pill>
          </div>

          <div class="mb-3">
            <h5 class="text-white font-weight-bold">
              {{ interactiveModule.Title }}
            </h5>
            <span class="d-block">
              <span class="text-orange">- </span
              ><small
                class="text-white"
                *ngFor="
                  let yearLevel of interactiveModule.YearLevels;
                  let i = index
                "
                ><span *ngIf="i > 0">, </span>{{ yearLevel.Title }}</small
              >
            </span>
          </div>

          <div class="mt-2" *ngIf="interactiveModule.Location">
            <h5 class="text-white font-weight-bold">Location</h5>

            <div class="card bg-white font-weight-bold mt-2 mb-2">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div>
                      <i class="fa fa-building mr-2"></i
                      >{{ interactiveModule.Location.Title }}
                    </div>
                    <div class="">
                      <i class="fa fa-map-marker mr-2"></i
                      ><span
                        >{{ interactiveModule.Location.Address1 }}
                        {{ interactiveModule.Location.Address2 }},
                        {{ interactiveModule.Location.Suburb }}
                        {{ interactiveModule.Location.State }}
                        {{ interactiveModule.Location.Postcode }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card bg-card-grey">
        <div class="card-body">
          <h5 class="text-dark font-weight-bold">Session Dates and Times</h5>
          <div
            *ngFor="
              let session of interactiveModule.InteractiveModuleSessions;
              let i = index
            "
          >
            <div class="card bg-white font-weight-bold mt-2 mb-2">
              <div
                class="card-body"
                [class.session-complete]="IsSessionComplete(session)"
              >
                <div class="row">
                  <div class="col-sm-12">
                    <div class="d-inline-block pr-2">
                      <i class="fa fa-calendar"></i>
                      {{ session.StartDateTime | date : "dd/MM/yyyy" }}
                    </div>
                    <div class="d-inline-block pr-2">
                      <i class="fa fa-clock"></i>
                      {{ session.StartDateTime | date : "hh:mm aa" }} -
                      {{ session.FinishDateTime | date : "hh:mm aa" }}
                    </div>
                    <div class="d-inline-block pr-2">
                      Presenter: {{ session.Presenter.Firstname }}
                      {{ session.Presenter.Surname }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <!-- ALREADY ENROLLED -->
      <div class="alert alert-warning" *ngIf="alreadyEnrolled">
        <h4>Already Enrolled</h4>
        <p>
          You (or your children) are already enrolled in this program. Please
          visit
          <strong
            ><a
              [routerLink]="[
                '/interactive-modules/instance',
                interactiveModule.Id
              ]"
              >{{ interactiveModule.Title }}</a
            ></strong
          >
          to access the content.
        </p>
      </div>
      <!-- /ALREADY ENROLLED -->

      <!-- SELECT CHILDREN -->
      <div
        class="card bg-card-grey mb-2"
        *ngIf="!alreadyEnrolled && IsInRole('Parent')"
      >
        <div class="card-body">
          <h5 class="text-dark">Select children to enrol</h5>
          <table class="table bg-white">
            <tr>
              <th>Child</th>
              <!-- <th>Username</th> -->
              <th>Date of Birth</th>
              <!-- <th>Price</th> -->
              <th class="text-center">Participate?</th>
            </tr>
            <tr *ngIf="!children.length">
              <td class="text-center" colspan="3">
                <h5>No children that match required year levels found.</h5>
              </td>
            </tr>
            <tr *ngFor="let child of children">
              <td>{{ child.User.FirstName }} {{ child.User.Surname }}</td>
              <!-- <td>{{child.User.Username}}</td> -->
              <td>{{ child.DateOfBirth | date : "dd/MM/yyyy" }}</td>
              <!-- <td>AUD${{interactiveModule.Price|number:'1.2-2'}}</td> -->
              <td class="text-center">
                <input
                  class=""
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  [(ngModel)]="child.Selected"
                />
              </td>
            </tr>
          </table>
          <span
            class="text-danger d-block mt-2"
            *ngIf="FieldError('NoChildren')"
            >You must select at least 1 child</span
          >
          <button class="btn btn-dark text-light mt-2" (click)="addChild()">
            <i class="fa fa-plus"></i> Add new child
          </button>
        </div>
      </div>
      <!-- /SELECT CHILDREN -->

      <!-- CREDIT BALANCE -->
      <div class="card bg-card-grey mb-2" *ngIf="myCreditBalance > 0">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <span class="font-weight-bold">Current Credit Balance</span>
            </div>
            <div class="col-6 text-right">
              <h4 class="font-weight-bold">
                AUD${{ myCreditBalance | number : "1.2-2" }}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <!-- /CREDIT BALANCE -->

      <!-- PRICE -->
      <div class="card bg-purple">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <span class="text-white font-weight-bold">Total RRP</span>
            </div>
            <div
              class="col-6 text-right"
              [class.text-white]="myCreditBalance > 0"
              [class.text-orange]="myCreditBalance <= 0"
            >
              <h4 class="font-weight-bold">
                AUD${{ GetTotalPrice(false) | number : "1.2-2" }}
              </h4>
            </div>
          </div>

          <div class="row" *ngIf="myCreditBalance > 0">
            <div class="col-6">
              <span class="text-white font-weight-bold"
                >Total Payment Required</span
              >
            </div>
            <div class="col-6 text-right">
              <h4 class="text-orange font-weight-bold">
                AUD${{ GetTotalPrice(true) | number : "1.2-2" }}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <!-- /PRICE -->
    </div>

    <div class="col-sm-12 mt-3" *ngIf="!alreadyEnrolled">
      <div class="card mb-2">
        <div class="card-header bg-dark text-light">
          <h3 class="card-title">Payment</h3>
        </div>
        <div class="card-body" *ngIf="IsInRole('Parent')">
          <div
            *ngIf="GetSelectedChildrenIds().length && GetTotalPrice(true) <= 0"
          >
            <div class="alert alert-info text-center">
              <h5>
                No payment is required, your current account credit will be
                used.
              </h5>
            </div>
          </div>

          <div
            *ngIf="GetSelectedChildrenIds().length && GetTotalPrice(true) > 0"
          >
            <p>
              Please enter your payment details below. A total of
              <strong>AUD${{ GetTotalPrice(true) | number : "1.2-2" }}</strong>
              will be debited.
            </p>

            <stripe-checkout></stripe-checkout>
          </div>

          <div class="form-group mt-3" *ngIf="GetSelectedChildrenIds().length">
            <re-captcha
              (resolved)="resolved($event)"
              siteKey="6LecutkZAAAAAITVLZd5-H0wcZ5W6uILIQIoB7zQ"
            ></re-captcha>
          </div>
        </div>

        <div class="card-footer">
          <button
            type="button"
            class="btn btn-lg btn-gateways w-100"
            (click)="BuyNow()"
            *ngIf="!processing && reCaptchaPassed"
          >
            Buy Now
          </button>
          <span
            type="button"
            class="btn btn-lg btn-gateways w-100 disabled"
            *ngIf="!processing && !reCaptchaPassed"
            >Buy Now</span
          >
          <button
            type="button"
            class="btn btn-lg btn-dark w-100"
            *ngIf="processing"
          >
            <i class="fa fa-spinner fa-spin"></i>&nbsp;Processing...
          </button>
        </div>
      </div>

      <div class="alert alert-danger" *ngIf="gatewayError">
        <span>There was a problem processing your payment.</span>
        <div *ngIf="errorText">
          <strong>{{ errorText }}</strong>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!loading && interactiveModule && enrolmentComplete">
    <div class="col-sm-12">
      <div class="alert alert-success">
        Congratulations, you have successfully enrolled in
        <strong>{{ interactiveModule.Title }}</strong
        >.
      </div>

      <div class="card">
        <div class="card-header bg-dark text-light">
          <h3 class="card-title">Next Steps</h3>
        </div>
        <div class="card-body">
          <p>As a parent, you also have the following capabilities</p>
          <ul>
            <li><a [routerLink]="['/parent']">My dashboard</a></li>
            <li><a [routerLink]="['/parent/edit']">My details</a></li>
            <li><a [routerLink]="['/parent/children']">My children</a></li>
            <li><a [routerLink]="['/parent/enrolments']">My enrolments</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';

import { StateService, ZoomAccountService } from '../../../services';
import { FormFunctions } from '../../../functions';
import { ZoomAccountModel } from '../../../models';

@Component({
    selector: 'page-admin-zoom-accounts',
    templateUrl: './page-admin-zoom-accounts.component.html',
})
export class PageAdminZoomAccountsComponent implements OnInit {
    loading: boolean = true;
    processing: boolean = false;
    entities: ZoomAccountModel[] = [];
    entity: ZoomAccountModel = new ZoomAccountModel();

    constructor(public stateService: StateService, private formFunctions: FormFunctions, public zoomAccountService: ZoomAccountService) {
        var me = this;
        me.LoadEntities();
    }

    ngOnInit() {
        var me = this;
    }

    LoadEntities() {
        let me = this;
        me.loading = true;
        me.zoomAccountService.FindAll().subscribe(data => {
            me.entities = data;
            me.loading = false;
        });
    }

    Edit(item: ZoomAccountModel) {
        let me = this;
        var clone = <ZoomAccountModel>me.formFunctions.DeepCopy(item);
        me.entity = clone;
    }
    Save(form, e) {
        let me = this;
        if (me.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            if (me.entity.Id) {
                me.zoomAccountService.Edit(me.entity).subscribe(data => {
                    me.processing = false;
                    me.entity = new ZoomAccountModel();
                    me.LoadEntities();
                });
            } else {
                me.zoomAccountService.Create(me.entity).subscribe(data => {
                    me.processing = false;
                    me.entity = new ZoomAccountModel();
                    me.LoadEntities();
                });
            }
        }
    }
    Cancel() {
        let me = this;
        me.entity = new ZoomAccountModel();
        me.LoadEntities();
    }
}

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import { LearningModuleModel, LearningModuleEnrolmentModel } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class LearningModuleService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindAll(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<LearningModuleModel[]>(this.appConfig.getConfig('apiUrl') + '/learning-modules', { withCredentials: true, headers: headers });
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<LearningModuleModel>(this.appConfig.getConfig('apiUrl') + '/learning-modules/' + id, { withCredentials: true, headers: headers });
    }
    FindByUrl(urlName: string): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<LearningModuleModel>(this.appConfig.getConfig('apiUrl') + '/learning-modules/by-url/' + urlName, { withCredentials: true, headers: headers });
    }
    CheckEnrolled(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<LearningModuleEnrolmentModel>(this.appConfig.getConfig('apiUrl') + '/learning-modules/check-enrolled/' + id, { withCredentials: true, headers: headers });
    }
}
import { Component, OnInit } from '@angular/core';

import { StateService, LearningModuleService } from '../../../services';

import { LearningModuleModel, LearningModuleTypeModel } from '../../../models';
import { LearningModuleCard } from 'src/app/components';

@Component({
    selector: 'page-learning-module-list',
    templateUrl: './page-learning-module-list.component.html',
})
export class PageLearningModuleListComponent implements OnInit {
    loading: boolean = true;
    learningModules: LearningModuleModel[] = [];

    constructor(public stateService: StateService, public learningModuleService: LearningModuleService) {
        var me = this;

        me.learningModuleService.FindAll().subscribe(data => {
            me.learningModules = data;
            me.loading = false;
        });
    }

    ngOnInit() {
        var me = this;
    }

}

import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { forkJoin } from "rxjs";
import { DatePipe } from "@angular/common";

import {
  StateService,
  ParentService,
  StudentService,
  AuthService,
  InteractiveModuleService,
  InteractiveModuleEnrolmentService,
  CreditCouponService,
} from "../../../services";

import {
  StudentModel,
  InteractiveModuleModel,
  EnrolStudentRequest,
  InteractiveModuleEnrolmentModel,
  UserModel,
} from "../../../models";

import {
  ModalAddChildComponent,
  StripeCheckoutComponent,
} from "../../../components";
import { RecaptchaComponent } from "ng-recaptcha";

@Component({
  selector: "page-interactive-module-enrol-finalise",
  styleUrls: ["./page-interactive-module-enrol-finalise.component.scss"],
  templateUrl: "./page-interactive-module-enrol-finalise.component.html",
})
export class PageInteractiveModuleEnrolFinaliseComponent implements OnInit {
  loading: boolean = true;
  interactiveModule: InteractiveModuleModel;
  expiryYears: number[] = [];
  children: StudentModel[] = [];
  enrolmentRequest: EnrolStudentRequest = new EnrolStudentRequest();
  errors: String[] = [];
  processing: boolean = false;
  gatewayError: boolean = false;
  enrolmentComplete: boolean = false;
  successfulEnrolmentRecord: InteractiveModuleEnrolmentModel;
  alreadyEnrolled: boolean = false;
  isSchool: boolean = false;
  isParent: boolean = false;
  allowedOnAccount: boolean = false;
  currentUser: UserModel;
  reCaptchaPassed: boolean = false;
  errorText: string = "";
  myCreditBalance: number = 0;

  @ViewChild(StripeCheckoutComponent, { static: false })
  stripe!: StripeCheckoutComponent;

  @ViewChild(RecaptchaComponent, { static: false })
  recaptcha!: RecaptchaComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    public stateService: StateService,
    public authService: AuthService,
    public interactiveModuleService: InteractiveModuleService,
    public parentService: ParentService,
    public studentService: StudentService,
    private modalService: NgbModal,
    public interactiveModuleEnrolmentService: InteractiveModuleEnrolmentService,
    public creditCouponService: CreditCouponService
  ) {
    var me = this;

    let id = me.activatedRoute.snapshot.params.id;

    let currentYear = new Date().getFullYear();
    for (var i = currentYear; i < currentYear + 15; i++) {
      this.expiryYears.push(i);
    }

    me.currentUser = me.stateService.userModel;
    me.stateService.userModel$.subscribe((data) => {
      // Logged on state has changed
      me.currentUser = data;
      me.CheckLoggedOn();
      me.CheckUserRoles();
    });

    me.CheckUserRoles();

    forkJoin([
      me.interactiveModuleService.FindByIdPublic(id),
      me.creditCouponService.MyAvailableBalance(),
    ]).subscribe(([data1, data2]) => {
      me.interactiveModule = data1;
      me.myCreditBalance = data2.DecimalValue;
      me.CheckLoggedOn();
      me.GetParentAndChildren();
      me.CheckEnrolled();
    });

    // me.interactiveModuleService.FindById(id).subscribe(data => {
    //     me.interactiveModule = data;
    //     me.CheckLoggedOn();
    //     me.GetParentAndChildren();
    //     me.CheckEnrolled();
    // });
  }

  ngOnInit() {}

  CheckLoggedOn() {
    let me = this;
    me.authService.IsLoggedOn().subscribe(
      (data) => {
        // Logged in, all OK
      },
      (err) => {
        // Not logged on, redirect
        me.router.navigate([
          "/interactive-modules/enrol",
          me.interactiveModule.Id,
        ]);
      }
    );
  }

  CheckUserRoles() {
    let me = this;
    if (!me.currentUser) {
      me.isSchool = false;
    }

    if (me.IsInRole("Parent")) {
      me.isParent = true;
    }

    if (me.IsInRole("School")) {
      me.isSchool = true;
    }
  }

  IsInRole(roleName: string) {
    let me = this;
    if (!me.currentUser) {
      return false;
    }
    if (me.currentUser.Roles.filter((o) => o.Name == roleName).length) {
      return true;
    }
    return false;
  }

  CheckEnrolled() {
    let me = this;
    me.interactiveModuleService
      .CheckEnrolled(me.interactiveModule.Id)
      .subscribe((data) => {
        // A returned record means I am enrolled
        // me.alreadyEnrolled = data != null;
      });
  }

  FieldError(errorType) {
    var hasError = this.errors.indexOf(errorType) > -1;
    return hasError;
  }

  GetParentAndChildren() {
    var me = this;
    // Get the current parent and children (if logged on)

    forkJoin([
      me.parentService.FindMyStudents(),
      me.interactiveModuleService.MyChildrenEnrolled(me.interactiveModule.Id),
    ]).subscribe(
      ([data1, data2]) => {
        me.children = data1.filter(
          (o) =>
            me.interactiveModule.YearLevels.filter((y) => y.Id == o.YearLevelId)
              .length && !data2.filter((j) => j.Id == o.Id).length
        );
        me.loading = false;
      },
      (err) => {
        me.loading = false;
      }
    );

    // me.parentService.FindMyStudents().subscribe(data => {
    //     // Only children who are in the permitted year level
    //     me.children = data.filter(o => me.interactiveModule.YearLevels.filter(y => y.Id == o.YearLevelId).length);
    //     me.loading = false;
    // }, err => {
    //     me.loading = false;
    // });
  }

  GetSelectedChildrenIds() {
    let me = this;
    let selectedChildren: number[] = [];
    for (var i = 0; i < me.children.length; i++) {
      if (me.children[i].Selected) {
        selectedChildren.push(me.children[i].Id);
      }
    }
    return selectedChildren;
  }

  GetTotalPrice(applyDiscount) {
    let me = this;
    let selectedChildrenCount = me.GetSelectedChildrenIds().length;
    if (!applyDiscount) {
      return me.interactiveModule.Price * selectedChildrenCount;
    } else {
      var price =
        selectedChildrenCount > 0
          ? me.interactiveModule.Price * selectedChildrenCount -
            me.myCreditBalance
          : 0;
      return price < 0 ? 0 : price;
    }
  }

  reset(): void {
    this.stripe.toggleInputs(false);
    this.processing = false;
    this.recaptcha.reset();
  }

  async BuyNow() {
    this.gatewayError = false;
    this.errorText = "";

    if (await this.ValidRequest()) {
      this.processing = true;
      !!this.stripe && this.stripe.toggleInputs(true);

      // Selected Children
      let selectedChildren = this.GetSelectedChildrenIds();
      this.enrolmentRequest.StudentIds = selectedChildren;
      this.enrolmentRequest.ModuleId = this.interactiveModule.Id;
      this.enrolmentRequest.Amount = this.GetTotalPrice(false);
      this.enrolmentRequest.UseCredit = this.myCreditBalance > 0;

      this.interactiveModuleEnrolmentService
        .PrepareEnrolStudent(this.enrolmentRequest)
        .subscribe(
          (data) => {
            if (data != null) {
              if (!data.FullCredit) {
                this.stripe.handleSubmit(data.Intent).then((value) => {
                  if (value.success) {
                    this.interactiveModuleEnrolmentService
                      .FinalEnrolStudent({
                        Success: value.success,
                        TxnId: value.id,
                        EnrolmentId: data.EnrolmentId,
                      })
                      .subscribe(
                        (final) => {
                          this.enrolmentComplete = true;
                          this.successfulEnrolmentRecord = final;
                          this.processing = false;
                        },
                        (err) => {
                          // Error
                          this.gatewayError = true;
                          this.errorText = err.statusText;
                          this.reset();
                        }
                      );
                  } else {
                    // Error
                    this.gatewayError = true;
                    this.errorText = value.error;
                    this.reset();
                  }
                });
              } else {
                // Full credit behaviour
                this.enrolmentComplete = true;
                this.successfulEnrolmentRecord = data.Result;
                this.processing = false;
              }
            }
          },
          (err) => {
            // Error
            this.gatewayError = true;
            this.errorText = err.error || err.statusText;
            this.reset();
          }
        );
    }
  }

  addChild() {
    let me = this;
    const modalRef = me.modalService.open(ModalAddChildComponent, {
      scrollable: true,
    });
    modalRef.componentInstance._includeExtra = "true";
    modalRef.result.then((result) => {
      me.addChildCallback(result);
    });
  }
  addChildCallback(child) {
    let me = this;
    me.GetParentAndChildren();
  }
  async ValidRequest() {
    this.errors = [];

    if (this.GetSelectedChildrenIds().length == 0) {
      this.errors.push("NoChildren");
    }
    if (this.GetTotalPrice(true) > 0) {
      const cardValidate = await this.stripe.validate();
      if (!cardValidate) {
        this.errors.push("Please ensure all card details are valid");
      }
    }
    return !this.errors.length;
  }

  ValidCreditCardNumber(cardNumber) {
    let regex =
      "^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35d{3})d{11})$";
    var matches = cardNumber.match(regex);
    return matches && matches.length > 0;
  }

  ValidEmail(email) {
    let regex =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    var matches = email.match(regex);
    return matches && matches.length > 0;
  }

  resolved(captchaResponse: string) {
    let me = this;
    me.enrolmentRequest.RecaptchaResponse = captchaResponse;
    me.reCaptchaPassed = true;
  }

  IsSessionComplete(session) {
    let now = new Date();
    let startDateTime = session.StartDateTime;

    let dYear = parseInt(this.datePipe.transform(startDateTime, "yyyy"));
    let dMonth = parseInt(this.datePipe.transform(startDateTime, "M")) - 1;
    let dDay = parseInt(this.datePipe.transform(startDateTime, "d"));
    let dHour = parseInt(this.datePipe.transform(startDateTime, "H"));
    let dMinute = parseInt(this.datePipe.transform(startDateTime, "m"));

    let sessionStart = new Date(dYear, dMonth, dDay, dHour, dMinute, 0);
    let sDiff = (sessionStart.getTime() - now.getTime()) / 1000;

    // Session has already started, cannot enrol into it
    return sDiff <= 0;
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService, SchoolService, GenericDataService } from '../../services';
import { SchoolModel, GenericLookupModel } from '../../models';
import { FormFunctions, CustomDateParserFormatter } from '../../functions';
import { Validations } from '../../constants'

@Component({
    selector: 'modal-edit-school',
    templateUrl: './modal-edit-school.component.html'
})
export class ModalEditSchoolComponent {
    @Input() entity: SchoolModel;
    states: GenericLookupModel[] = [];
    loading: boolean = true;
    showSuccess: boolean = false;
    showError: boolean = false;
    processing: boolean = false;

    constructor(public activeModal: NgbActiveModal, public stateService: StateService, public validations: Validations, public formFunctions: FormFunctions, public schoolService: SchoolService, public genericDataService: GenericDataService) {
        let me = this;

        me.states = me.genericDataService.GetStates();
    }
    ngOnInit() {

    }
    UpdateDetails(form, e) {
        let me = this;

        if (this.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            if (me.entity.Id) {
                me.schoolService.Update(me.entity).subscribe(data => {
                    me.showSuccess = true;
                    me.processing = false;
                    me.entity = data;
                }, err => {
                    me.processing = false;
                    me.showError = true;
                });
            } else {
                me.schoolService.Create(me.entity).subscribe(data => {
                    me.showSuccess = true;
                    me.processing = false;
                    me.entity = data;
                }, err => {
                    me.processing = false;
                    me.showError = true;
                });
            }
        }
    }
}
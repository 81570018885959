<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12 text-right pb-3">
      <a class="btn btn-dark" [routerLink]="['/admin/interactive-module-templates/new']"><i class="fa fa-plus"></i> Create New</a>
    </div>
    <div class="col-sm-12">
      <table class="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th>Style</th>
            <th>Delivery</th>
            <th>Title</th>
            <th>Price</th>
            <th>Invite Only?</th>
            <th style="width:170px;">Action</th>
          </tr>
        </thead>
        <tbody *ngIf="templates.length==0">
          <tr>
            <td colspan="5" class="text-center">
              <h5>No templates to display</h5>
              <a class="btn btn-dark" [routerLink]="['/admin/interactive-module-templates/new']"><i class="fa fa-plus"></i> Create</a>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="templates.length>0">
          <tr *ngFor="let template of templates">
            <td>
              <img [src]="GetImageUrl(template)" height="50">
            </td>
            <td>{{template.InteractiveModuleStyle?.Title}}</td>
            <td>
              <span *ngIf="template.IsOnsite">OnSite</span>
              <span *ngIf="!template.IsOnsite">OnLine</span>
            </td>
            <td>{{template.Title}}</td>
            <td>${{template.Price|number:'1.2-2'}}</td>
            <td>{{template.InviteOnly?"Yes":"No"}}</td>
            <td>
              <a class="btn btn-sm" [routerLink]="['/admin/interactive-module-templates/edit/',template.Id]"><i class="fa fa-edit"></i> Edit</a>
              <br>
              <a class="btn btn-sm" [routerLink]="['/admin/interactive-module-templates/instances/create',template.Id]"><i class="fa fa-server"></i> Create Instances</a>
              <!-- <button class="btn btn-sm" (click)="CreateInstance(template)"><i class="fa fa-server"></i> Create Instances</button> -->
              <br>
              <a class="btn btn-sm" [routerLink]="['/admin/interactive-module-templates/instances/',template.Id]"><i class="fa fa-edit"></i> View Instances</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

import { InteractiveModuleEnrolmentModel, StudentModel } from './';

export class InteractiveModuleEnrolmentStudentModel {
    Id: number;
    InteractiveModuleEnrolmentId: number;
    StudentId: number;
    InteractiveModuleEnrolment: InteractiveModuleEnrolmentModel;
    Student: StudentModel;
    DateCreated: Date;

    constructor() {

    }
}
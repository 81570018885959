<websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>
<websilk-loading *ngIf="!loading && loadingUnits" displayText="Loading units... Please wait"></websilk-loading>


<div class="row text-light py-5" *ngIf="!loading && !loadingUnits" style="margin-top:-8px; background-color: black;">
  <div class="container">
    <!-- Author Details -->
    <div class="row mb-3" *ngIf="!loading">
      <!-- <div class="col-sm-3">
        <i class="fa fa-user fa-2x mr-1"></i>
        <h5 class="d-inline">{{learningModule.Author}}</h5>
      </div> -->
      <div class="col-sm-8">
        <i *ngIf="learningModule.YearLevels.length" class="fas fa-school fa-2x mr-3"></i>
        <span *ngFor="let yearLevel of learningModule.YearLevels">
          <h5 class="d-inline">{{yearLevel.Title}}</h5>
        </span>
      </div>
      <div class="col-sm-4">
        <h5>{{moduleUnits.length}} videos in module</h5>
      </div>
    </div>
    <!-- /Author Details -->

    <!-- VIDEO -->
    <div class="row">
      <!-- Video Panel -->
      <div class="col-sm-8 h-100">

        <vg-player class="mb-5" style="height:auto;">
          <vg-controls [vgAutohide]="true" [vgAutohideTime]="1.5">
            <vg-play-pause></vg-play-pause>
            <vg-playback-button></vg-playback-button>

            <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

            <vg-scrub-bar>
              <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
              <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
            </vg-scrub-bar>

            <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

            <vg-mute></vg-mute>
            <vg-volume></vg-volume>

            <vg-fullscreen></vg-fullscreen>
          </vg-controls>
          <video #media [vgMedia]="media" [src]="getMediaSource()" id="singleVideo" preload="auto" crossorigin>
          </video>
        </vg-player>

        <h5 class="text-gateways">{{currentItem.Title}}</h5>
        <p>
          {{currentItem.Description}}
        </p>
        <h5 class="text-gateways mt-5">Extra Resources</h5>
        <table>
          <tr *ngFor="let resource of currentItem.LearningModuleUnitResources">
            <td><a [attr.href]="getResourceSource(resource)" target="_blank" class="link" download>{{resource.Title}}</a></td>
          </tr>
        </table>
      </div>
      <!-- /Video Panel -->
      <!-- Playlist Panel -->
      <div class="col-sm-4 h-100">
        <ul>
          <li *ngFor="let item of moduleUnits" (click)="onClickPlaylistItem(item)" [class.bg-dark]="item === currentItem" class="btn w-100 text-left text-truncate text-nowrap text-light">
            <i class="far fa-play-circle" *ngIf="canAccessMedia(item)"></i>
            <i class="fa fa-lock" *ngIf="!canAccessMedia(item)"></i>
            &nbsp;{{item.Idx}}. {{ item.Title }}
          </li>
        </ul>

        <hr class="bg-white" *ngIf="!canAccessMedia">

        <div class="text-center mb-1" *ngIf="!canAccessModule">
          <strong>Purchase this for just ${{learningModule.Price|number:'1.2-2'}}</strong>
        </div>
        <div *ngIf="!canAccessModule">
          <a [routerLink]="['/learning-modules/get-started',learningModule.UrlName]" class="btn btn-gateways w-100">Get started</a>
        </div>
      </div>
      <!-- /Playlist Panel -->
    </div>
    <!-- /VIDEO -->


  </div>
</div>

<div class="row bg-light" *ngIf="!loading && !loadingUnits">
  <div class="container pt-4 pb-4">
    <!-- <div class="container"> -->
    <div class="row">

      <div class="col-sm-8">
        <div class="card bg-white p-3 rounded">
          <h3>Topics covered</h3>
          <p>This module covers a range of topics as listed below.</p>
          <div *ngFor="let unit of moduleUnits">
            <h5>{{unit.Idx}}. {{unit.Title}}</h5>
            <p class="text-dark">{{unit.Description}}</p>
          </div>
        </div>
      </div>

      <div class="col-sm-4 mb-3">
        <div class="card bg-white p-3 rounded">
          <h3>{{learningModule.Author}}</h3>
          <p>{{learningModule.Presenter.Summary}}</p>
        </div>
        <div class="bg-white mt-3" *ngIf="!canAccessModule">
          <div class="card bg-white p-3 rounded">
            <div class="text-center mb-1">
              <strong>Purchase this for just ${{learningModule.Price|number:'1.2-2'}}</strong>
            </div>
            <div>
              <a [routerLink]="['/learning-modules/get-started',learningModule.UrlName]" class="btn btn-gateways w-100">Get started</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </div>

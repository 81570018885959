import { Component, Input, OnInit } from '@angular/core';

import { AcademicFocusModel } from '../../models';

@Component({
    selector: 'academic-focus-pill',
    styleUrls: ['./academic-focus-pill.component.scss'],
    template:
        `
    <span class="badge academic-focus-pill" [class.light-mode]="LightMode"><img class="d-inline-block" [src]="GetImageUrl(academicFocus)" style="height:20px;"><span class="text"> {{academicFocus.Title}}</span></span>
  `
})
export class AcademicFocusPill {
    @Input() academicFocus: AcademicFocusModel;
    @Input() LightMode: boolean;

    constructor() { }

    GetImageUrl(item: AcademicFocusModel) {
        var me = this;
        let url = '';
        if (item) {
            url = 'https://gateways-online-content.s3-ap-southeast-2.amazonaws.com/' + item.StorageFolderName + '/' + item.TileImageName;
        }
        return url;
    }
}




import { Component, Input, OnInit } from '@angular/core';

import { AcademicFocusModel, InteractiveModuleStyleModel } from '../../models';

@Component({
    selector: 'program-style-pill',
    styleUrls: ['./program-style-pill.component.scss'],
    template:
        `
    <span class="badge program-style-pill ml-2" [class.bg-white]="!OrangeMode" [class.text-orange]="!OrangeMode" [class.text-white]="OrangeMode" [class.bg-orange]="OrangeMode">{{ProgramStyle?.Title}}</span>
  `
})
export class ProgramStylePill {
    @Input() OrangeMode: boolean;
    @Input() ProgramStyle: InteractiveModuleStyleModel;

    constructor() { }
}




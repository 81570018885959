<div class="modal-header bg-dark text-white">
  <h4 class="modal-title">My Details</h4>
  <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #thisForm="ngForm">
    <websilk-text-field id="txtUsername" name="txtUsername" [(ngModel)]="entity.Username" displayInline="false" labelText="Username" required="true" pattern=""></websilk-text-field>
    <websilk-text-field id="txtEmail" name="txtEmail" [(ngModel)]="entity.Email" displayInline="false" labelText="Email" required="true" pattern=""></websilk-text-field>
    <websilk-text-field id="txtPassword" name="txtPassword" [(ngModel)]="entity.Password" labelText="Password" required="true" pattern="" displayInline="false" fieldType="password"></websilk-text-field>
    <websilk-text-field id="txtConfirmPassword" name="txtConfirmPassword" [(ngModel)]="entity.ConfirmPassword" labelText="Confirm Password" required="true" [pattern]="'^'+this.entity.Password+'$'" displayInline="false" fieldType="password" errorText="Must match password"></websilk-text-field>
    <websilk-text-field id="txtFirstname" name="txtFirstname" [(ngModel)]="entity.Firstname" displayInline="false" labelText="First name" required="true" pattern=""></websilk-text-field>
    <websilk-text-field id="txtSurname" name="txtSurname" [(ngModel)]="entity.Surname" displayInline="false" labelText="Surname" required="true" pattern=""></websilk-text-field>
    <websilk-select-field id="selRoles" name="selRoles" [bindItems]="roles" [(ngModel)]="entity.RoleId" labelText="Role" idKey="Id" descKey="Name" required="true" displayInline="false"></websilk-select-field>
    <websilk-select-autocomplete-field id="selSchools" name="selSchools" [bindItems]="schools" [(ngModel)]="entity.SchoolId" labelText="School" idKey="Id" descKey="Name" required="false" displayInline="false"></websilk-select-autocomplete-field>


    <div class="alert alert-danger text-center" *ngIf="showError">
      <i class="fa fa-exclamation-triangle fa-2x"></i>
      <h5 class="">There was a problem adding this user.</h5>
    </div>
    <div class="alert alert-success text-center" *ngIf="showSuccess">
      <i class="fa fa-tick fa-2x"></i>
      <h5 class="">Details updated successfully.</h5>
    </div>
  </form>

</div>
<div class="modal-footer">
  <button class="btn btn-gateways" (click)="UpdateDetails(thisForm,$event)" *ngIf="!processing"><i class="fa fa-save"></i> Save</button>
  <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')" *ngIf="!processing">Close</button>
</div>

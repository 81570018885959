import { Component, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StateService, LocationService } from '../../../../services';
import { FormFunctions } from '../../../../functions';
import { LocationModel } from '../../../../models';

@Component({
    selector: 'page-admin-locations',
    templateUrl: './page-admin-locations.component.html',
})
export class PageAdminLocationsComponent implements OnInit {
    loading: boolean = true;
    processing: boolean = false;
    entities: LocationModel[] = [];
    entity: LocationModel = new LocationModel();

    constructor(private modalService: NgbModal, public stateService: StateService, private formFunctions: FormFunctions, public locationService: LocationService) {
        var me = this;
        me.LoadEntities();
    }

    ngOnInit() {
        var me = this;
    }

    LoadEntities() {
        let me = this;
        me.loading = true;
        me.locationService.FindAll().subscribe(data => {
            me.entities = data;
            me.loading = false;
        });
    }

    Edit(item: LocationModel) {
        let me = this;
        var clone = <LocationModel>me.formFunctions.DeepCopy(item);
        me.entity = clone;
    }

    Save(form, e) {
        let me = this;
        if (me.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            if (me.entity.Id) {
                me.locationService.Edit(me.entity).subscribe(data => {
                    me.processing = false;
                    me.entity = new LocationModel();
                    me.LoadEntities();
                });
            } else {
                me.locationService.Create(me.entity).subscribe(data => {
                    me.processing = false;
                    me.entity = new LocationModel();
                    me.LoadEntities();
                });
            }
        }
    }

    Cancel() {
        let me = this;
        me.entity = new LocationModel();
        me.LoadEntities();
    }
}

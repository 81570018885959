<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <!-- Logged on, select child -->
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header bg-dark text-light">
          <h5 class="card-title">
            Already a member?
          </h5>
        </div>
        <div class="card-body bg-light text-dark">
          <websilk-login id="wsLogin" (onSuccess)="loginCallback($event)"></websilk-login>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header bg-dark text-light">
          <h5 class="card-title">
            Not a member?
          </h5>
        </div>
        <div class="card-body bg-light text-dark">
          <websilk-register id="wsRegister" includeStudent="true" inlineFormDisplay="true" (onSuccess)="registerCallback($event)"></websilk-register>
        </div>
      </div>
    </div>
    <!-- Logged on, add child -->
    <!-- Not logged on, have an account, log in -->
    <!-- Not logged on, don't have an account, register -->
  </div>
</div>

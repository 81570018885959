import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { StateService, AuthService, RoleService } from '../../services';
import { WebsilkTextField } from '../../components';
import { ResetPasswordRequestModel } from '../../models';
import { FormFunctions } from '../../functions';

@Component({
    selector: 'page-forgot-password',
    templateUrl: './page-forgot-password.component.html',
})
export class PageForgotPasswordComponent implements OnInit {
    resetMode: boolean = false;
    processing: boolean = false;
    token: string = "";
    username: string;
    requestSuccess: boolean = false;
    requestError: boolean = false;
    passwordReset: ResetPasswordRequestModel = new ResetPasswordRequestModel();

    constructor(public router: Router, private activatedRoute: ActivatedRoute, public stateService: StateService, public authService: AuthService, public formFunctions: FormFunctions, public roleService: RoleService) {
        var me = this;
        // If there is a token, look up the user and do the reset
        me.token = me.activatedRoute.snapshot.params.token;
        if (me.token && me.token != "") {
            me.resetMode = true;
            me.passwordReset.ResetToken = me.token;
        }
    }

    ngOnInit() {
        var me = this;
    }

    RequestPasswordReset(form, e) {
        let me = this;
        me.requestSuccess = false;
        me.requestError = false;
        // Always say success to avoid fishing for valid user
        if (me.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            me.authService.ResetPasswordRequest(me.username).subscribe(data => {
                me.requestSuccess = true;
                me.processing = false;
            }, err => {
                me.requestSuccess = true;
                me.requestError = true;
                me.processing = false;
            });
        }
    }
    PasswordReset(form, e) {
        let me = this;
        me.requestSuccess = false;
        me.requestError = false;
        if (me.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            me.authService.ResetPassword(me.passwordReset).subscribe(data => {
                me.requestSuccess = true;
                me.processing = false;
            }, err => {
                me.requestSuccess = false;
                me.requestError = true;
                me.processing = false;
            });
        }
    }

}

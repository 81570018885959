<div class="modal-header bg-dark text-white">
  <h4 class="modal-title">Update child</h4>
  <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #thisForm="ngForm">

    <websilk-text-field id="txtFirstname" name="txtFirstname" [(ngModel)]="student.User.FirstName" displayInline="false" labelText="First name" required="true" pattern=""></websilk-text-field>
    <websilk-text-field id="txtSurname" name="txtSurname" [(ngModel)]="student.User.Surname" displayInline="false" labelText="Surname" required="true" pattern=""></websilk-text-field>

    <div class="form-group">
      <div class="input-group">
        <input class="form-control" placeholder="dd/mm/yyyy" name="dp" [(ngModel)]="student.DateOfBirth" ngbDatepicker #d="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
            <i class="fa fa-calendar"></i>
          </button>
        </div>
      </div>
    </div>

    <websilk-select-field id="selYearLevel" name="selYearLevel" [bindItems]="yearLevels" [(ngModel)]="student.YearLevelId" labelText="Year Level" idKey="Id" descKey="Title" required="true" displayInline="false"></websilk-select-field>
    <div class="form-group">
      <span>Selected School: {{GetSelectedSchool()?.Name}}</span>
    </div>
    <websilk-select-autocomplete-field id="selSchools" name="selSchools" [bindItems]="schoolList" [(ngModel)]="student.SchoolId" labelText="Change School" idKey="Id" descKey="Name" required="true" displayInline="false"></websilk-select-autocomplete-field>
    <websilk-select-field id="selGender" name="selGender" [bindItems]="genders" [(ngModel)]="student.Gender" labelText="Gender" idKey="Id" descKey="Description" required="true" displayInline="false"></websilk-select-field>

    <div class="alert alert-danger text-center" *ngIf="showError">
      <i class="fa fa-exclamation-triangle fa-2x"></i>
      <h5 class="">There was a problem adding this child.</h5>
    </div>
    <div class="alert alert-success text-center" *ngIf="showSuccess">
      <i class="fa fa-tick fa-2x"></i>
      <h5 class="">Details updated successfully.</h5>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-gateways" (click)="UpdateChild(thisForm,$event)" *ngIf="!processing">Update</button>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')" *ngIf="!processing">Close</button>
  <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
</div>

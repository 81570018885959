import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService, StudentService, YearLevelService, SchoolService } from '../../services';
import { StudentCreateRequest, YearLevelModel, GenericLookupModel, SchoolModel } from '../../models';
import { FormFunctions, CustomDateParserFormatter } from '../../functions';
import { Validations } from '../../constants'

@Component({
    selector: 'modal-admin-add-child',
    templateUrl: 'modal-admin-add-child.component.html'
})
export class ModalAdminAddChildComponent {
    @Input() instanceId: number;

    includeExtra: boolean = true;
    displayInline: string = 'true';
    createStudent: StudentCreateRequest = new StudentCreateRequest();
    processing: boolean = false;
    error: boolean = false;
    yearLevels: YearLevelModel[] = [];
    genders: GenericLookupModel[] = [];
    schools: SchoolModel[] = [];

    constructor(public activeModal: NgbActiveModal, public stateService: StateService, public validations: Validations, public formFunctions: FormFunctions, public studentService: StudentService, public yearLevelService: YearLevelService, public schoolService: SchoolService) {
        let me = this;
        // Genders
        me.genders.push(new GenericLookupModel("M", "Male"));
        me.genders.push(new GenericLookupModel("F", "Female"));
        me.genders.push(new GenericLookupModel("U", "Unspecified"));
        // Year Levels
        me.yearLevelService.FindAll().subscribe(data => {
            me.yearLevels = data;
        });
    }
    ngOnInit() {
        let me = this;
        // Schools
        if (me.includeExtra) {
            me.schoolService.FindAll().subscribe(data => {
                me.schools = data;
            });
        }
    }
    Register(form, e) {
        var me = this;
        if (me.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            me.studentService.CreateAdmin(this.createStudent).subscribe(data => {
                me.activeModal.close({ success: true, record: data });
                me.error = false;
                me.processing = false;
            }, err => {
                me.error = true;
                me.processing = false;
            });
        }
    }
}
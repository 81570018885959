import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

import { StateService, ParentService } from '../../services';

import { ParentRegisterRequest } from '../../models';
import { WebsilkTextField } from '../../components';
import { Validations } from '../../constants'
import { FormFunctions } from '../../functions';

@Component({
    selector: 'page-register',
    templateUrl: './page-register.component.html',
})
export class PageRegisterComponent implements OnInit {

    constructor(private router: Router, public stateService: StateService, public validations: Validations, public formFunctions: FormFunctions, public parentService: ParentService) {
        var me = this;
    }

    ngOnInit() {
        var me = this;
    }
}

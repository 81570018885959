<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Title</th>
            <th>Sessions</th>
            <th class="text-center">Allowed</th>
            <th class="text-center">Enrolled</th>
            <th class="text-center">Available</th>
            <th class="text-right">Action</th>
          </tr>
        </thead>
        <tbody *ngIf="instances.length==0">
          <tr>
            <td colspan="6" class="text-center">
              <h5>No instances created</h5>
              <button class="btn btn-dark"><i class="fa fa-plus"></i> Create</button>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="instances.length>0">
          <tr *ngFor="let instance of instances">
            <td>{{instance.Title}}</td>
            <td>
              <span class="d-block" *ngFor="let session of instance.InteractiveModuleSessions">
                {{session.StartDateTime|date:'dd/MM/yyyy hh:mm aa'}} - {{session.FinishDateTime|date:'hh:mm aa'}}
              </span>
            </td>
            <td class="text-center">{{instance.MaxEnrolmentsTotal}}</td>
            <td class="text-center">
              {{GetEnrolmentCount(instance)}}
            </td>
            <td class="text-center">
              {{(instance.MaxEnrolmentsTotal-GetEnrolmentCount(instance))}}
            </td>
            <td class="text-right">
              <button class="btn btn-sm btn-danger" (click)="DeleteInstance(instance.Id)"><i class="fa fa-trash"></i> Delete</button>
              <a class="btn btn-sm btn-dark ml-1" [routerLink]="['/admin/interactive-module-templates/instances/enrolments',instance.Id]">Manage Enrolments</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { StateService, SchoolService } from '../../../services';

import { ModalResetPasswordComponent } from '../../../components';

import { SchoolMyEnrolmentsProjectionModel, StudentModel } from '../../../models';

@Component({
    selector: 'page-school-enrolments',
    templateUrl: './page-school-enrolments.component.html',
})
export class PageSchoolEnrolmentsComponent implements OnInit {
    loading: boolean = true;
    dates: SchoolMyEnrolmentsProjectionModel[] = [];

    constructor(private router: Router, public stateService: StateService, public schoolService: SchoolService, public modalService: NgbModal) {
        var me = this;

        me.schoolService.FindMyEnrolments().subscribe(data => {
            me.dates = data;
            me.loading = false;
        });
    }

    ngOnInit() {
        var me = this;
    }
    ResetPassword(student: StudentModel) {
        let me = this;
        const modalRef = me.modalService.open(ModalResetPasswordComponent);
        modalRef.componentInstance.user = student.User;
    }
    ViewRoll(date) {
        let me = this;

        let useDate = new Date(date);
        let useMonthStr = (useDate.getMonth() + 1) < 10 ? '0' + (useDate.getMonth() + 1) : (useDate.getMonth() + 1);
        let useDayStr = useDate.getDate() < 10 ? '0' + useDate.getDate() : useDate.getDate();

        let dateStr = useDate.getFullYear() + '-' + useMonthStr + '-' + useDayStr;
        me.router.navigate(['/school/enrolments/roll', dateStr]);
    }

    ExportRoll(date) {
        let me = this;

        let useDate = new Date(date);
        let useMonthStr = (useDate.getMonth() + 1) < 10 ? '0' + (useDate.getMonth() + 1) : (useDate.getMonth() + 1);
        let useDayStr = useDate.getDate() < 10 ? '0' + useDate.getDate() : useDate.getDate();
        let dateStr = useDate.getFullYear() + '-' + useMonthStr + '-' + useDayStr;

        me.schoolService.ExportStudentRoll(dateStr).subscribe(data => {
            var url = URL.createObjectURL(data);
            var link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "enrolments.xlsx");
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }, err => {
            alert('There was a problem exporting your roll');
        });
    }
}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { StateService, AcademicFocusService } from '../../services';
import { AcademicFocusModel, InteractiveInstanceFilterRequestModel } from '../../models';

@Component({
    selector: 'page-home',
    templateUrl: './page-home.component.html',
    styleUrls: ['./page-home.component.scss']
})
export class PageHomeComponent implements OnInit {
    loading: boolean = true;
    academicFocuses: AcademicFocusModel[] = [];

    constructor(private router: Router, public stateService: StateService, public sanitizer: DomSanitizer, public academicFocusService: AcademicFocusService) {
        var me = this;

        me.academicFocusService.FindAll().subscribe(data => {
            me.academicFocuses = data;
        });
    }

    ngOnInit() {
        var me = this;
    }

    GetImageUrl(item: AcademicFocusModel) {
        var me = this;
        let url = '';
        if (item) {
            url = 'https://gateways-online-content.s3-ap-southeast-2.amazonaws.com/' + item.StorageFolderName + '/' + item.TileImageName;
        }
        return url;
    }

    NavigateToItem(item: AcademicFocusModel) {
        let me = this;

        let filterRequest = new InteractiveInstanceFilterRequestModel();
        filterRequest.AcademicFocusId = item.Id;
        me.stateService.changeFilterRequestModel(filterRequest);

        me.router.navigate(['/interactive-modules']);
    }
}

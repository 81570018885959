import { CommonModule } from '@angular/common';
import { Component, Input, forwardRef, HostBinding, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isBoolean } from 'util';
import { catchError, map, debounceTime, switchMap, concat } from 'rxjs/operators';
import { of, Observable, Subject, BehaviorSubject } from 'rxjs';


@Component({
  selector: 'websilk-select-field',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WebsilkSelectField),
      multi: true
    }
  ],
  template:
    `
      <div *ngIf="displayInline" class="form-group row">
        <label for="{{id}}" class="control-label {{inlineLabelClass}}">{{labelText}}</label>
        <div class="{{inlineFieldClass}}">
          <ng-select bindValue="{{idKey}}" bindLabel="{{descKey}}" [items]="bindItems" [(ngModel)]="value" required="{{required}}" placeholder="{{placeHolderText}}"></ng-select>
          <span *ngIf="errorText=='' && required" class="error-label">{{labelText}} is required</span>
        </div>
      </div>
      
      <div *ngIf="!displayInline" class="form-group">
        <label for="{{id}}" class="control-label" *ngIf="labelText!=''">{{labelText}}</label>
        <div class="">
          <ng-select bindValue="{{idKey}}" bindLabel="{{descKey}}" [items]="bindItems" [(ngModel)]="value" required="{{required}}" placeholder="{{placeHolderText}}"></ng-select>
          <span *ngIf="errorText=='' && required" class="error-label">{{labelText}} is required</span>
        </div>
      </div>
        `
})
export class WebsilkSelectField implements ControlValueAccessor {
  @HostBinding('attr.id')
  externalId = '';

  @Input() id: string;
  @Input('value') _value;
  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    if (this._value == undefined) {
      return 'false';
    }
    if (isBoolean(this._value)) {
      return this._value.toString();
    }

    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  @Input() inlineLabelClass: string = 'col-md-3 text-md-right';
  @Input() inlineFieldClass: string = 'col-md-9';
  @Input() errorText: string = '';
  @Input() labelText: string = '';
  @Input() idKey: string = '';
  @Input() descKey: string = '';
  @Input() bindItems: Array<any> = [];

  @Input('required') _required = 'false';
  get required() {
    return (this._required == 'true');
  }

  @Input('displayInline') _displayInline = 'true';
  get displayInline() {
    return (this._displayInline == 'true');
  }

  @Input('placeholderText') _placeholderText = null;
  get placeHolderText() {
    return this._placeholderText;
  }

  constructor(private cd: ChangeDetectorRef) {
    let me = this;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  getItemValue(item: object, prop: string) {
    var val = '';
    if (prop.indexOf('+') > -1) {
      var arr = prop.split('+');
      for (let i = 0; i < arr.length; i++) {
        val = val + ' ' + item[arr[i]];
      }
    } else {
      val = item[prop];
    }
    return val;
  }

}




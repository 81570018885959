<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading data... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">

    <div class="col-sm-12">
      <div class="card">
        <div class="card-header bg-dark text-light">
          <h5 class="card-title">My schedule</h5>
        </div>
        <div class="card-body">
          <table class="table">
            <thead>
              <tr>
                <th>Start Date/Time</th>
                <th>Finish Time</th>
                <th>Program</th>
                <th>Supervisor</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let session of mySessions">
                <td>{{session.StartDateTime|date:'EEEE dd/MM/yyyy hh:mm a'}}</td>
                <td>{{session.FinishDateTime|date:'hh:mm a'}}</td>
                <td>{{session.InteractiveModule.InteractiveModuleTemplate.Title}}</td>
                <td>{{session.SupervisorUser?.FirstName}} {{session.SupervisorUser?.Surname}}</td>
                <td>
                  <a [routerLink]="['/presenter/program-detail',session.Id]">Manage session</a>
                  <!-- <a href="{{session.SessionLink}}" target="_blank">Launch Zoom Session</a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>

</div>

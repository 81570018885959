export class StudentCreateRequest {
    Firstname: string;
    Surname: string;
    Password: string;
    ConfirmPassword: string;
    DateOfBirth: Date;
    Gender: string;
    YearLevelId: number;
    ParentId: number;
    SchoolId: number;
    ParentEmail: string;
    ParentFirstname: string;
    ParentSurname: string;

    constructor() {
        this.Firstname = "";
        this.Surname = "";
        this.Password = "";
        this.ConfirmPassword = "";
        this.Gender = "";
        this.YearLevelId = null;
        this.ParentEmail = "";
        this.ParentFirstname = "";
        this.ParentSurname = "";
    }
}
<websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

<div class="row" *ngIf="!loading">
  <!-- LEFT COLUMN -->
  <div class="col-sm-7 mb-2">

    <div class="card mb-3 image-card">
      <div class="embed-responsive embed-responsive-16by9">
        <img class="card-img-top embed-responsive-item" [src]="GetImageUrl(instance.InteractiveModuleTemplate)" />
      </div>
    </div>

    <div class="card bg-card-grey mb-3">
      <div class="card-body">
        <h4>About</h4>
        <div [innerHtml]="instance.Description"></div>
      </div>
    </div>

  </div>
  <!-- /LEFT COLUMN -->


  <!-- RIGHT COLUMN -->
  <div class="col-sm-5">

    <!-- ENROL NOW -->
    <div class="card mb-3">
      <div class="card-body bg-purple">

        <div class="mb-3 row">
          <div class="col-8">
            <academic-focus-pill [academicFocus]="instance.InteractiveModuleTemplate.AcademicFocus"></academic-focus-pill>
            <in-out-school-pill [InSchool]="instance.InteractiveModuleTemplate.InviteOnly"></in-out-school-pill>
          </div>
          <div class="col text-right">
            <program-style-pill [OrangeMode]="false" [ProgramStyle]="instance.InteractiveModuleTemplate.InteractiveModuleStyle"></program-style-pill>
          </div>
        </div>

        <div class="mb-3">
          <h5 class="text-white font-weight-bold">{{instance.InteractiveModuleTemplate.Title}}</h5>
          <span class="d-block">
            <strong class="text-orange">- </strong><strong class="text-white" *ngFor="let yearLevel of instance.YearLevels;let i=index"><span *ngIf="i>0">, </span>{{yearLevel.Title}}</strong>
          </span>
        </div>

        <div>
          <div>
            <div class="card bg-white font-weight-bold mt-2 mb-2">
              <div class="card-body">

                <div class="row">
                  <div class="col-sm-12">
                    <div class="d-inline-block pr-2">
                      <i class="fa fa-calendar"></i> {{session.StartDateTime|date:'dd/MM/yyyy'}}
                    </div>
                    <div class="d-inline-block pr-2">
                      <i class="fa fa-clock"></i> {{session.StartDateTime|date:'hh:mm aa'}} - {{session.FinishDateTime|date:'hh:mm aa'}}
                    </div>
                    <div class="d-inline-block float-right">
                      <a class="btn btn-sm bg-orange text-white font-weight-bold" [href]="session.SessionLinkHost" *ngIf="!isPresenter" target="_blank">Start Session</a>
                      <a class="btn btn-sm bg-orange text-white font-weight-bold" [href]="session.SessionLink" *ngIf="isPresenter" target="_blank">Join Session</a>
                    </div>
                  </div>
                </div>

                <div class="row mt-2" *ngIf="!isPresenter">
                  <div class="col-12">
                    <dl>
                      <dt>Zoom Account: {{session.ZoomAccount.AccountEmail}}</dt>
                      <dt>Zoom Password: {{session.ZoomAccount.AccountPassword}}</dt>
                    </dl>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>

      </div>
      <!-- /ENROL NOW -->

    </div>

    <!-- REQUIREMENTS -->
    <div class="card bg-card-grey mb-3">
      <div class="card-body">
        <h4>Requirements</h4>
        <div *ngIf="instance.Requirements" [innerHtml]="instance.Requirements"></div>
        <div *ngIf="!instance.Requirements">No specific requirements</div>
      </div>
    </div>
    <!-- /REQUIREMENTS -->

    <!-- RESOURCES -->
    <div class="card bg-card-grey mb-3" *ngIf="instance.InteractiveModuleResources.length">
      <div class="card-body">
        <h4>Resources</h4>
        <div class="card mb-1" *ngFor="let resource of instance.InteractiveModuleResources; let i = index">

          <div class="card-body">
            <div class="row">
              <div class="col-8"><strong>{{resource.Title}}</strong></div>
              <div class="col-4 text-right">
                <a class="btn btn-sm btn-dark font-size-2" [href]="GetResourceUrl(resource)" target="_blank" download>
                  <i class="fa fa-download"></i> Download
                </a>
              </div>
            </div>
            <p>
              {{resource.Description}}
            </p>
          </div>

        </div>
      </div>
    </div>
    <!-- /RESOURCES -->

  </div>
  <!-- /RIGHT COLUMN -->

</div>

<div class="row" *ngIf="!loading">
  <div class="col-12">
    <div class="card bg-card-grey mb-3">
      <div class="card-body">
        <h4>Students</h4>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Student Name</th>
              <th>Year Level</th>
              <th>Parent Name</th>
              <th>Parent Phone</th>
              <th>School</th>
              <th>State</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let studentEnrolment of students">
              <td>
                {{studentEnrolment.Student.User.FirstName}} {{studentEnrolment.Student.User.Surname}}
              </td>
              <td>{{studentEnrolment.Student.YearLevel.Title}}</td>
              <td>
                {{studentEnrolment.Student.Parent.User.FirstName}} {{studentEnrolment.Student.Parent.User.Surname}}
              </td>
              <td>
                {{studentEnrolment.Student.Parent.PhoneNumber}}
              </td>
              <td>
                {{studentEnrolment.Student.School?.Name}}
              </td>
              <td>
                {{studentEnrolment.Student.School?.State}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="modal-header bg-dark text-white">
  <h4 class="modal-title">Assign Supervisor</h4>
  <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Assigning a supervisor for <strong>{{sessionIds.length}}</strong> scheduled sessions</p>
  <div class="form-group">
    <label class="control-label">Supervisor</label>
    <select class="form-control" [(ngModel)]="request.SupervisorUserId">
      <option value="0">Select a supervisor</option>
      <option *ngFor="let supervisor of supervisors" [value]="supervisor.Id">{{supervisor.FirstName}} {{supervisor.Surname}}</option>
    </select>
  </div>
  <div class="alert alert-success mt-2" *ngIf="success">
    <p>Supervisor assigned.</p>
  </div>
  <div class="alert alert-sdanger mt-2" *ngIf="error">
    <p>There was a problem assigning the supervisor.</p>
    <div>{{errorMessage}}</div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-gateways" (click)="AssignSupervisor()" *ngIf="!processing && !success">Assign Supervisor</button>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')" *ngIf="!processing">Close</button>
  <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
</div>

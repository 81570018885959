import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { StateService, InteractiveModuleService, InteractiveModuleEnrolmentService } from '../../../../services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ModalInteractiveEnrolmentListComponent } from '../../../../components';

import { InteractiveModuleModel, InteractiveModuleEnrolmentModel } from '../../../../models';
import { } from 'src/app/components';

@Component({
    selector: 'page-admin-interactive-module-instances',
    templateUrl: './page-admin-interactive-module-instances.component.html',
})
export class PageAdminInteractiveModuleInstancesComponent implements OnInit {
    loading: boolean = true;
    instances: InteractiveModuleModel[] = [];
    enrolments: InteractiveModuleEnrolmentModel[] = [];

    constructor(public stateService: StateService, public interactiveModuleService: InteractiveModuleService, private interactiveModuleEnrolmentService: InteractiveModuleEnrolmentService, private modalService: NgbModal, private activatedRoute: ActivatedRoute) {
        var me = this;
        me.LoadData();
    }

    ngOnInit() {
        var me = this;
    }

    LoadData() {
        var me = this;

        let templateId = me.activatedRoute.snapshot.params.templateId;

        forkJoin(
            me.interactiveModuleService.FindByTemplateId(templateId),
            me.interactiveModuleEnrolmentService.FindByTemplate(templateId)
        ).subscribe(([data1, data2]) => {
            me.instances = data1;
            me.enrolments = data2;
            me.loading = false;
        });
    }

    GetEnrolments(instance: InteractiveModuleModel): InteractiveModuleEnrolmentModel[] {
        let me = this;
        return me.enrolments.filter(o => o.InteractiveModuleId == instance.Id);
    }
    GetEnrolmentCount(instance: InteractiveModuleModel) {
        let me = this;
        let enrolments = this.GetEnrolments(instance);

        let total = enrolments.map(item => item.InteractiveModuleEnrolmentStudents).reduce((prev, curr) => prev + curr.length, 0);
        return total;
    }
    ViewEnrolments(instance: InteractiveModuleModel) {
        // Launch Modal
        let me = this;
        let enrolments = me.GetEnrolments(instance);
        const modalRef = me.modalService.open(ModalInteractiveEnrolmentListComponent, {
            scrollable: true,
            size: 'lg'
        });
        modalRef.componentInstance.enrolments = enrolments;
    }
    DeleteInstance(id: number) {
        let me = this;
        var isConfirmed = confirm('Are you sure you want to remove this instance?');
        if (isConfirmed) {
            me.interactiveModuleService.Delete(id).subscribe(data => {
                alert('Instance removed');
                me.LoadData();
            }, err => {
                alert('Problem removing instance');
            });
        }
    }
}
import { Component, OnInit } from '@angular/core';

import { StateService, InteractiveModuleSessionService } from '../../../services';

import { InteractiveModuleSessionModel, UserModel } from '../../../models';
import { } from 'src/app/components';

@Component({
    selector: 'page-supervisor-home',
    templateUrl: './page-supervisor-home.component.html',
})
export class PageSupervisorHomeComponent implements OnInit {
    loading: boolean = true;
    entities: InteractiveModuleSessionModel[] = [];
    currentUser: UserModel;

    constructor(public stateService: StateService, private interactiveModuleSessionService: InteractiveModuleSessionService) {
        var me = this;
        me.currentUser = me.stateService.userModel;
        me.stateService.userModel$.subscribe(data => {
            me.currentUser = data;
            me.LoadData()
        });
        me.LoadData()
    }

    ngOnInit() {
        var me = this;
    }

    LoadData() {
        let me = this;
        if (me.currentUser) {
            me.interactiveModuleSessionService.FindBySupervisor(me.currentUser.Id).subscribe(data => {
                me.entities = data;
                me.loading = false;
            });
        };
    }

}

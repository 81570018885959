import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';

import { StateService, ParentService, InteractiveModuleEnrolmentService } from '../../../services';

import { LearningModuleEnrolmentModel, InteractiveModuleEnrolmentModel } from '../../../models';

@Component({
    selector: 'page-parent-enrolments',
    templateUrl: './page-parent-enrolments.component.html',
})
export class PageParentEnrolmentsComponent implements OnInit {
    lEnrolments: LearningModuleEnrolmentModel[] = [];
    iEnrolments: InteractiveModuleEnrolmentModel[] = [];
    individualFutureOnly: boolean = true;

    loading: boolean = true;

    constructor(public stateService: StateService, public parentService: ParentService, public interactiveModuleEnrolmentService: InteractiveModuleEnrolmentService) {
        var me = this;

        me.LoadData();
    }

    ngOnInit() {
        var me = this;
    }

    LoadData() {
        var me = this;

        me.loading = true;
        forkJoin([
            me.interactiveModuleEnrolmentService.MyEnrolments(me.individualFutureOnly),
            me.parentService.FindMyEnrolments()
        ]).subscribe(([data1, data2]) => {
            me.iEnrolments = data1;
            me.lEnrolments = data2;

            me.loading = false;
        });
    }

    IndividualFutureChanged() {
        let me = this;
        me.LoadData();
    }

}

export class PrepareEnrolStudentRequest {
  ParentId: number;
  StudentIds: number[];
  OnAccount: boolean;
  ManualEnrolment: boolean;
  Amount: number;
  ModuleId: number;
  RecaptchaResponse: string;
  UseCredit: boolean;

  constructor() {
      this.StudentIds = [];
      this.OnAccount = false;
      this.ManualEnrolment = false;
      this.RecaptchaResponse = "";
      this.UseCredit = false;
  }
}
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ServiceHelper } from '../config';
import { RoleModel } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class RoleService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindAll(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<RoleModel[]>(this.appConfig.getConfig('apiUrl') + '/roles', { withCredentials: true, headers: headers });
    }
    FindMyRoles(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<RoleModel[]>(this.appConfig.getConfig('apiUrl') + '/roles/my-roles', { withCredentials: true, headers: headers });
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<RoleModel>(this.appConfig.getConfig('apiUrl') + '/roles/' + id, { withCredentials: true, headers: headers });
    }
}
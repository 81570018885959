import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable()
export class AWSS3Service {
    bucket: S3;
    constructor() {
        this.bucket = new S3(
            {
                accessKeyId: 'AKIAY44BPMD4GN255UXZ',
                secretAccessKey: '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',
                region: 'ap-southeast-2'
            });
    }

    UploadFile(folder, file, saveFilename) {
        const contentType = file.type;

        const params = {
            Bucket: 'gateways-online-content',
            Key: folder + '/' + saveFilename,
            Body: file,
            ACL: 'public-read',
            ContentType: contentType
        };

        // var s3Params: S3.PutObjectRequest;

        return this.bucket.upload(params, function (err, data) {
            if (err) {
                console.log('There was an error uploading your file: ', err);
                return false;
            }
            console.log('Successfully uploaded file.', data);
            return true;
        });

    }
}
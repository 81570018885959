import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '../../services';
import { InteractiveModuleEnrolmentModel, InteractiveModuleEnrolmentStudentModel } from '../../models';

@Component({
    selector: 'modal-interactive-enrolment-list',
    templateUrl: 'modal-interactive-enrolment-list.component.html'
})
export class ModalInteractiveEnrolmentListComponent {

    @Input() enrolments: InteractiveModuleEnrolmentModel[];

    constructor(public activeModal: NgbActiveModal, public stateService: StateService) {
        let me = this;
    }

    ngOnInit() {
        let me = this;
    }

    EnrolmentStudents() {
        let me = this;
        let arr = me.enrolments.map(item => item.InteractiveModuleEnrolmentStudents).reduce((prev: InteractiveModuleEnrolmentStudentModel[], curr) => prev.concat(curr), []);
        return arr;
    }
}
<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading child... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-3 pt-2" *ngIf="!students.length">
      <div class="card h-100">
        <div class="card-body mt-5 text-center">
          <h3 class="card-title">No assigned children</h3>
          <p class="card-text">We cannot find any assigned children for your profile. Before you are able to enrol in learning modules you will need to add your children to your profile.</p>
          <a href="javascript:void(0)" class="text-gateways text-decoration-none d-block mt-5" (click)="AddChild()">
            <i class="fa fa-plus fa-3x d-block"></i>
            <h5>Add new child</h5>
          </a>
        </div>
      </div>
    </div>

    <div class="col-sm-3 pt-2" *ngFor="let student of students">
      <div class="card h-100">
        <div class="card-header text-center pt-5 pb-5">
          <i *ngIf="student.Gender=='M'" class="fas fa-male fa-6x"></i>
          <i *ngIf="student.Gender=='F'" class="fas fa-female fa-6x"></i>
          <i *ngIf="student.Gender=='U'" class="fas fa-user fa-6x"></i>
        </div>
        <div class="card-body text-center">
          <h5 class="card-title">{{student.User?.FirstName}} {{student.User?.Surname}}</h5>
          <small class="d-block">Username: {{student.User?.Username}}</small>
          <small class="d-block">Login code: {{student.User?.UniqueToken}}</small>
          <small class="d-block">Born: {{student.DateOfBirth|date:'dd/MM/yyyy'}}</small>
        </div>
        <div class="card-footer p-0">
          <button class="btn btn-gateways w-100 btn-square" (click)="EditChild(student)"><i class="fa fa-edit"></i> Update details</button>
          <button class="btn btn-dark w-100 btn-square-top" (click)="ResetPassword(student)">Reset Password</button>
        </div>
      </div>
    </div>


    <div class="col-sm-3 pt-2" *ngIf="students.length">
      <div class="card h-100">
        <div class="card-body mt-5 text-center">
          <h3 class="card-title">Not Listed?</h3>
          <p class="card-text">Is your child not listed? Before you are able to enrol in learning modules you will need to add your children to your profile.</p>
          <a href="javascript:void(0)" class="text-gateways text-decoration-none d-block mt-5" (click)="AddChild()">
            <i class="fa fa-plus fa-3x d-block"></i>
            <h5>Add new child</h5>
          </a>
        </div>
      </div>
    </div>

  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StateService, StudentService, GenericDataService } from '../../../../services';
import { FormFunctions } from '../../../../functions';
import { StudentModel, GenericLookupModel } from '../../../../models';
import { ModalEditChildComponent } from '../../../../components';

@Component({
    selector: 'page-admin-students',
    templateUrl: './page-admin-students.component.html',
})
export class PageAdminStudentsComponent implements OnInit {
    loading: boolean = true;
    processing: boolean = false;
    entities: StudentModel[] = [];
    filteredEntities: StudentModel[] = [];
    filterName: string = "";
    states: GenericLookupModel[] = [];


    constructor(public stateService: StateService, private formFunctions: FormFunctions, private modalService: NgbModal, public studentService: StudentService, public genericDataService: GenericDataService) {
        var me = this;
        me.states = me.genericDataService.GetStates();

        me.LoadEntities();
    }

    ngOnInit() {
        var me = this;
    }

    LoadEntities() {
        let me = this;
        me.loading = true;
        me.studentService.FindAll().subscribe(data => {
            me.entities = data;
            me.loading = false;
        });
    }

    FilterEntities() {
        let me = this;
        let items = me.entities;

        if (me.filterName.length < 3) {
            me.filteredEntities = [];
            return
        }

        if (me.filterName) {
            items = items.filter(o => (o.User?.FirstName + ' ' + o.User?.Surname).toLowerCase().includes(me.filterName.toLowerCase()));
        }

        me.filteredEntities = items;
    }

    Edit(student: StudentModel) {
        let me = this;
        const modalRef = me.modalService.open(ModalEditChildComponent, {
            scrollable: true
        });
        modalRef.componentInstance.student = student;

        modalRef.result.then((result) => {
            me.LoadEntities();
        });
    }

    // Create() {
    //     let me = this;
    //     const modalRef = me.modalService.open(ModalEditSchoolComponent, {
    //         scrollable: true,
    //         size: 'lg'
    //     });

    //     modalRef.componentInstance.entity = new StudentModel();

    //     modalRef.result.then((result: any) => {
    //         if (result.success) {
    //             me.LoadEntities();
    //         }
    //     });
    // }

}

<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <div class="card-header bg-dark text-white">Register</div>
        <div class="card-body">
          <websilk-register id="wsRegister" includeStudent="true" inlineFormDisplay="false" successRedirect="/login/success"></websilk-register>
          <!-- <form #thisForm="ngForm">

            <websilk-text-field id="txtFirstname" name="txtFirstname" [(ngModel)]="request.Firstname" displayInline="false" labelText="First name" required="true" pattern=""></websilk-text-field>
            <websilk-text-field id="txtSurname" name="txtSurname" [(ngModel)]="request.Surname" displayInline="false" labelText="Surname" required="true" pattern=""></websilk-text-field>
            <websilk-text-field id="txtEmail" name="txtEmail" [(ngModel)]="request.Email" displayInline="false" labelText="Email" required="true" pattern="{{validations.EMAIL}}" fieldType="email" errorText="Must be a valid email address"></websilk-text-field>
            <websilk-text-field id="txtPassword" name="txtPassword" [(ngModel)]="request.Password" displayInline="false" labelText="Password" required="true" pattern="" fieldType="password"></websilk-text-field>
            <websilk-text-field id="txtConfirmPassword" name="txtConfirmPassword" [(ngModel)]="request.ConfirmPassword" displayInline="false" labelText="Confirm Password" required="true" [pattern]="'^'+this.request.Password+'$'" fieldType="password" errorText="Must match password"></websilk-text-field>

            <div class="col-md-6 offset-md-4">
              <button type="button" class="btn btn-gateways" (click)="Register(thisForm,$event)">
                Register
              </button>
            </div>
          </form> -->
        </div>

      </div>
    </div>
  </div>
</div>

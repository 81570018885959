import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StateService, LearningModuleService, ParentService, StudentService, LearningModuleEnrolmentService, AuthService } from '../../../services';

import { LearningModuleModel, StudentModel, ParentModel, EnrolStudentRequest, LearningModuleEnrolmentModel } from '../../../models';

import { ModalAddChildComponent } from '../../../components';

@Component({
    selector: 'page-get-started-list-payment',
    templateUrl: './page-get-started-payment.component.html',
})
export class PageGetStartedPaymentComponent implements OnInit {
    loading: boolean = true;
    learningModule: LearningModuleModel;
    expiryYears: number[] = [];
    children: StudentModel[] = [];
    enrolmentRequest: EnrolStudentRequest = new EnrolStudentRequest();
    errors: String[] = [];
    processing: boolean = false;
    gatewayError: boolean = false;
    enrolmentComplete: boolean = false;
    successfulEnrolmentRecord: LearningModuleEnrolmentModel;
    alreadyEnrolled: boolean = false;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, public stateService: StateService, public authService: AuthService, public learningModuleService: LearningModuleService, public parentService: ParentService, public studentService: StudentService, public learningModuleEnrolmentService: LearningModuleEnrolmentService, private modalService: NgbModal) {
        var me = this;

        let currentYear = new Date().getFullYear();
        for (var i = currentYear; i < currentYear + 15; i++) {
            this.expiryYears.push(i);
        }

        me.stateService.userModel$.subscribe(data => {
            // Logged on state has changed
            me.CheckLoggedOn();
        });

        let urlName = me.activatedRoute.snapshot.params.urlName;
        me.learningModuleService.FindByUrl(urlName).subscribe(data => {
            me.learningModule = data;
            me.CheckLoggedOn();
            me.GetParentAndChildren();
            me.CheckEnrolled();
        });
    }

    ngOnInit() {
        var me = this;
    }

    CheckLoggedOn() {
        let me = this;
        me.authService.IsLoggedOn().subscribe(data => {
            // Logged in, all OK
        }, err => {
            // Not logged on, redirect
            me.router.navigate(['/learning-modules/get-started', me.learningModule.UrlName]);
        })
    }

    CheckEnrolled() {
        let me = this;
        me.learningModuleService.CheckEnrolled(me.learningModule.Id).subscribe(data => {
            // A returned record means I am enrolled
            me.alreadyEnrolled = data != null;
        });
    }

    FieldError(errorType) {
        var hasError = this.errors.indexOf(errorType) > -1;
        return hasError;
    }

    GetParentAndChildren() {
        var me = this;
        // Get the current parent and children (if logged on)
        me.parentService.FindMyStudents().subscribe(data => {
            me.children = data;
            me.loading = false;
        });
    }

    GetSelectedChildrenIds() {
        let me = this;
        let selectedChildren: number[] = [];
        for (var i = 0; i < me.children.length; i++) {
            if (me.children[i].Selected) {
                selectedChildren.push(me.children[i].Id);
            }
        }
        return selectedChildren;
    }

    GetTotalPrice() {
        let me = this;
        // let selectedChildrenCount = me.GetSelectedChildrenIds().length;
        // return (me.learningModule.Price * selectedChildrenCount);
        return me.learningModule.Price;
    }

    BuyNow() {
        let me = this;
        me.gatewayError = false;

        if (me.ValidRequest()) {
            me.processing = true;
            // Selected Children
            let selectedChildren = me.GetSelectedChildrenIds();
            me.enrolmentRequest.StudentIds = selectedChildren;
            me.enrolmentRequest.ModuleId = me.learningModule.Id;
            me.enrolmentRequest.Amount = me.GetTotalPrice();

            me.learningModuleEnrolmentService.EnrolStudent(me.enrolmentRequest).subscribe(data => {
                debugger;
                if (data != null) {
                    // Success
                    me.enrolmentComplete = true;
                    me.successfulEnrolmentRecord = data;
                }
                me.processing = false;
            }, err => {
                // Error
                me.gatewayError = true;
                me.processing = false;
            });
        }
    }
    addChild() {
        let me = this;
        const modalRef = me.modalService.open(ModalAddChildComponent, {
            scrollable: true
        });
        modalRef.componentInstance._includeExtra = 'false';
        modalRef.result.then((result) => {
            me.addChildCallback(result);
        });
    }
    addChildCallback(child) {
        let me = this;
        me.GetParentAndChildren();
    }
    ValidRequest() {
        this.errors = [];

        if (this.GetSelectedChildrenIds().length == 0) {
            this.errors.push('NoChildren');
        }
        if (this.enrolmentRequest.NameOnCard == '') {
            this.errors.push('NameOnCard');
        }
        if (this.enrolmentRequest.CardNumber == '') {
            this.errors.push('CardNumber');
        }
        if (!this.ValidCreditCardNumber(this.enrolmentRequest.CardNumber) && this.enrolmentRequest.CardNumber != '') {
            this.errors.push('InvalidCardNumber');
        }
        if (this.enrolmentRequest.ExpiryMonth == '') {
            this.errors.push('ExpiryMonth');
        }
        if (this.enrolmentRequest.ExpiryYear == '') {
            this.errors.push('ExpiryYear');
        }
        if (this.enrolmentRequest.Cvv == '') {
            this.errors.push('Cvv');
        }
        return !this.errors.length
    }
    ValidCreditCardNumber(cardNumber) {
        let regex = '^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$';
        var matches = cardNumber.match(regex);
        return matches && matches.length > 0;
    }
    ValidEmail(email) {
        let regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        var matches = email.match(regex);
        return matches && matches.length > 0;
    }
}

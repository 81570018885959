import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import { LocationModel } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class LocationService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindAll(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<LocationModel[]>(this.appConfig.getConfig('apiUrl') + '/locations', { withCredentials: true, headers: headers });
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<LocationModel>(this.appConfig.getConfig('apiUrl') + '/locations/' + id, { withCredentials: true, headers: headers });
    }
    Create(item: LocationModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<LocationModel>(this.appConfig.getConfig('apiUrl') + '/locations', params, { withCredentials: true, headers: headers });
    }
    Edit(item: LocationModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<LocationModel>(this.appConfig.getConfig('apiUrl') + '/locations/' + item.Id, params, { withCredentials: true, headers: headers });
    }
}
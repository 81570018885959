<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-8">
      <div class="form-group">
        <label class="control-label">Import File</label>
        <input accept=".xlsx,.xls" type="file" class="form-control" name="filUpload" id="filUpload" #filUpload style="border:0 none;background-color:#f6fafd;">
      </div>
    </div>
    <div class="col-4 text-right">
      <label class="control-label">&nbsp;</label>
      <div>
        <button class="btn btn-gateways" *ngIf="!importing" (click)="Import($event, filUpload)"><i class="fa fa-upload"></i> Import</button>
        <span class="btn btn-dark" *ngIf="importing"><i class="fa fa-spinner fa-spinning"></i> Importing...</span>
      </div>
    </div>
    <div class="col-12" *ngIf="importError">
      <div class="alert alert-danger text-center">{{importError}}</div>
    </div>
  </div>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12">

      <form #thisForm="ngForm">
        <div class="card">
          <div class="card-header bg-dark text-light">
            <h5 class="card-title">Create/Edit</h5>
          </div>
          <div class="card-body bg-light text-dark">
            <div class="form-group">
              <label class="control-label" for="txtAmount">Amount</label>
              <input id="txtAmount" name="txtAmount" type="text" class="form-control" [(ngModel)]="entity.Value">
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-sm-6 text-left">
                <button type="button" class="btn btn-gateways" (click)="Save(thisForm,$event)" *ngIf="!processing">
                  <span>Save</span>
                </button>
                <button type="button" class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i>&nbsp;Processing...</button>
              </div>
              <div class="col-sm-6 text-right">
                <button class="btn btn-dark" (click)="Cancel()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="card mt-3">
        <div class="card-header bg-dark text-light">
          <h5 class="card-title">List</h5>
        </div>
        <div class="card-body bg-light text-dark">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Coupon Code</th>
                <th>Assigned To User</th>
                <th>Value</th>
                <th>Amount Used</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of entities">
                <td>{{item.Code}}</td>
                <td>
                  <span *ngIf="item.AssignedUser">{{item.AssignedUser?.FirstName}} {{item.AssignedUser?.Surname}}</span>
                  <span *ngIf="!item.AssignedUser">Not Assigned</span>
                </td>
                <td>${{item.Value|number:'1.2-2'}}</td>
                <td>${{item.UsedValue|number:'1.2-2'}}</td>
                <td>
                  <button class="btn btn-sm" (click)="Edit(item)"><i class="fa fa-edit"></i> Edit</button>
                  <button class="btn btn-sm text-danger" *ngIf="!item.AssignedUser" (click)="Delete(item)"><i class="fa fa-trash"></i> Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { StateService, InteractiveModuleEnrolmentService, InteractiveModuleStyleService } from '../../../../services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { } from '../../../../components';

import { SearchEnrolmentsRequestModel, InteractiveModuleEnrolmentModel, InteractiveModuleStyleModel } from '../../../../models';

@Component({
    selector: 'page-admin-all-enrolments',
    templateUrl: './page-admin-all-enrolments.component.html',
})
export class PageAdminAllEnrolmentsComponent implements OnInit {
    loading: boolean = true;
    searching: boolean = false;
    searchRequest: SearchEnrolmentsRequestModel = new SearchEnrolmentsRequestModel();
    styles: InteractiveModuleStyleModel[] = [];
    enrolments: InteractiveModuleEnrolmentModel[] = [];
    exporting: boolean = false;

    constructor(public stateService: StateService, private interactiveModuleEnrolmentService: InteractiveModuleEnrolmentService, private modalService: NgbModal, private activatedRoute: ActivatedRoute, public interactiveModuleStyleService: InteractiveModuleStyleService) {
        var me = this;

        forkJoin(
            me.interactiveModuleStyleService.FindAll()
        ).subscribe(([data1]) => {
            me.styles = data1;

            me.loading = false;
        });
    }

    ngOnInit() {
        var me = this;
    }

    Search() {
        let me = this;

        me.searching = true;

        me.interactiveModuleEnrolmentService.Search(me.searchRequest).subscribe(data => {
            me.enrolments = data;

            me.searching = false;
        });
    }

    ExportResults() {
        let me = this;

        me.exporting = true;
        me.interactiveModuleEnrolmentService.Export(me.searchRequest).subscribe(data => {
            var url = URL.createObjectURL(data);
            var link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "enrolments.xlsx");
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            me.exporting = false;
        }, err => {
            me.exporting = false;
            alert('There was a problem exporting your file');
        });

    }

}
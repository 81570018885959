import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import { SchoolModel, SchoolMyEnrolmentsProjectionModel, InteractiveModuleEnrolmentStudentModel } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class SchoolService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindAll(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<SchoolModel[]>(this.appConfig.getConfig('apiUrl') + '/schools', { withCredentials: true, headers: headers });
    }
    FindByState(state: string): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<SchoolModel[]>(this.appConfig.getConfig('apiUrl') + '/schools/by-state/' + state, { withCredentials: true, headers: headers });
    }
    FindMyEnrolments(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<SchoolMyEnrolmentsProjectionModel[]>(this.appConfig.getConfig('apiUrl') + '/schools/my-enrolments/', { withCredentials: true, headers: headers });
    }
    FindStudentRoll(requestDate: string): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleEnrolmentStudentModel[]>(this.appConfig.getConfig('apiUrl') + '/schools/student-roll/' + requestDate, { withCredentials: true, headers: headers });
    }
    ExportStudentRoll(requestDate: string): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get(this.appConfig.getConfig('apiUrl') + '/schools/export-student-roll/' + requestDate, { withCredentials: true, headers: headers, responseType: 'blob', observe: 'response' })
            .pipe(
                map((res: any) => {
                    return new Blob([res.body]);
                })
            );
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<SchoolModel>(this.appConfig.getConfig('apiUrl') + '/schools/' + id, { withCredentials: true, headers: headers });
    }
    Create(request: SchoolModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<SchoolModel>(this.appConfig.getConfig('apiUrl') + '/schools', params, { withCredentials: true, headers: headers });
    }
    Update(request: SchoolModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<SchoolModel>(this.appConfig.getConfig('apiUrl') + '/schools/' + request.Id, params, { withCredentials: true, headers: headers });
    }
}
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { forkJoin } from 'rxjs';

import { StateService, InteractiveModuleSessionService, InteractiveModuleEnrolmentService, InteractiveModuleService } from '../../services';

import { InteractiveModuleSessionModel, UserModel, InteractiveModuleEnrolmentModel, InteractiveModuleModel, InteractiveModuleEnrolmentStudentModel } from '../../models';

@Component({
    selector: 'manage-interactive-session',
    styleUrls: ['./manage-interactive-session.component.scss'],
    templateUrl: './manage-interactive-session.component.html'
})
export class ManageInteractiveSessionComponent {
    @Input() sessionId: number;
    @Input() zoomId: string;
    @Input() isPresenter: boolean = false;

    loading: boolean = true;
    session: InteractiveModuleSessionModel;
    instance: InteractiveModuleModel;
    enrolments: InteractiveModuleEnrolmentModel[] = [];
    students: InteractiveModuleEnrolmentStudentModel[] = [];

    constructor(private activatedRoute: ActivatedRoute, public stateService: StateService, private interactiveModuleSessionService: InteractiveModuleSessionService, public interactiveModuleEnrolmentService: InteractiveModuleEnrolmentService, public interactiveModuleService: InteractiveModuleService) {
        var me = this;

        me.sessionId = me.activatedRoute.snapshot.params.sessionId;
        me.zoomId = me.activatedRoute.snapshot.params.zoomId;

        forkJoin(
            me.interactiveModuleSessionService.FindById(me.sessionId)
        ).subscribe(([data1]) => {
            me.session = data1;
            me.LoadSessionData();
        });

    }

    ngOnInit() {
        var me = this;
    }
    LoadSessionData() {
        let me = this;

        forkJoin(
            me.interactiveModuleEnrolmentService.FindByInstance(me.session.InteractiveModuleId),
            me.interactiveModuleService.FindById(me.session.InteractiveModuleId)
        ).subscribe(([data1, data2]) => {
            me.enrolments = data1;
            me.instance = data2;

            // Extract student enrolments
            var studentsArr = [];
            for (let i = 0; i < data1.length; i++) {
                let item = data1[i];
                let enrolledStudents = item.InteractiveModuleEnrolmentStudents;
                studentsArr = studentsArr.concat(enrolledStudents);
            }

            me.students = studentsArr;

            me.loading = false;
        });
    }

    GetImageUrl(item) {
        var me = this;
        let url = '';
        if (me.instance) {
            url = 'https://gateways-online-content.s3-ap-southeast-2.amazonaws.com/' + item.StorageFolderName + '/' + item.TileImageName;
        }
        return url;
    }

    GetResourceUrl(item) {
        var me = this;
        var url = '';
        url = 'https://gateways-online-content.s3-ap-southeast-2.amazonaws.com/' + me.instance.UrlName + '/' + item.ResourceFilename;
        return url;
    }

}

<div class="container" *ngIf="loading">
  <div class="row mt-3">
    <div class="col-sm-12">
      <websilk-loading displayText="Loading program details... Please wait"></websilk-loading>
    </div>
  </div>
</div>

<div class="container" *ngIf="!loading">

  <div class="row mt-3">

    <!-- LEFT COLUMN -->
    <div class="col-sm-7 mb-2">

      <div class="card mb-3 image-card">
        <div class="embed-responsive embed-responsive-16by9">
          <img class="card-img-top embed-responsive-item" [src]="GetImageUrl(entity.InteractiveModuleTemplate)" />
        </div>
      </div>

      <div class="card bg-card-grey mb-3">
        <div class="card-body">
          <h4>About</h4>
          <div [innerHtml]="entity.Description"></div>
        </div>
      </div>

      <div class="card bg-card-grey mb-3">
        <div class="card-body">
          <h4>About the Presenter</h4>
          <strong>{{entity.InteractiveModuleSessions[0].Presenter.Firstname}} {{entity.InteractiveModuleSessions[0].Presenter.Surname}}</strong>
          <p>{{entity.InteractiveModuleSessions[0].Presenter.Summary}}</p>
        </div>
      </div>

      <div class="card bg-card-grey mb-3" *ngIf="entity.Intention">
        <div class="card-body">
          <h4>Expected Outcomes</h4>
          <div [innerHtml]="entity.Intention"></div>
        </div>
      </div>
    </div>
    <!-- /LEFT COLUMN -->

    <!-- RIGHT COLUMN -->
    <div class="col-sm-5">

      <!-- ENROL NOW -->
      <div class="card mb-3">
        <div class="card-body bg-purple">

          <div class="mb-3 row">
            <div class="col-8">
              <academic-focus-pill [academicFocus]="entity.InteractiveModuleTemplate.AcademicFocus"></academic-focus-pill>
              <in-out-school-pill [InSchool]="entity.InteractiveModuleTemplate.InviteOnly"></in-out-school-pill>
            </div>
            <div class="col text-right">
              <program-style-pill [OrangeMode]="false" [ProgramStyle]="entity.InteractiveModuleTemplate.InteractiveModuleStyle"></program-style-pill>
            </div>
          </div>

          <div class="mb-3">
            <h5 class="text-white font-weight-bold">{{entity.InteractiveModuleTemplate.Title}}</h5>
            <span class="d-block">
              <strong class="text-orange">- </strong><strong class="text-white" *ngFor="let yearLevel of entity.YearLevels;let i=index"><span *ngIf="i>0">, </span>{{yearLevel.Title}}</strong>
            </span>
          </div>

          <div *ngIf="entity.InteractiveModuleSessions.length==1">
            <div *ngFor="let session of entity.InteractiveModuleSessions; let i = index">
              <div class="card bg-white font-weight-bold mt-2 mb-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12" [class.session-complete]="IsSessionComplete(session)">
                      <div class="d-inline-block pr-2">
                        <i class="fa fa-calendar"></i> {{session.StartDateTime|date:'dd/MM/yyyy'}}
                      </div>
                      <div class="d-inline-block pr-2">
                        <i class="fa fa-clock"></i> {{session.StartDateTime|date:'hh:mm aa'}} - {{session.FinishDateTime|date:'hh:mm aa'}}
                      </div>
                      <div class="d-inline-block float-right" *ngIf="!alreadyEnrolled">
                        <span>
                          <span class="btn btn-circle btn-success" *ngIf="GetAvailableCount()>0">{{GetAvailableCount()}}</span>
                          <span class="badge badge-danger" *ngIf="GetAvailableCount()<=0">Full</span>
                        </span>
                      </div>
                      <div class="d-inline-block float-right" *ngIf="alreadyEnrolled">
                        <a class="btn btn-sm bg-orange text-white font-weight-bold" [href]="session.SessionLink" target="_blank">Join Session</a>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>

          <div class="mt-4" *ngIf="!isSupervisor && !isPresenter && !alreadyEnrolled && GetAvailableCount()>0">
            <small class="text-white font-weight-bold">Get started for</small>
          </div>

          <div class="row" *ngIf="!isSupervisor && !isPresenter && GetAvailableCount()>0">
            <div class="col-6" *ngIf="!alreadyEnrolled">
              <h2 class="text-orange font-weight-bold">${{entity.Price|number:'1.2-2'}}</h2>
            </div>
            <div class="text-right" [class.col-6]="!alreadyEnrolled" [class.col-12]="alreadyEnrolled">
              <a [routerLink]="['/interactive-modules/enrol',entity.Id]" class="btn bg-orange text-white font-weight-bold" *ngIf="!alreadyEnrolled">Enrol now</a>
            </div>
          </div>

          <div class="row" *ngIf="!isSupervisor && !isPresenter && GetAvailableCount()<=0">
            <div class="col-12 text-center text-white">
              <strong class="d-block">Program full</strong>
              <small>Please try another date from the list below</small>
            </div>
          </div>

          <div class="row mt-4" *ngIf="(isSupervisor || isPresenter) && entity.InteractiveModuleSessions.length==1">
            <div class="col-6">

            </div>
            <div class="col-6 text-right">
              <!-- If child or presenter -->
              <a class="btn bg-orange text-white font-weight-bold" [href]="entity.InteractiveModuleSessions[0].SessionLink" target="_blank" *ngIf="!isSupervisor && isPresenter">Join Session</a>
              <!-- If Supervisor -->
              <a class="btn bg-orange text-white font-weight-bold" [href]="entity.InteractiveModuleSessions[0].SessionLinkHost" target="_blank" *ngIf="isSupervisor">Start Session</a>
            </div>
          </div>


          <div class="mt-2" *ngIf="entity.Location">
            <h5 class="text-white font-weight-bold">Location</h5>

            <div class="card bg-white font-weight-bold mt-2 mb-2">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div>
                      <i class="fa fa-building mr-2"></i>{{entity.Location.Title}}
                    </div>
                    <div class="">
                      <i class="fa fa-map-marker mr-2"></i><span>{{entity.Location.Address1}} {{entity.Location.Address2}}, {{entity.Location.Suburb}} {{entity.Location.State}} {{entity.Location.Postcode}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <!-- /ENROL NOW -->

      </div>

      <!-- LOCATION -->
      <!-- <div class="card mb-3" *ngIf="entity.Location">
        <div class="card-body bg-purple">
          <h5 class="text-white font-weight-bold">Location</h5>

          <div class="card bg-white font-weight-bold mt-2 mb-2">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="d-inline-block">
                    <i class="fa fa-building mr-2"></i><span>{{entity.Location.Address1}} {{entity.Location.Address2}}, {{entity.Location.Suburb}} {{entity.Location.State}} {{entity.Location.Postcode}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div> -->
      <!-- /LOCATION -->

      <!-- SESSIONS INCLUDED (multiple sessions for instance) -->
      <div class="card mb-3" *ngIf="entity.InteractiveModuleSessions.length > 1">
        <div class="card-body bg-purple">
          <h5 class="text-white font-weight-bold">Sessions included</h5>
          <div *ngFor="let session of entity.InteractiveModuleSessions; let i = index">

            <div class="card bg-white font-weight-bold mt-2 mb-2" [class.session-complete]="IsSessionComplete(session)">
              <div class="card-body">

                <div class="row">
                  <div class="col-sm-12">
                    <div class="d-inline-block pr-2">
                      <i class="fa fa-calendar"></i> {{session.StartDateTime|date:'dd/MM/yyyy'}}
                    </div>
                    <div class="d-inline-block pr-2">
                      <i class="fa fa-clock"></i> {{session.StartDateTime|date:'hh:mm aa'}} - {{session.FinishDateTime|date:'hh:mm aa'}}
                    </div>
                    <div class="d-inline-block">
                      Presenter: {{session.Presenter.Firstname}} {{session.Presenter.Surname}}
                    </div>
                    <div class="mt-1" *ngIf="alreadyEnrolled">
                      <a class="btn btn-sm bg-orange text-white font-weight-bold" [href]="session.SessionLink" target="_blank">Join Session</a>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- /SESSIONS INCLUDED (multiple sessions for instance) -->

      <!-- OTHER AVAILABLE SESSIONS (instance only has 1 session) -->
      <div class="card mb-3" *ngIf="entity.InteractiveModuleSessions.length == 1 && groupedModule">
        <div class="card-body bg-purple">
          <h5 class="text-white font-weight-bold">Other available sessions</h5>
          <a class="session-link text-dark" *ngFor="let otherInstance of groupedModule.Instances" [routerLink]="['/interactive-modules/instance',otherInstance.InteractiveModule.Id]">

            <div class="card bg-white font-weight-bold mt-2 mb-2">
              <div class="card-body" style="font-size:0.7rem;">
                <div class="row" *ngFor="let session of otherInstance.InteractiveModule.InteractiveModuleSessions; let i = index">
                  <div class="col-sm-12">
                    <div class="d-inline-block pr-2">
                      <i class="fa fa-calendar"></i> {{session.StartDateTime|date:'dd/MM/yyyy'}}
                    </div>
                    <div class="d-inline-block pr-2">
                      <i class="fa fa-clock"></i> {{session.StartDateTime|date:'hh:mm aa'}} - {{session.FinishDateTime|date:'hh:mm aa'}}
                    </div>
                    <div class="d-inline-block">
                      by {{session.Presenter.Firstname}} {{session.Presenter.Surname}}
                    </div>
                    <div class="d-link-block float-right">
                      <i class="fa fa-arrow-right"></i>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </a>
        </div>
      </div>
      <!-- OTHER AVAILABLE SESSIONS (instance only has 1 session) -->



      <!-- REQUIREMENTS -->
      <div class="card bg-card-grey mb-3">
        <div class="card-body">
          <h4>Requirements</h4>
          <div *ngIf="entity.Requirements" [innerHtml]="entity.Requirements"></div>
          <div *ngIf="!entity.Requirements">No specific requirements</div>
        </div>
      </div>
      <!-- /REQUIREMENTS -->

      <!-- RESOURCES -->
      <div class="card bg-card-grey mb-3" *ngIf="resources.length && (isPresenter||isSupervisor||alreadyEnrolled)">
        <!-- <div class="card bg-card-grey mb-3" *ngIf="entity.InteractiveModuleResources.length && (isPresenter||isSupervisor||alreadyEnrolled)"> -->
        <div class="card-body">
          <h4>Resources</h4>
          <!-- <div class="card mb-1" *ngFor="let resource of entity.InteractiveModuleResources; let i = index"> -->
          <div class="card mb-1" *ngFor="let resource of resources; let i = index">

            <div class="card-body">
              <div class="row">
                <div class="col-8"><strong>{{resource.Title}}</strong></div>
                <div class="col-4 text-right">
                  <a class="btn btn-sm btn-dark font-size-2" [href]="GetResourceUrl(resource)" target="_blank" download>
                    <i class="fa fa-download"></i> Download
                  </a>
                </div>
              </div>
              <p>
                {{resource.Description}}
              </p>
            </div>

          </div>
        </div>
      </div>
      <!-- /RESOURCES -->

    </div>
    <!-- /RIGHT COLUMN -->

  </div>

</div>

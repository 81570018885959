import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { InteractiveModuleFutureProjectionGroupedModel, InteractiveModuleFutureProjectionModel } from '../../models';

@Component({
    selector: 'interactive-module-instance-group-card',
    styleUrls: ['interactive-module-instance-group-card.component.scss'],
    templateUrl: 'interactive-module-instance-group-card.component.html'
})
export class InteractiveModuleInstanceGroupCardComponent {
    @Input() model: InteractiveModuleFutureProjectionGroupedModel;

    constructor(private datePipe: DatePipe) {
    }
    ngOnInit() {
    }

    GetAvailableSpots(item: InteractiveModuleFutureProjectionModel) {
        var available = item.InteractiveModule.MaxEnrolmentsTotal - item.TotalEnrolments;
        return available;
    }
    IsSessionComplete(session) {
        let now = new Date();
        let startDateTime = session.StartDateTime;

        let dYear = parseInt(this.datePipe.transform(startDateTime, 'yyyy'));
        let dMonth = parseInt(this.datePipe.transform(startDateTime, 'M')) - 1;
        let dDay = parseInt(this.datePipe.transform(startDateTime, 'd'));
        let dHour = parseInt(this.datePipe.transform(startDateTime, 'H'));
        let dMinute = parseInt(this.datePipe.transform(startDateTime, 'm'));

        let sessionStart = new Date(dYear, dMonth, dDay, dHour, dMinute, 0);
        let sDiff = (sessionStart.getTime() - now.getTime()) / 1000;

        // Session has already started, cannot enrol into it
        return sDiff <= 0;
    }
}
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StateService, SchoolService, GenericDataService } from '../../../services';
import { FormFunctions } from '../../../functions';
import { SchoolModel, GenericLookupModel } from '../../../models';
import { ModalEditSchoolComponent } from '../../../components';

@Component({
    selector: 'page-admin-schools',
    templateUrl: './page-admin-schools.component.html',
})
export class PageAdminSchoolsComponent implements OnInit {
    loading: boolean = true;
    processing: boolean = false;
    entities: SchoolModel[] = [];
    filterState: string = "";
    filterName: string = "";
    states: GenericLookupModel[] = [];

    constructor(public stateService: StateService, private formFunctions: FormFunctions, private modalService: NgbModal, public schoolService: SchoolService, public genericDataService: GenericDataService) {
        var me = this;
        me.states = me.genericDataService.GetStates();

        me.LoadEntities();
    }

    ngOnInit() {
        var me = this;
    }

    LoadEntities() {
        let me = this;
        me.loading = true;
        me.schoolService.FindAll().subscribe(data => {
            me.entities = data;
            me.loading = false;
        });
    }

    FilteredSchools() {
        let me = this;
        let items = me.entities;

        if (me.filterState) {
            items = items.filter(o => o.State == me.filterState);
        }

        if (me.filterName) {
            items = items.filter(o => o.Name.toLowerCase().substr(0, me.filterName.length) == me.filterName.toLowerCase());
        }

        if (!me.filterState && !me.filterName) {
            return [];
        } else {
            return items;
        }
    }

    Edit(school: SchoolModel) {
        let me = this;
        const modalRef = me.modalService.open(ModalEditSchoolComponent, {
            scrollable: true,
            size: 'lg'
        });

        modalRef.componentInstance.entity = school;

        modalRef.result.then((result: any) => {
            if (result.success) {
                me.LoadEntities();
            }
        });
    }

    Create() {
        let me = this;
        const modalRef = me.modalService.open(ModalEditSchoolComponent, {
            scrollable: true,
            size: 'lg'
        });

        modalRef.componentInstance.entity = new SchoolModel();

        modalRef.result.then((result: any) => {
            if (result.success) {
                me.LoadEntities();
            }
        });
    }

}

import { Component, OnInit } from '@angular/core';

import { StateService, InteractiveModuleTemplateService } from '../../../../services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ModalCreateInteractiveInstanceComponent } from '../../../../components';

import { InteractiveModuleTemplateModel } from '../../../../models';
import { } from 'src/app/components';

@Component({
    selector: 'page-admin-interactive-module-templates',
    templateUrl: './page-admin-interactive-module-templates.component.html',
})
export class PageAdminInteractiveModuleTemplatesComponent implements OnInit {
    loading: boolean = true;
    templates: InteractiveModuleTemplateModel[] = [];

    constructor(public stateService: StateService, public interactiveModuleTemplateService: InteractiveModuleTemplateService, private modalService: NgbModal) {
        var me = this;

        me.interactiveModuleTemplateService.FindAll().subscribe(data => {
            me.templates = data;
            me.loading = false;
        });
    }

    ngOnInit() {
        var me = this;
    }

    CreateInstance(template: InteractiveModuleTemplateModel) {
        // Launch Modal
        let me = this;
        const modalRef = me.modalService.open(ModalCreateInteractiveInstanceComponent, {
            scrollable: true,
            size: 'lg'
        });
        modalRef.componentInstance.entity = template;
    }

    GetImageUrl(template: InteractiveModuleTemplateModel) {
        let url = 'https://gateways-online-content.s3-ap-southeast-2.amazonaws.com/' + template.StorageFolderName + '/' + template.TileImageName;
        return url;
    }
}

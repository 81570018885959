  <nav class="navbar navbar-expand-lg navbar-dark bg-dark text-light" *ngIf="mode">
    <div class="container">
      <!-- COMMON MENU -->
      <ul class="navbar-nav mr-auto" *ngIf="mode=='common'">
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin']">Dashboard</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/lookups/presenters']">Presenters</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/lookups/academic-focuses']">Academic Focuses</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/lookups/zoom-accounts']">Zoom Accounts</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/lookups/interactive-module-styles']">Interactive Module Styles</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/users']">Users</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/lookups/schools']">Schools</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/lookups/students']">Students</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/lookups/parents']">Parents</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/lookups/timetables']">Time Tables</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/lookups/locations']">Locations</a></li>
      </ul>
      <!-- /COMMON MENU -->

      <!-- INTERACTIVE MENU -->
      <ul class="navbar-nav mr-auto" *ngIf="mode=='interactive'">
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin']">Dashboard</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/interactive-module-templates']">Templates</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/interactive-modules/session-calendar']">Session Calendar</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/interactive-modules/sessions']">All Sessions</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/interactive-modules/all-enrolments']">All Enrolments</a></li>
      </ul>
      <!-- /INTERACTIVE MENU -->
    </div>
  </nav>

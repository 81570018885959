export class SchoolModel {
    Id: number;
    Name: string;
    Address1: string;
    Address2: string;
    Address3: string;
    Suburb: string;
    State: string;
    Postcode: string;
    Country: string;
    ContactName: string;
    ContactEmail: string;

    constructor() {
        this.Name = "";
        this.Address1 = "";
        this.Address2 = "";
        this.Address3 = "";
        this.Suburb = "";
        this.State = "";
        this.Postcode = "";
        this.Country = "";
        this.ContactName = "";
        this.ContactEmail = "";
    }
}
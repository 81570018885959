import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService, ParentService, GenericDataService } from '../../services';
import { ParentModel, GenericLookupModel } from '../../models';
import { FormFunctions, CustomDateParserFormatter } from '../../functions';
import { Validations } from '../../constants'

@Component({
    selector: 'modal-edit-parent',
    template: `
    <div class="modal-header bg-dark text-white">
      <h4 class="modal-title">My Details</h4>
      <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form #thisForm="ngForm">
            <websilk-text-field id="txtEmail" name="txtEmail" [(ngModel)]="entity.User.Email" displayInline="false" labelText="Email" required="true" pattern=""></websilk-text-field>
            <websilk-text-field id="txtFirstname" name="txtFirstname" [(ngModel)]="entity.User.FirstName" displayInline="false" labelText="First name" required="true" pattern=""></websilk-text-field>
            <websilk-text-field id="txtSurname" name="txtSurname" [(ngModel)]="entity.User.Surname" displayInline="false" labelText="Surname" required="true" pattern=""></websilk-text-field>
            <websilk-text-field id="txtPhoneNumber" name="txtPhoneNumber" [(ngModel)]="entity.PhoneNumber" displayInline="false" labelText="Contact Phone" required="true" pattern=""></websilk-text-field>
            <websilk-text-field id="txtAddress1" name="txtAddress1" [(ngModel)]="entity.Address1" displayInline="false" labelText="Address Line 1" required="false" pattern=""></websilk-text-field>
            <websilk-text-field id="txtAddress2" name="txtAddress2" [(ngModel)]="entity.Address2" displayInline="false" labelText="Address Line 2" pattern=""></websilk-text-field>
            <websilk-text-field id="txtAddress2" name="txtAddress3" [(ngModel)]="entity.Address3" displayInline="false" labelText="Address Line 3" pattern=""></websilk-text-field>
            <websilk-text-field id="txtSuburb" name="txtSuburb" [(ngModel)]="entity.Suburb" displayInline="false" labelText="Suburb" pattern=""></websilk-text-field>
            <websilk-select-field id="selState" name="selState" [bindItems]="states" [(ngModel)]="entity.State" labelText="State" idKey="Id" descKey="Description" required="false" displayInline="false"></websilk-select-field>
            <websilk-text-field id="txtPostcode" name="txtPostcode" [(ngModel)]="entity.Postcode" displayInline="false" labelText="Postcode" pattern=""></websilk-text-field>

            <div class="alert alert-danger text-center" *ngIf="showError">
                <i class="fa fa-exclamation-triangle fa-2x"></i>
                <h5 class="">There was a problem updating details: {{errorMessage}}</h5>
            </div>
            <div class="alert alert-success text-center" *ngIf="showSuccess">
                <i class="fa fa-tick fa-2x"></i>
                <h5 class="">Details updated successfully.</h5>
            </div>
        </form>

    </div>
    <div class="modal-footer">
        <button class="btn btn-gateways" (click)="UpdateDetails(thisForm,$event)" *ngIf="!processing"><i class="fa fa-save"></i> Save</button>
        <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')" *ngIf="!processing">Close</button>
    </div>
  `
})
export class ModalEditParentComponent {
    @Input() entity: ParentModel;
    states: GenericLookupModel[] = [];
    loading: boolean = true;
    showSuccess: boolean = false;
    showError: boolean = false;
    processing: boolean = false;
    errorMessage: string = "";

    constructor(public activeModal: NgbActiveModal, public stateService: StateService, public validations: Validations, public formFunctions: FormFunctions, public parentService: ParentService, public genericDataService: GenericDataService) {
        let me = this;

        me.states = me.genericDataService.GetStates();
    }
    ngOnInit() {

    }
    UpdateDetails(form, e) {
        let me = this;

        if (this.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            me.showError = false;
            me.errorMessage = "";
            me.parentService.Update(me.entity).subscribe(data => {
                me.showSuccess = true;
                me.processing = false;
                me.entity = data;
            }, err => {
                me.processing = false;
                me.showError = true;
                me.errorMessage = err.error;
            });
        }
    }
}
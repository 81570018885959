import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import { LearningModuleEnrolmentStudentModel } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class LearningModuleEnrolmentStudentService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<LearningModuleEnrolmentStudentModel>(this.appConfig.getConfig('apiUrl') + '/learning-module-enrolment-students/' + id, { withCredentials: true, headers: headers });
    }
    Create(request: LearningModuleEnrolmentStudentModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<LearningModuleEnrolmentStudentModel>(this.appConfig.getConfig('apiUrl') + '/learning-module-enrolment-students', params, { withCredentials: true, headers: headers });
    }
    Update(request: LearningModuleEnrolmentStudentModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<LearningModuleEnrolmentStudentModel>(this.appConfig.getConfig('apiUrl') + '/learning-module-enrolment-students/' + request.Id, params, { withCredentials: true, headers: headers });
    }
}
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService, AuthService, RoleService } from '../../services';
import { UserModel, RoleModel, UserRoleModel } from '../../models';
import { FormFunctions, CustomDateParserFormatter } from '../../functions';
import { Validations } from '../../constants'

@Component({
    selector: 'modal-add-user-role',
    templateUrl: './modal-add-user-role.component.html'
})
export class ModalAddUserRoleComponent {
    @Input() user: UserModel;
    roles: RoleModel[] = [];
    processing: boolean = false;
    success: boolean = false;
    request: UserRoleModel = new UserRoleModel();

    constructor(public activeModal: NgbActiveModal, public stateService: StateService, public validations: Validations, public formFunctions: FormFunctions, public authService: AuthService, public roleService: RoleService) {
        let me = this;
    }
    ngOnInit() {
        let me = this;

        me.request.UserId = me.user.Id;

        me.roleService.FindAll().subscribe(data => {
            me.roles = data;
        });

    }
    Update(form, e) {
        var me = this;
        if (me.formFunctions.isFormValid(form, e)) {
            me.success = false;
            me.processing = true;

            me.authService.AddRole(me.request).subscribe(data => {
                me.success = true;
                me.processing = false;
            });
        }
    }
}
export class CreateZoomSessionRequestModel {
    SessionIds: number[];
    ZoomAccountId: number;
    CreateForEachSession: boolean;
    TitleOverride: string;

    constructor() {
        this.SessionIds = [];
        this.ZoomAccountId = 0;
        this.CreateForEachSession = true;
        this.TitleOverride = "";
    }
}
export class ZoomAccountModel {
    Id: number;
    Title: string;
    AccountEmail: string;
    AccountPassword: string;
    ApiKey: string;
    ApiSecret: string;
    AccountId: string;

    constructor() {
        this.Title = "";
        this.AccountEmail = "";
        this.AccountPassword = "";
        this.ApiKey = "";
        this.ApiSecret = "";
        this.AccountId = "";
    }
}
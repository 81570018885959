<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">

    <div class="col-5">
      <div class="form-group form-inline">
        <label class="control-label">Users for Role</label>
        <select class="form-control ml-3" [(ngModel)]="selectedRole">
          <option value="">Select role</option>
          <option *ngFor="let role of roles" [value]="role.Name">{{role.Name}}</option>
        </select>
        <button class="btn btn-dark ml-3" (click)="SearchUsers()">Display Users</button>
      </div>
    </div>

    <div class="col-5">
      <div class="form-group form-inline">
        <label class="control-label">User Name</label>
        <input type="text" class="form-control ml-3 w-75" [(ngModel)]="filterName" (keyup)="FilterEntities()">
      </div>
    </div>
    <div class="col-2 text-right">
      <button class="btn btn-dark" (click)="CreateUser()"><i class="fa fa-plus"></i> Create</button>
    </div>

    <div class="col-sm-12">

      <table class="table table-striped font-size-2">
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>First name</th>
            <th>Surname</th>
            <th>Roles</th>
            <th>School</th>
            <th style="width:170px;">Action</th>
          </tr>
        </thead>
        <tbody *ngIf="loadingUsers">
          <tr>
            <td colspan="7" class="text-center">
              <websilk-loading></websilk-loading>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!loadingUsers && entities.length==0">
          <tr>
            <td colspan="7" class="text-center">
              <h5>No users to display</h5>
              <button class="btn btn-dark" (click)="CreateUser()"><i class="fa fa-plus"></i> Create</button>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!loadingUsers && entities.length>0 && filteredEntities.length==0">
          <tr>
            <td colspan="7" class="text-center">
              <h5>Too many users to display, please use the name filter</h5>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!loadingUsers && filteredEntities.length>0">
          <tr *ngFor="let user of filteredEntities">
            <td>{{user.Username}}</td>
            <td>{{user.Email}}</td>
            <td>{{user.FirstName}}</td>
            <td>{{user.Surname}}</td>
            <td>
              <span class="badge badge-sm badge-dark mr-1" *ngFor="let role of user.Roles">{{role.Name}}</span>
            </td>
            <td>{{user.School?.Name}}</td>
            <td>
              <div>
                <button class="btn btn-dark btn-sm" (click)="ResetPassword(user)"><i class="fa fa-key"></i> Reset Password</button>
              </div>
              <div class="mt-1">
                <button class="btn btn-gateways btn-sm" (click)="AddRole(user)">Add Role</button>
              </div>
              <div class="mt-1">
                <button class="btn btn-gateways btn-sm" (click)="EditUser(user)"><i class="fa fa-edit"></i> Edit User</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

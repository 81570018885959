export class CreatePresenterRequest {
    Id: number;
    Firstname: string;
    Surname: string;
    Email: string;
    Summary: string;

    constructor() {
        this.Id = 0;
        this.Firstname = "";
        this.Surname = "";
        this.Email = "";
        this.Summary = "";
    }
}
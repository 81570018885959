<div class="card bg-card-grey">
  <div class="card-body">
    <div class="mb-3 row">
      <div class="col">
        <academic-focus-pill [academicFocus]="model.Template.AcademicFocus" [LightMode]="true"></academic-focus-pill>
        <in-out-school-pill [InSchool]="model.Template.InviteOnly" [LightMode]="true"></in-out-school-pill>
        <delivery-pill [IsOnsite]="model.Template.IsOnsite"></delivery-pill>
      </div>
      <div class="col text-right">
        <program-style-pill [OrangeMode]="true" [ProgramStyle]="model.Template.InteractiveModuleStyle"></program-style-pill>
      </div>
    </div>
    <h5 class="text-purple font-weight-bold">{{model.Template.Title}}</h5>
    <span class="d-block">
      <strong class="text-orange">- </strong><strong class="text-gateways" *ngFor="let yearLevel of model.Instances[0].InteractiveModule.YearLevels;let i=index"><span *ngIf="i>0">, </span>{{yearLevel.Title}}</strong>
    </span>

    <p>{{model.Template.ShortDescription}}</p>

    <div class="card bg-white font-weight-bold mt-2 mb-2" *ngFor="let instance of model.Instances">
      <div class="card-body">

        <div class="row">

          <div class="col-sm-7">
            <div *ngFor="let session of instance.InteractiveModule.InteractiveModuleSessions; let i = index" [class.session-complete]="IsSessionComplete(session)">
              <div class="d-inline-block pr-2">
                <i class="fa fa-calendar"></i> {{session.StartDateTime|date:'dd/MM/yyyy'}}
              </div>
              <div class="d-inline-block pr-2">
                <i class="fa fa-clock"></i> {{session.StartDateTime|date:'hh:mm aa'}} - {{session.FinishDateTime|date:'hh:mm aa'}}
              </div>
              <div class="d-inline-block">
                by {{session.Presenter.Firstname}} {{session.Presenter.Surname}}
              </div>
              <hr class="mt-1 mb-1" *ngIf="instance.InteractiveModule.InteractiveModuleSessions.length > 1 && i!=(instance.InteractiveModule.InteractiveModuleSessions.length-1)" />
            </div>
          </div>

          <div class="col-sm-5 text-right">
            <div class="d-inline-block pr-4">
              <span *ngIf="GetAvailableSpots(instance) > 5">
                <span class="btn btn-circle btn-success">{{GetAvailableSpots(instance)}}</span> Available
              </span>
              <span *ngIf="GetAvailableSpots(instance) > 0 && GetAvailableSpots(instance) <= 5">
                <span class="btn btn-circle btn-warning">{{GetAvailableSpots(instance)}}</span> Available
              </span>
            </div>
            <div class="d-inline-block pr-4" *ngIf="GetAvailableSpots(instance) > 0">
              <span class="text-purple">${{instance.InteractiveModule.Price|number:'1.2-2'}}</span>
            </div>
            <div class="d-inline-block">
              <a *ngIf="GetAvailableSpots(instance)>0" [routerLink]="['/interactive-modules/instance',instance.InteractiveModule.Id]" class="btn btn-sm btn-dark mr-1">Details</a>
              <a *ngIf="GetAvailableSpots(instance)>0" [routerLink]="['/interactive-modules/enrol',instance.InteractiveModule.Id]" class="btn btn-sm btn-gateways">Enrol</a>
              <span *ngIf="GetAvailableSpots(instance)<=0" class="btn btn-sm btn-danger">Program Full</span>
            </div>
          </div>

        </div>

        <!-- LOCATION -->
        <div class="row mt-4" *ngIf="instance.InteractiveModule.Location">
          <div class="col-12">
            <div>
              <i class="fa fa-building mr-2"></i>{{instance.InteractiveModule.Location.Title}}
            </div>
            <div class="">
              <i class="fa fa-map-marker mr-2"></i><span>{{instance.InteractiveModule.Location.Address1}} {{instance.InteractiveModule.Location.Address2}}, {{instance.InteractiveModule.Location.Suburb}} {{instance.InteractiveModule.Location.State}} {{instance.InteractiveModule.Location.Postcode}}</span>
            </div>
          </div>
        </div>
        <!-- /LOCATION -->

      </div>
    </div>

  </div>
</div>

<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading data... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12">
      <p class="p-4">Welcome to your G.A.T.E.WAYS Online School Portal. You will find details below of upcoming enrolments for in-school interactive programs at your school. Please ensure you print out your roll the day before the sessions to ensure you can provide students with their logon codes.</p>
    </div>

    <div class="col-sm-12 pt-2" *ngIf="!dates.length">
      <div class="card h-100">
        <div class="card-body mt-1 text-center">
          <i class="fa fa-exclamation-triangle fa-2x"></i>
          <h3 class="card-title">No upcoming enrolments</h3>
          <p class="card-text">Your school does not have any upcoming enrolments scheduled.</p>
        </div>
      </div>
    </div>

    <div class="col-sm-12" *ngIf="dates.length">

      <div class="card bg-light" *ngFor="let date of dates">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-8">
              <h4 class="text-purple"><span class="card-title h4"><i class="fa fa-calendar"></i> {{date.Date|date:'dd/MM/yyyy'}}</span></h4>
            </div>
            <div class="col-sm-4 text-right">
              <button class="btn btn-dark float-right" (click)="ViewRoll(date.Date)">View Roll</button>
              <button class="btn btn-gateways float-right mr-1" (click)="ExportRoll(date.Date)">Export Roll</button>
            </div>
          </div>

          <div class="card bg-white mt-2 mb-2" *ngFor="let session of date.Sessions">
            <div class="card-body">
              <div class="row font-weight-bold mb-3 h5">
                <div class="col-sm-7">
                  {{session.Session.InteractiveModule.Title}}
                </div>
                <div class="col-sm-5 text-right">
                  <i class="fa fa-clock"></i> {{session.Session.StartDateTime|date:'hh:mm aa'}} - {{session.Session.FinishDateTime|date:'hh:mm aa'}}
                </div>
              </div>

              <div class="row bg-dark text-light font-weight-bold pt-1 pb-1">
                <div class="col-sm-5">Student</div>
                <div class="col-sm-2">Date of Birth</div>
                <div class="col-sm-3">Logon code</div>
                <div class="col-sm-2 text-right">Link</div>
              </div>
              <div class="row pt-1 pb-1" *ngFor="let student of session.Enrolments">
                <div class="col-sm-5">
                  {{student.Student.User?.FirstName}} {{student.Student.User?.Surname}}
                </div>
                <div class="col-sm-2">{{student.Student.DateOfBirth|date:'dd/MM/yyyy'}}</div>
                <div class="col-sm-3">{{student.Student.User?.UniqueToken}}</div>
                <div class="col-sm-2 text-right"><a class="text-gateways" [href]="session.Session.SessionLink" target="_blank"><i class="fa fa-external-link"></i>Join Session</a></div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>

import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StateService, ScriptService, InteractiveModuleTemplateService, AcademicFocusService, InteractiveModuleTemplateResourceService, YearLevelService, InteractiveModuleStyleService, LocationService } from '../../../../services';

import { FormFunctions } from '../../../../functions';

import { InteractiveModuleTemplateModel, GenericLookupModel, AcademicFocusModel, InteractiveModuleTemplateResourceModel, YearLevelModel, InteractiveModuleStyleModel, UpdateImageRequestModel, LocationModel } from '../../../../models';
import { ModalAddTemplateResourceComponent, ModalUploadImageComponent } from '../../../../components';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'page-admin-interactive-module-template-item',
    templateUrl: './page-admin-interactive-module-template-item.component.html',
})
export class PageAdminInteractiveModuleTemplateItemComponent implements OnInit {
    loading: boolean = true;
    savingYears: boolean = false;
    processing: boolean = false;
    editMode: boolean = false;
    editSuccess: boolean = false;
    editFail: boolean = false;
    entity: InteractiveModuleTemplateModel;
    resources: InteractiveModuleTemplateResourceModel[] = [];
    arrTrueFalse: GenericLookupModel[] = [];
    arrAcademicFocuses: AcademicFocusModel[] = [];
    arrYearLevels: YearLevelModel[];
    arrStyles: InteractiveModuleStyleModel[] = [];
    arrLocations: LocationModel[] = [];
    ckeConfig: any;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private modalService: NgbModal, private formFunctions: FormFunctions, public stateService: StateService, public scriptService: ScriptService, public interactiveModuleTemplateService: InteractiveModuleTemplateService, public academicFocusService: AcademicFocusService, public interactiveModuleTemplateResourceService: InteractiveModuleTemplateResourceService, public yearLevelService: YearLevelService, public interactiveModuleStyleService: InteractiveModuleStyleService, public locationService: LocationService) {
        var me = this;

        me.ckeConfig = {
            allowedContent: false,
            forcePasteAsPlainText: true,
            removePlugins: 'horizontalrule,specialchar,about,others',
            removeButtons: 'Save,NewPage,Preview,Print,Templates,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Find,Select,Button,ImageButton,HiddenField,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,CopyFormatting,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,PageBreak,Iframe,Font,FontSize,TextColor,BGColor,ShowBlocks,Cut,Copy,Paste,Image,Maximize,Styles,Anchor,SpecialChar,Scayt,Undo,Redo,Strike,RemoveFormat,Indent,Outdent'
        };

        me.arrTrueFalse.push(new GenericLookupModel('false', "No"));
        me.arrTrueFalse.push(new GenericLookupModel('true', "Yes"));

        forkJoin(
            me.academicFocusService.FindAll(),
            me.yearLevelService.FindAll(),
            me.interactiveModuleStyleService.FindAll(),
            me.locationService.FindAll()
        ).subscribe(([data1, data2, data3, data4]) => {
            me.arrAcademicFocuses = data1;
            me.arrYearLevels = data2;
            me.arrStyles = data3;
            me.arrLocations = data4;
        });

        var id = me.activatedRoute.snapshot.params.id;

        if (id) {
            me.LoadEntity(id);
        } else {
            me.entity = new InteractiveModuleTemplateModel();
            me.editMode = false;
            me.loading = false;
        }
    }

    ngOnInit() {
        var me = this;
    }

    ngAfterContentInit() {
        let me = this;
        me.scriptService.load('ckeditor').then(data => {

        });
    }

    LoadEntity(id) {
        let me = this;
        me.loading = true;

        me.interactiveModuleTemplateService.FindById(id).subscribe(data => {
            let val = parseFloat(data.Price);
            if (!isNaN(val)) {
                data.Price = val.toFixed(2);
            }

            me.entity = data;
            me.loading = false;
            me.editMode = true;
            me.LoadResources();
        });
    }
    LoadResources() {
        let me = this;
        me.interactiveModuleTemplateResourceService.FindByTemplateId(me.entity.Id).subscribe(data => {
            me.resources = data;
        });
    }

    YearLevelChange(item: YearLevelModel) {
        let me = this;
        // let existPos = me.entity.YearLevels.indexOf(item);
        let existPos = me.entity.YearLevels.map(function (e) { return e.Id; }).indexOf(item.Id);
        if (existPos < 0) {
            me.entity.YearLevels.push(item);
        } else {
            me.entity.YearLevels.splice(existPos, 1);
        }
    }

    YearLevelChecked(item: YearLevelModel) {
        let me = this;

        if (me.entity.YearLevels.filter(o => o.Id == item.Id).length > 0) {
            return true;
        }
        return false;
    }

    GetImageUrl(item) {
        var me = this;
        let url = '';
        if (me.entity) {
            url = 'https://gateways-online-content.s3-ap-southeast-2.amazonaws.com/' + item.StorageFolderName + '/' + item.TileImageName;
        }
        return url;
    }

    ChangeImage() {
        let me = this;

        let storageFolder = me.entity.UrlName.toString();

        const modalRef = me.modalService.open(ModalUploadImageComponent, {
            scrollable: true,
            size: 'lg'
        });
        modalRef.componentInstance.StorageFolderName = storageFolder;
        modalRef.result.then((result) => {
            if (result != 'exit') {
                var req = new UpdateImageRequestModel();
                req.EntityId = me.entity.Id;
                req.ImageName = result;
                req.StorageFolderName = storageFolder;

                me.interactiveModuleTemplateService.UpdateImage(req).subscribe(data => {
                    me.entity = data;
                });
            }
        });
    }

    Save(form, e, successTarget, failTarget) {
        let me = this;
        if (me.formFunctions.isFormValid(form, e)) {
            // Dependant view
            if (!me.entity.InviteOnly) {
                me.entity.MaxEnrolmentsSchool = null;
            }

            me.editSuccess = false;
            me.editFail = false;
            me.processing = true;
            if (this.editMode) {
                me.interactiveModuleTemplateService.Edit(me.entity).subscribe(data => {
                    me.editSuccess = true;
                    successTarget.scrollTo(0, 0);
                    me.processing = false;
                    me.LoadEntity(me.entity.Id);
                }, err => {
                    me.editFail = true;
                    failTarget.scrollTo(0, 0);
                    me.processing = false;
                });
            } else {
                me.interactiveModuleTemplateService.Create(me.entity).subscribe(data => {
                    me.processing = false;
                    me.router.navigate(['/admin/interactive-module-templates/edit', data.Id]);
                }, err => {
                    me.processing = false;
                });
            }
        }
    }

    NewResource() {
        let me = this;
        const modalRef = me.modalService.open(ModalAddTemplateResourceComponent, {
            scrollable: true,
            size: 'lg'
        });
        modalRef.componentInstance.template = me.entity;
        modalRef.result.then((result) => {
            me.LoadResources();
        });
    }

    UpdateYearLevels() {
        let me = this;

        let arr = [];
        for (let i = 0; i < me.entity.YearLevels.length; i++) {
            arr.push(me.entity.YearLevels[i].Id);
        }

        me.savingYears = true;
        me.interactiveModuleTemplateService.UpdateYearLevels(me.entity.Id, arr).subscribe(data => {
            me.LoadEntity(me.entity.Id);
            me.savingYears = false;
        }, err => {
            me.savingYears = false;
        });
    }

    DeleteResource(resource) {
        let me = this;

        if (confirm("This will remove the resource permenantly. Are you sure you wish to continue?")) {
            me.interactiveModuleTemplateResourceService.Delete(resource.Id).subscribe(data => {
                me.LoadResources();
            });
        }
    }
}

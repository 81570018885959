<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">

    <div class="col-sm-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Common Data</h5>
          <p>Manage and maintain your common data such as users, presenters, lookups.</p>
        </div>
        <div class="card-footer p-0">
          <a [routerLink]="['/admin/lookups/presenters']" class="btn btn-lg btn-dark w-100 btn-square">Manage</a>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Learning Modules</h5>
          <p>Manage and maintain your on demand learning modules</p>
        </div>
        <div class="card-footer p-0">
          <a href="#" class="btn btn-lg btn-dark w-100 btn-square">Manage</a>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Interactive Modules</h5>
          <p>Manage and maintain your on live and interactive modules</p>
        </div>
        <div class="card-footer p-0">
          <a [routerLink]="['/admin/interactive-module-templates']" class="btn btn-lg btn-dark w-100 btn-square">Manage</a>
        </div>
      </div>
    </div>

  </div>
</div>

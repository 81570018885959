import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  SignedInGuard,
  AdminRoleGuard,
  ParentRoleGuard,
  StudentRoleGuard,
  PresenterRoleGuard,
  SchoolRoleGuard,
  SupervisorRoleGuard
} from './guards';

import {
  PageHomeComponent,
  PageLoginComponent,
  PageRegisterComponent,
  PageParentHomeComponent,
  PageParentChildrenComponent,
  PageParentEnrolmentsComponent,
  PageLearningModuleListComponent,
  PageLearningModuleItemComponent,
  PageGetStartedComponent,
  PageGetStartedPaymentComponent,
  PageStudentHomeComponent,
  PageForgotPasswordComponent,
  PageAdminInteractiveModuleTemplatesComponent,
  PageAdminDashboardComponent,
  PageAdminInteractiveModuleTemplateItemComponent,
  PageAdminAcademicFocusesComponent,
  PageAdminPresentersComponent,
  PageInteractiveModuleListComponent,
  PageInteractiveModuleItemComponent,
  PageInteractiveModuleEnrolComponent,
  PageInteractiveModuleEnrolFinaliseComponent,
  PageInteractiveInstanceComponent,
  PagePresenterHomeComponent,
  PageSchoolEnrolmentsComponent,
  PageSchoolEnrolmentsRoleComponent,
  PageAdminInteractiveModuleInstancesComponent,
  PageSupervisorHomeComponent,
  PageAdminInteractiveModuleInstanceEnrolmentsComponent,
  PageAdminZoomAccountsComponent,
  PageAdminCreateInstancesComponent,
  PageAdminInteractiveModuleStylesComponent,
  PageAdminUserListComponent,
  PageInteractiveModuleSessionCalendarComponent,
  PageAdminSessionListComponent,
  PageAdminAllEnrolmentsComponent,
  PageAdminSchoolsComponent,
  PageSupervisorProgramDetailComponent,
  PagePresenterProgramDetailComponent,
  PageAdminTimetablesComponent,
  PageAdminStudentsComponent,
  PageAdminParentsComponent,
  PageAdminLocationsComponent,
  PageParentRedeemCreditCouponComponent,
  PageAdminCreditCouponsComponent
} from './pages'

const routes: Routes = [
  { path: '', component: PageHomeComponent, data: { title: 'Gateways Online', showBanner: true } },
  { path: 'login', component: PageLoginComponent, data: { title: 'Sign In' } },
  { path: 'login/:status', component: PageLoginComponent, data: { title: 'Sign In' } },
  { path: 'register', component: PageRegisterComponent, data: { title: 'Register' } },
  { path: 'reset-password', component: PageForgotPasswordComponent, data: { title: 'Reset Password' } },
  { path: 'reset-password/:token', component: PageForgotPasswordComponent, data: { title: 'Reset Password' } },
  { path: 'learning-modules', component: PageLearningModuleListComponent, data: { title: 'On Demand Programs' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'learning-modules/:urlName', component: PageLearningModuleItemComponent, data: { title: 'On Demand Program' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'learning-modules/get-started/:urlName', component: PageGetStartedComponent, data: { title: 'Get Started' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'learning-modules/get-started/finalise/:urlName', component: PageGetStartedPaymentComponent, data: { title: 'Finalise Enrolment' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'interactive-modules', component: PageInteractiveModuleListComponent, data: { title: 'Live and Interactive Programs', showBanner: true } },
  { path: 'interactive-modules/instance/:id', component: PageInteractiveInstanceComponent, data: { title: 'Interactive Program', showBanner: true } },
  { path: 'interactive-modules/:urlName', component: PageInteractiveModuleItemComponent, data: { title: 'Interactive Program' } },
  { path: 'interactive-modules/enrol/:id', component: PageInteractiveModuleEnrolComponent, data: { title: 'Enrol' } },
  { path: 'interactive-modules/enrol/finalise/:id', component: PageInteractiveModuleEnrolFinaliseComponent, data: { title: 'Finalise enrolment', showBanner: true } },
  // Parent Role
  { path: 'parent', component: PageParentHomeComponent, data: { title: 'Parent Portal' }, canActivate: [SignedInGuard, ParentRoleGuard] },
  { path: 'parent/children', component: PageParentChildrenComponent, data: { title: 'My Children' }, canActivate: [SignedInGuard, ParentRoleGuard] },
  { path: 'parent/enrolments', component: PageParentEnrolmentsComponent, data: { title: 'My Enrolments' }, canActivate: [SignedInGuard, ParentRoleGuard] },
  { path: 'parent/redeem-coupon', component: PageParentRedeemCreditCouponComponent, data: { title: 'Redeem Coupon' }, canActivate: [SignedInGuard, ParentRoleGuard] },
  // Student Role
  { path: 'student', component: PageStudentHomeComponent, data: { title: 'Student Portal' }, canActivate: [SignedInGuard, StudentRoleGuard] },
  // School Role
  { path: 'school', component: PageSchoolEnrolmentsComponent, data: { title: 'School Portal' }, canActivate: [SignedInGuard, SchoolRoleGuard] },
  { path: 'school/enrolments/roll/:requestDate', component: PageSchoolEnrolmentsRoleComponent, data: { title: 'Enrolment Roll' }, canActivate: [SignedInGuard, SchoolRoleGuard] },
  // Presenter Role
  { path: 'presenter', component: PagePresenterHomeComponent, data: { title: 'Presenter Portal' }, canActivate: [SignedInGuard, PresenterRoleGuard] },
  { path: 'presenter/program-detail/:sessionId', component: PagePresenterProgramDetailComponent, data: { title: 'Presenter Portal' }, canActivate: [SignedInGuard, PresenterRoleGuard] },
  { path: 'presenter/program-detail/by-zoom/:zoomId', component: PagePresenterProgramDetailComponent, data: { title: 'Presenter Portal' }, canActivate: [SignedInGuard, PresenterRoleGuard] },
  // Supervisor Role
  { path: 'supervisor', component: PageSupervisorHomeComponent, data: { title: 'Supervisor Portal' }, canActivate: [SignedInGuard, SupervisorRoleGuard] },
  { path: 'supervisor/program-detail/:sessionId', component: PageSupervisorProgramDetailComponent, data: { title: 'Program details' }, canActivate: [SignedInGuard, SupervisorRoleGuard] },
  { path: 'supervisor/program-detail/by-zoom/:zoomId', component: PageSupervisorProgramDetailComponent, data: { title: 'Program details' }, canActivate: [SignedInGuard, SupervisorRoleGuard] },
  // Admin Role
  { path: 'admin', component: PageAdminDashboardComponent, data: { title: 'Admin Portal' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/lookups/academic-focuses', component: PageAdminAcademicFocusesComponent, data: { title: 'Academic Focuses', adminMenuMode: 'common' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/lookups/presenters', component: PageAdminPresentersComponent, data: { title: 'Presenters', adminMenuMode: 'common' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/lookups/zoom-accounts', component: PageAdminZoomAccountsComponent, data: { title: 'Zoom Accounts', adminMenuMode: 'common' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/lookups/interactive-module-styles', component: PageAdminInteractiveModuleStylesComponent, data: { title: 'Interactive Program Styles', adminMenuMode: 'common' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/lookups/schools', component: PageAdminSchoolsComponent, data: { title: 'Schools', adminMenuMode: 'common' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/lookups/timetables', component: PageAdminTimetablesComponent, data: { title: 'Timetables', adminMenuMode: 'common' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/lookups/students', component: PageAdminStudentsComponent, data: { title: 'Students', adminMenuMode: 'common' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/lookups/parents', component: PageAdminParentsComponent, data: { title: 'Parents', adminMenuMode: 'common' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/lookups/locations', component: PageAdminLocationsComponent, data: { title: 'Locations', adminMenuMode: 'common' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/lookups/credit-coupons', component: PageAdminCreditCouponsComponent, data: { title: 'Credit Coupons', adminMenuMode: 'common' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/interactive-modules/session-calendar', component: PageInteractiveModuleSessionCalendarComponent, data: { title: 'Session Calendar', adminMenuMode: 'interactive' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/interactive-modules/all-enrolments', component: PageAdminAllEnrolmentsComponent, data: { title: 'All Enrolments', adminMenuMode: 'interactive' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/interactive-modules/sessions', component: PageAdminSessionListComponent, data: { title: 'Session List', adminMenuMode: 'interactive' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/interactive-module-templates', component: PageAdminInteractiveModuleTemplatesComponent, data: { title: 'Interactive Module Template', adminMenuMode: 'interactive' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/interactive-module-templates/new', component: PageAdminInteractiveModuleTemplateItemComponent, data: { title: 'Create Template', adminMenuMode: 'interactive' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/interactive-module-templates/edit/:id', component: PageAdminInteractiveModuleTemplateItemComponent, data: { title: 'Edit Template', adminMenuMode: 'interactive' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/interactive-module-templates/instances/create/:templateId', component: PageAdminCreateInstancesComponent, data: { title: 'Create Instances', adminMenuMode: 'interactive' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/interactive-module-templates/instances/:templateId', component: PageAdminInteractiveModuleInstancesComponent, data: { title: 'Instances', adminMenuMode: 'interactive' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/interactive-module-templates/instances/enrolments/:instanceId', component: PageAdminInteractiveModuleInstanceEnrolmentsComponent, data: { title: 'Instances', adminMenuMode: 'interactive' }, canActivate: [SignedInGuard, AdminRoleGuard] },
  { path: 'admin/users', component: PageAdminUserListComponent, data: { title: 'Users' }, canActivate: [SignedInGuard, AdminRoleGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

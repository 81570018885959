<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12 pt-2" *ngIf="!entities.length">
      <div class="card h-100">
        <div class="card-body mt-1 text-center">
          <i class="fa fa-exclamation-triangle fa-2x"></i>
          <h3 class="card-title">No sessions?</h3>
          <p class="card-text">You currently don't have any sessions assigned to you.</p>
        </div>
      </div>
    </div>

    <div class="col-sm-12 pt-2" *ngIf="entities.length">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Program</th>
            <th>Presenter</th>
            <th>Zoom Account</th>
            <th>Zoom Password</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let session of entities">
            <td>{{session.StartDateTime|date:'dd/MM/yyyy'}}</td>
            <td>{{session.StartDateTime|date:'hh:mm aa'}}</td>
            <td>{{session.InteractiveModule.Title}}</td>
            <td>{{session.Presenter.Firstname}} {{session.Presenter.Surname}}</td>
            <td>{{session.ZoomAccount?.AccountEmail}}</td>
            <td>{{session.ZoomAccount?.AccountPassword}}</td>
            <td>
              <a [routerLink]="['/supervisor/program-detail',session.Id]">Manage session</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>

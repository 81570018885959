import { LearningModuleModel, LearningModuleUnitResourceModel } from './';

export class LearningModuleUnitModel {
    Id: number;
    LearningModuleId: number;
    Idx: number;
    Title: string;
    Description: string;
    VideoFile: string;
    LearningModuleUnitResources: LearningModuleUnitResourceModel[]

    LearningModule: LearningModuleModel

    constructor() {
        this.Title = "";
        this.Description = "";
        this.VideoFile = "";
        this.LearningModuleUnitResources = [];
    }
}
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService, InteractiveModuleTemplateResourceService, AWSS3Service } from '../../services';
import { InteractiveModuleTemplateModel, InteractiveModuleTemplateResourceModel } from '../../models';
import { FormFunctions } from '../../functions';
import { Validations } from '../../constants'

@Component({
    selector: 'modal-add-template-resource',
    templateUrl: './modal-add-template-resource.component.html'
})
export class ModalAddTemplateResourceComponent {
    @Input() template: InteractiveModuleTemplateModel;
    presenters: any[] = [];
    request: InteractiveModuleTemplateResourceModel = new InteractiveModuleTemplateResourceModel();
    selectedFiles: FileList;
    loading: boolean = true;
    showSuccess: boolean = false;
    showError: boolean = false;
    processing: boolean = false;

    constructor(public activeModal: NgbActiveModal, public stateService: StateService, public validations: Validations, public formFunctions: FormFunctions, public interactiveModuleTemplateResourceService: InteractiveModuleTemplateResourceService, public aWSS3Service: AWSS3Service) {
        let me = this;
    }

    ngOnInit() {
        let me = this;

        me.request.InteractiveModuleTemplateId = me.template.Id;
    }
    Create(form, e) {
        let me = this;

        if (this.formFunctions.isFormValid(form, e)) {

            me.processing = true;
            me.showError = false;
            me.showSuccess = false;

            const file = this.selectedFiles.item(0);
            me.request.ResourceFilename = file.name;
            me.aWSS3Service.UploadFile(me.template.UrlName, file, file.name).on('httpUploadProgress', function (evt) {
                // evt.loaded is bytes uploaded
                // evt.total is total bytes size
            }).send(function (err, data) {
                if (err) {
                    console.log('There was an error uploading your file: ', err);
                    me.showError = true;
                    me.processing = false;
                    return false;
                }
                else {
                    me.interactiveModuleTemplateResourceService.Create(me.request).subscribe(data => {
                        me.showSuccess = true;
                        me.processing = false;
                    }, err => {
                        me.showError = true;
                        me.processing = false;
                    });

                    return true;
                }
            });

        }
    }
    selectFile(event) {
        this.selectedFiles = event.target.files;
    }
}
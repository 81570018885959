import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-basic-message',
  template: `
    <div class="modal-header bg-dark text-white">
      <h4 class="modal-title">{{title}}</h4>
      <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{content}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div>
  `
})
export class ModalBasicMessageComponent {
  @Input() title;
  @Input() content;

  constructor(public activeModal: NgbActiveModal) { }
}
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { StateService, AuthService, RoleService } from '../../services';
import { WebsilkTextField } from '../../components';
import { Authenticate, RoleModel } from '../../models';
import { FormFunctions } from '../../functions';

@Component({
    selector: 'page-login',
    templateUrl: './page-login.component.html',
})
export class PageLoginComponent implements OnInit {
    request: Authenticate = new Authenticate();
    status: string = "";
    processing: boolean = false;

    constructor(public router: Router, private activatedRoute: ActivatedRoute, public stateService: StateService, public authService: AuthService, public formFunctions: FormFunctions, public roleService: RoleService) {
        var me = this;
        me.status = me.activatedRoute.snapshot.params.status;
    }

    ngOnInit() {
        var me = this;
    }
    loginCallback(userId) {
        var me = this;
        me.processing = true;
        me.roleService.FindMyRoles().subscribe(data => {
            me.processing = false;
            if (data.length) {
                let role: RoleModel = data[0];
                let roleName = role.Name.toLowerCase();
                me.navigateHome(roleName);
            }
        });
    }
    navigateHome(roleName) {
        let me = this;
        switch (roleName) {
            case "administrators":
                me.router.navigate(['/admin']);
                break;
            case "student":
                me.router.navigate(['/student']);
                break;
            case "parent":
                me.router.navigate(['/parent']);
                break;
            case "presenter":
                me.router.navigate(['/presenter']);
                break;
            case "school":
                me.router.navigate(['/school']);
                break;
            case "supervisor":
                me.router.navigate(['/supervisor']);
                break;
        }
    }
}

<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-3">
      <div class="form-group">
        <label class="control-label">Student Name</label>
        <input type="text" class="form-control" [(ngModel)]="filterName" (keyup)="FilterEntities()">
      </div>
    </div>
    <div class="col-6"></div>
    <div class="col-3 text-right">
      <!-- <button class="btn btn-dark" (click)="Create()"><i class="fa fa-plus"></i> Create School</button> -->
    </div>

  </div>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12">

      <div class="card mt-3">
        <div class="card-header bg-dark text-light">
          <h5 class="card-title">List</h5>
        </div>
        <div class="card-body bg-light text-dark">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Firstname</th>
                <th>Surname</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="filteredEntities.length==0">
                <td class="text-center" colspan="3">
                  <h5>No Students Located</h5>
                  <p>Please use filters first</p>
                </td>
              </tr>
              <tr *ngFor="let entity of filteredEntities">
                <td>{{entity.User?.FirstName}}</td>
                <td>{{entity.User?.Surname}}</td>
                <td>
                  <button class="btn btn-sm" (click)="Edit(entity)"><i class="fa fa-edit"></i> Edit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { forkJoin } from 'rxjs';

import { StateService } from '../../../services';

import { InteractiveModuleSessionModel, UserModel, InteractiveModuleEnrolmentModel, InteractiveModuleModel, InteractiveModuleEnrolmentStudentModel } from '../../../models';

@Component({
    selector: 'page-supervisor-program-detail',
    styleUrls: ['page-supervisor-program-detail.component.scss'],
    templateUrl: './page-supervisor-program-detail.component.html',
})
export class PageSupervisorProgramDetailComponent implements OnInit {
    loading: boolean = true;
    sessionId: number;
    zoomId: string;
    currentUser: UserModel;

    constructor(private activatedRoute: ActivatedRoute, public stateService: StateService) {
        var me = this;

        me.sessionId = me.activatedRoute.snapshot.params.sessionId;
        me.zoomId = me.activatedRoute.snapshot.params.zoomId;

    }

    ngOnInit() {
        var me = this;
    }


}

import { Injectable, EventEmitter } from '@angular/core';
import { UserModel, InteractiveInstanceFilterRequestModel } from '../models';
import { } from '../services';

@Injectable()
export class StateService {

    constructor() {
    }

    // Page Title
    public pageTitle: string = '';
    public pageTitle$: any = new EventEmitter();
    changePageTitle(value) {
        this.pageTitle = value;
        this.pageTitle$.emit(value);
    }
    // Show Banner
    public showBanner: boolean = false;
    public showBanner$: any = new EventEmitter();
    changeShowBanner(value) {
        this.showBanner = value;
        this.showBanner$.emit(value);
    }

    // User Model
    public userModel: UserModel = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    public userModel$: any = new EventEmitter<UserModel>();
    changeUserModel(value) {
        this.userModel = value;
        // Store in local storage for continued login
        if (value) {
            localStorage.setItem('user', JSON.stringify(value));
            var now = new Date();
            var expires = new Date(now.getTime() + 30 * 60000);
            this.changeUserModelExpires(expires);
        } else {
            localStorage.removeItem('user');
            this.userModel = null;
            this.changeUserModelExpires(null);
        }

        this.userModel$.emit(value);
    }

    // User Model
    public filterRequestModel: InteractiveInstanceFilterRequestModel = localStorage.getItem('filterRequest') ? JSON.parse(localStorage.getItem('filterRequest')) : null;
    public filterRequestModel$: any = new EventEmitter<InteractiveInstanceFilterRequestModel>();
    changeFilterRequestModel(value) {
        this.filterRequestModel = value;
        // Store in local storage for continued login
        if (value) {
            localStorage.setItem('filterRequest', JSON.stringify(value));
        } else {
            localStorage.removeItem('filterRequest');
            this.filterRequestModel = null;
        }

        this.filterRequestModel$.emit(value);
    }

    // User Model expiry date
    public userModelExpires: any = localStorage.getItem('userExpires') ? JSON.parse(localStorage.getItem('userExpires')) : null;
    public userModelExpires$: any = new EventEmitter();
    changeUserModelExpires(value) {
        this.userModelExpires = value;
        // Store in local storage for continued login
        if (value) {
            localStorage.setItem('userExpires', JSON.stringify(value));
        } else {
            localStorage.removeItem('userExpires');
        }

        this.userModelExpires$.emit(value);
    }
    userModelValid() {
        if (this.userModelExpires != null) {
            var StartTime = new Date();
            var EndTime = new Date(this.userModelExpires);
            var duration = EndTime.valueOf() - StartTime.valueOf();
            if (duration > 0) {
                // manually update so it doesn't emit to subscribers and cause a loop
                var now = new Date();
                var expires = new Date(now.getTime() + 30 * 60000);
                localStorage.setItem('userExpires', JSON.stringify(expires));
                return true;
            } else {
                this.changeUserModelExpires(null);
                this.changeUserModel(null);
            }
        } else {
            if (this.userModel != null) {
                var now = new Date();
                var expires = new Date(now.getTime() + 30 * 60000);
                this.changeUserModelExpires(expires);
            }
        }
    }
}
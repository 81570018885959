import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import {
    InteractiveModuleModel,
    InteractiveInstanceRequest,
    InteractiveModuleEnrolmentModel,
    InteractiveInstanceFilterRequestModel,
    InteractiveModuleFutureProjectionModel,
    InteractiveModuleFutureProjectionGroupedModel,
    GenericResponseModel,
    StudentModel
} from '../models';
import { AppConfig } from '../config';

@Injectable()
export class InteractiveModuleService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindAll(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleModel[]>(this.appConfig.getConfig('apiUrl') + '/interactive-modules', { withCredentials: true, headers: headers });
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleModel>(this.appConfig.getConfig('apiUrl') + '/interactive-modules/' + id, { withCredentials: true, headers: headers });
    }
    FindByIdPublic(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleModel>(this.appConfig.getConfig('apiUrl') + '/interactive-modules/public/' + id, { withCredentials: true, headers: headers });
    }
    FindFuture(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<any[]>(this.appConfig.getConfig('apiUrl') + '/interactive-modules/find-future', { withCredentials: true, headers: headers });
    }
    FindFutureInstancesAll(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleModel[]>(this.appConfig.getConfig('apiUrl') + '/interactive-modules/find-future-instances-all', { withCredentials: true, headers: headers });
    }
    FindFutureInstances(request: InteractiveInstanceFilterRequestModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<InteractiveModuleFutureProjectionModel[]>(this.appConfig.getConfig('apiUrl') + '/interactive-modules/find-future-instances', params, { withCredentials: true, headers: headers });
    }
    FindFutureInstancesGrouped(request: InteractiveInstanceFilterRequestModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<InteractiveModuleFutureProjectionGroupedModel[]>(this.appConfig.getConfig('apiUrl') + '/interactive-modules/find-future-instances-grouped', params, { withCredentials: true, headers: headers });
    }
    FindFutureInstancesGroupedByTemplate(templateId: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleFutureProjectionGroupedModel[]>(this.appConfig.getConfig('apiUrl') + '/interactive-modules/find-future-instances-grouped-by-template/' + templateId, { withCredentials: true, headers: headers });
    }
    FindByTemplateUrl(url: string): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleModel[]>(this.appConfig.getConfig('apiUrl') + '/interactive-modules/find-by-template-url/' + url, { withCredentials: true, headers: headers });
    }
    FindByTemplateId(templateId: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleModel[]>(this.appConfig.getConfig('apiUrl') + '/interactive-modules/find-by-template-id/' + templateId, { withCredentials: true, headers: headers });
    }
    Create(item: InteractiveModuleModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<InteractiveModuleModel>(this.appConfig.getConfig('apiUrl') + '/interactive-modules', params, { withCredentials: true, headers: headers });
    }
    CreateFromTemplate(item: InteractiveInstanceRequest) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<InteractiveModuleModel>(this.appConfig.getConfig('apiUrl') + '/interactive-modules/create-from-template', params, { withCredentials: true, headers: headers });
    }
    Edit(item: InteractiveModuleModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<InteractiveModuleModel>(this.appConfig.getConfig('apiUrl') + '/interactive-modules/' + item.Id, params, { withCredentials: true, headers: headers });
    }
    CheckEnrolled(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleEnrolmentModel>(this.appConfig.getConfig('apiUrl') + '/interactive-modules/check-enrolled/' + id, { withCredentials: true, headers: headers });
    }
    MyChildrenEnrolled(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<StudentModel[]>(this.appConfig.getConfig('apiUrl') + '/interactive-modules/my-children-enrolled/' + id, { withCredentials: true, headers: headers });
    }
    Delete(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.delete<GenericResponseModel>(this.appConfig.getConfig('apiUrl') + '/interactive-modules/' + id, { withCredentials: true, headers: headers });
    }
}
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService, AuthService, RoleService, SchoolService } from '../../services';
import { UserModel, RoleModel, SchoolModel, CreateUserRequestModel } from '../../models';
import { FormFunctions, CustomDateParserFormatter } from '../../functions';
import { Validations } from '../../constants'
import { forkJoin } from 'rxjs';

@Component({
    selector: 'modal-edit-user',
    templateUrl: './modal-edit-user.component.html'
})
export class ModalEditUserComponent {
    entity: CreateUserRequestModel = new CreateUserRequestModel();
    loading: boolean = true;
    showSuccess: boolean = false;
    showError: boolean = false;
    processing: boolean = false;
    roles: RoleModel[] = [];
    schools: SchoolModel[] = [];

    constructor(public activeModal: NgbActiveModal, public stateService: StateService, public validations: Validations, public formFunctions: FormFunctions, public authService: AuthService, public roleService: RoleService, public schoolService: SchoolService) {
        let me = this;

        forkJoin(
            me.roleService.FindAll(),
            me.schoolService.FindAll()
        ).subscribe(([data1, data2]) => {
            me.roles = data1;
            me.schools = data2;
        });
    }

    ngOnInit() {

    }

    UpdateDetails(form, e) {
        let me = this;

        if (this.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            me.authService.CreateBasic(me.entity).subscribe(data => {
                me.showSuccess = true;
                me.processing = false;
            }, err => {
                me.processing = false;
                me.showError = true;
            });
        }
    }
}
import { Component, Input, OnInit } from '@angular/core';

import { AcademicFocusModel } from '../../models';

@Component({
    selector: 'in-out-school-pill',
    styleUrls: ['./in-out-school-pill.component.scss'],
    template:
        `
    <span class="badge in-school-pill ml-2" [class.light-mode]="LightMode" *ngIf="InSchool">In School</span>
    <span class="badge in-school-pill ml-2" [class.light-mode]="LightMode" *ngIf="!InSchool">Outside School</span>
  `
})
export class InOutSchoolPill {
    @Input() InSchool: boolean;
    @Input() LightMode: boolean;

    constructor() { }
}




<div class="modal-header bg-dark text-white">
  <h4 class="modal-title">Add User Role</h4>
  <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Adding role for <strong>{{user?.FirstName}} {{user?.Surname}}</strong></p>
  <form #thisForm="ngForm" [class.d-none]="success">
    <websilk-select-field id="selRoles" name="selRoles" [bindItems]="roles" [(ngModel)]="request.RoleId" labelText="Role" idKey="Id" descKey="Name" required="true" displayInline="false"></websilk-select-field>
  </form>
  <div class="alert alert-success mt-2" *ngIf="success">
    <p>Role successfully added.</p>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-gateways" (click)="Update(thisForm,$event)" *ngIf="!processing && !success">Update</button>
  <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')" *ngIf="!processing">Close</button>
  <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
</div>

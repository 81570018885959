import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AppConfig } from '../config';
declare var gtag: any;
declare var dataLayer: any;
declare var fbq: any;

@Injectable()
export class AnalyticsService {

    GA_MEASUREMENT_ID: string = dataLayer[1][1] || '';

    constructor() {
    }

    routeChange(url: string) {
        // gtag('config', this.GA_MEASUREMENT_ID, { 'page_path': url });
        fbq('track', 'PageView');
    }

    eventLog(category: string, action: string, label: string, value: string = '') {

        gtag && gtag('event', action || '', {
            'send_to': this.GA_MEASUREMENT_ID,
            'event_category': category || '',
            'event_label': label || '',
            'value': value
        });

    }

}

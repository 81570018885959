<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12 pb-3">
      <a class="text-dark" [routerLink]="['/admin/interactive-module-templates']"><i class="fa fa-arrow-left"></i> Back to list</a>
    </div>

    <div class="col-sm-12">

      <div class="card">
        <div class="card-header bg-dark text-light">
          <h5 class="card-title">Create Instances</h5>
        </div>
        <div class="card-body">

          <form #thisForm="ngForm">

            <div class="alert alert-success" *ngIf="showSuccess">
              Your new instances have been created.
            </div>

            <div class="alert alert-danger" *ngIf="showError">
              There was a problem processing your request, please contact your systems administrator
            </div>

            <div *ngIf="!showSuccess && !showError">
              <div class="card">
                <div class="card-header">
                  <strong class="card-title">Instance details</strong>
                </div>
                <div class="card-body">

                  <p>Repeating options (i.e. running once a week for 4 weeks in a block would be repeat every "7" days for 4 iterations)</p>
                  <websilk-text-field id="txtTitle" name="txtTitle" [(ngModel)]="request.Title" labelText="Title" placeholderText="Title" required="true" displayInline="false"></websilk-text-field>
                  <websilk-select-field id="selSupervisor" name="selSupervisor" [bindItems]="supervisors" [(ngModel)]="request.SupervisorUserId" labelText="Supervisor" idKey="id" descKey="name" required="true" displayInline="false" errorText="Supervisor is required"></websilk-select-field>
                  <div class="form-group" *ngIf="!entity.IsOnsite">
                    <label class="control-label">Zoom Account</label>
                    <select class="form-control" name="selZoomAccount" id="selZoomAccount" [(ngModel)]="request.ZoomAccountId">
                      <option value="">Select zoom account</option>
                      <option *ngFor="let zoomAccount of zoomAccounts" [value]="zoomAccount.Id">{{zoomAccount.Title}} ({{zoomAccount.Email}})</option>
                    </select>
                  </div>
                  <div class="form-group" *ngIf="entity.IsOnsite">
                    <div class="alert alert-info text-center">
                      This is an OnSite program
                    </div>
                  </div>
                  <websilk-text-field id="txtRepeatEvery" name="txtRepeatEvery" [(ngModel)]="request.RepeatEvery" labelText="Repeat Every" trailingText="days" placeholderText="Days" required="true" pattern="^\d+$" displayInline="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-3" trailingTextInlineClass="col-sm-3" errorText="Required, 0 is default"></websilk-text-field>
                  <websilk-text-field id="txtRepeatFor" name="txtRepeatFor" [(ngModel)]="request.RepeatFor" labelText="Repeat" trailingText="time" placeholderText="Frequency" required="true" pattern="^\d+$" displayInline="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-3" trailingTextInlineClass="col-sm-3" errorText="Required, 0 is default"></websilk-text-field>
                  <div class="form-group row">
                    <label class="col-form-label col-sm-3">
                      Repeat For Day?
                    </label>
                    <div class="col-sm-3">
                      <input class="form-check-input" type="checkbox" [(ngModel)]="request.AreRepeatSessions" id="chkRepeatSessions" name="chkRepeatSessions">
                    </div>
                  </div>

                  <div class="form-group row" *ngIf="!entity.IsOnsite">
                    <label class="col-form-label col-sm-3">
                      Create Zoom Sessions Now?
                    </label>
                    <div class="col-sm-3">
                      <input class="form-check-input" type="checkbox" [(ngModel)]="request.CreateExternalSessions" id="chkCreateExternalSessions" name="chkCreateExternalSessions">
                    </div>
                  </div>

                </div>
              </div>

              <!-- Primary Sessions -->
              <div class="card mt-3">
                <div class="card-header">
                  <strong class="card-title">Session details</strong>
                </div>
                <div class="card-body">

                  <div class="row header">
                    <div class="col-3">Date</div>
                    <div class="col-2">Time</div>
                    <div class="col-2">Duration</div>
                    <div class="col-5">Presenter</div>
                  </div>

                  <div class="row body" *ngFor="let session of sessions;let i = index">
                    <div class="col-3">
                      <websilk-date-field id="dtSessionDate_{{i}}" name="dtSessionDate_{{i}}" [(ngModel)]="session.SessionDate" labelText="" required="true" displayInline="false" errorText="Session Date is required"></websilk-date-field>
                    </div>
                    <div class="col-2">
                      <websilk-text-field id="txtSessionTime_{{i}}" name="txtSessionTime_{{i}}" [(ngModel)]="session.SessionTime" labelText="" required="true" pattern="^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" displayInline="false" errorText="Session Time is required"></websilk-text-field>
                    </div>
                    <div class="col-2">
                      <websilk-text-field id="txtSessionDurationMinutes_{{i}}" name="txtSessionDurationMinutes_{{i}}" placeholderText="Minutes" [(ngModel)]="session.SessionDurationMinutes" labelText="" required="true" pattern="^\d+$" displayInline="false" errorText="Duration is required"></websilk-text-field>
                    </div>
                    <div class="col-5">
                      <websilk-select-field id="selPresenter_{{i}}" name="selPresenter_{{i}}" [bindItems]="presenters" [(ngModel)]="session.PresenterId" labelText="" idKey="id" descKey="name" required="true" displayInline="false" errorText="Presenter is required"></websilk-select-field>
                    </div>
                  </div>

                </div>
              </div>
              <!-- Repeat Sessions (i.e. AM and PM) -->

              <div class="card mt-3" *ngIf="request.AreRepeatSessions">
                <div class="card-header">
                  <strong class="card-title">Repeat session details (i.e. PM times)</strong>
                </div>
                <div class="card-body">

                  <div class="row header">
                    <div class="col-3">Date</div>
                    <div class="col-2">Time</div>
                    <div class="col-2">Duration</div>
                    <div class="col-5">Presenter</div>
                  </div>
                  <div class="row body" *ngFor="let session of repeatSessions;let i = index">
                    <div class="col-3">
                      <websilk-date-field id="dtRepeatSessionDate_{{i}}" name="dtRepeatSessionDate_{{i}}" [(ngModel)]="session.SessionDate" labelText="" required="true" displayInline="false" errorText="Session Date is required"></websilk-date-field>
                    </div>
                    <div class="col-2">
                      <websilk-text-field id="txtRepeatSessionTime_{{i}}" name="txtRepeatSessionTime_{{i}}" [(ngModel)]="session.SessionTime" labelText="" required="true" pattern="^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" displayInline="false" errorText="Session Time is required"></websilk-text-field>
                    </div>
                    <div class="col-2">
                      <websilk-text-field id="txtRepeatSessionDurationMinutes_{{i}}" name="txtSessionDurationMinutes_{{i}}" placeholderText="Minutes" [(ngModel)]="session.SessionDurationMinutes" labelText="" required="true" pattern="^\d+$" displayInline="false" errorText="Duration is required"></websilk-text-field>
                    </div>
                    <div class="col-5">
                      <websilk-select-field id="selRepeatPresenter_{{i}}" name="selRepeatPresenter_{{i}}" [bindItems]="presenters" [(ngModel)]="session.PresenterId" labelText="" idKey="id" descKey="name" required="true" displayInline="false" errorText="Presenter is required"></websilk-select-field>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </form>


        </div>

        <div class="card-footer">
          <div *ngIf="!showSuccess && !showError">
            <button type="button" class="btn btn-gateways" (click)="Create(thisForm,$event)" *ngIf="!processing">Create</button>
            <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
          </div>
          <div *ngIf="showSuccess || showError">
            <a class="btn btn-outline-dark" [routerLink]="['/admin/interactive-module-templates']"><i class="fa fa-arrow-left"></i> Back to list</a>
          </div>
        </div>

      </div>


    </div>
  </div>
</div>

<div class="container" *ngIf="loading">
  <websilk-loading displayText="Loading modules... Please wait"></websilk-loading>
</div>
<div class="container" *ngIf="!loading">

  <div class="row">
    <div class="col-sm-3">
      <websilk-date-field id="dtStartDate" name="dtStartDate" [(ngModel)]="startDate" labelText="Start Date" required="true" displayInline="false"></websilk-date-field>
    </div>
    <div class="col-sm-3">
      <websilk-date-field id="dtEndDate" name="dtEndDate" [(ngModel)]="endDate" labelText="End Date" required="true" displayInline="false"></websilk-date-field>
    </div>
    <div class="col-sm-2">
      <div class="form-group">
        <label class="form-label">&nbsp;</label>
        <div>
          <button class="btn btn-dark" (click)="LoadSessions()">Search</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-card-grey" *ngIf="!loading">
  <div class="container">
    <div class="row pt-2 pb-2">
      <div class="col-sm-5 text-right">
        <div class="mt-1">
          <strong>Filters:</strong>
        </div>
      </div>
      <div class="col-sm-2">
        <div>
          <select id="selDayOfWeek" name="selDayOfWeek" class="form-control form-control-small" [(ngModel)]="filter.DayOfWeek">
            <option value="">Any Day</option>
            <option value="Sunday">Sunday</option>
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
          </select>
        </div>
      </div>
      <div class="col-sm-2">
        <div>
          <select id="selTimeOfDay" name="selTimeOfDay" class="form-control form-control-small" [(ngModel)]="filter.TimeOfDay">
            <option value="">Any Time</option>
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
        </div>
      </div>
      <div class="col-sm-3">
        <div>
          <select id="selProgram" name="selProgram" class="form-control form-control-small" [(ngModel)]="filter.TemplateId">
            <option value="0">All Programs</option>
            <option *ngFor="let template of templates" value="{{template.Id}}">{{template.Title}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-secondary" *ngIf="!loading">
  <div class="container">
    <div class="row pt-2 pb-2">

      <div class="col-sm-12 text-right">
        <strong class="d-inline-block mr-2 text-white">Actions:</strong>
        <button class="btn btn-sm btn-dark mr-2" (click)="CreateZoomSessions()">Create Zoom Sessions</button>
        <button class="btn btn-sm btn-dark" (click)="AssignSupervisor()">Assign Supervisor</button>
      </div>

    </div>
  </div>
</div>

<div class="container" *ngIf="!loading">
  <div class="row">
    <div class="col-sm-12">
      <table class="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th>Title</th>
            <th>Day</th>
            <th>Start Date/Time</th>
            <th>Finish Date/Time</th>
            <th>Presenter</th>
            <th>Supervisor</th>
            <th class="text-center">Zoom Session Created</th>
          </tr>
        </thead>
        <tbody *ngIf="FilteredSessions().length==0">
          <tr>
            <td colspan="8" class="text-center">
              <h5>No sessions found</h5>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="FilteredSessions().length>0">
          <tr *ngFor="let session of FilteredSessions()">
            <td class="text-center">
              <div class="form-check">
                <input type="checkbox" (change)="CheckChanged($event,session.Id)" [checked]="IsChecked(session.Id)">
              </div>
            </td>
            <td>{{session.InteractiveModule.Title}}</td>
            <td>{{session.StartDateTime|date:'EEEE'}}</td>
            <td>{{session.StartDateTime|date:'dd/MM/yyyy HH:mm aa'}}</td>
            <td>{{session.FinishDateTime|date:'dd/MM/yyyy HH:mm aa'}}</td>
            <td>{{session.Presenter.Firstname}} {{session.Presenter.Surname}}</td>
            <td>{{session.SupervisorUser?.FirstName}} {{session.SupervisorUser?.Surname}}</td>
            <td class="text-center" [class.bg-success]="session.ExternalSessionCreated">{{session.ExternalSessionCreated?'Yes':'No'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ngb-tabset [destroyOnHide]="false" *ngIf="displayMode=='both'">
  <ngb-tab>
    <ng-template ngbTabTitle>Username / Password</ng-template>
    <ng-template ngbTabContent>
      <form #thisForm="ngForm">
        <websilk-text-field id="txtUsername" name="txtUsername" [(ngModel)]="request.UserName" displayInline="false" labelText="Username" required="true" pattern=""></websilk-text-field>
        <websilk-text-field id="txtPassword" name="txtPassword" [(ngModel)]="request.Password" displayInline="false" labelText="Password" required="true" pattern="" fieldType="password"></websilk-text-field>

        <div>
          <button type="button" class="btn btn-gateways" *ngIf="!processing" (click)="PerformLogin(thisForm,$event)">Login</button>
          <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
          <a [routerLink]="['/reset-password']" class="btn btn-link float-right">
            Forgot Your Password?
          </a>
        </div>

        <div class="alert alert-danger mt-2" *ngIf="error!=''">{{error}}</div>
      </form>
    </ng-template>
  </ngb-tab>


  <ngb-tab>
    <ng-template ngbTabTitle>Logon Code</ng-template>
    <ng-template ngbTabContent>
      <form #thisFormUnique="ngForm">
        <websilk-text-field id="txtUniqueId" name="txtUniqueId" [(ngModel)]="requestUnique.UniqueId" displayInline="false" labelText="Logon Code" required="true" pattern=""></websilk-text-field>

        <div>
          <button type="button" class="btn btn-gateways" *ngIf="!processing" (click)="PerformLoginUnique(thisFormUnique,$event)">Login</button>
          <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
        </div>

        <div class="alert alert-danger mt-2" *ngIf="error!=''">{{error}}</div>
      </form>
    </ng-template>
  </ngb-tab>

</ngb-tabset>

<div *ngIf="displayMode=='email'">
  <form #thisForm="ngForm">
    <websilk-text-field id="txtUsername" name="txtUsername" [(ngModel)]="request.UserName" displayInline="false" labelText="Username" required="true" pattern=""></websilk-text-field>
    <websilk-text-field id="txtPassword" name="txtPassword" [(ngModel)]="request.Password" displayInline="false" labelText="Password" required="true" pattern="" fieldType="password"></websilk-text-field>

    <div>
      <button type="button" class="btn btn-gateways" *ngIf="!processing" (click)="PerformLogin(thisForm,$event)">Login</button>
      <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
      <a [routerLink]="['/reset-password']" class="btn btn-link float-right">
        Forgot Your Password?
      </a>
    </div>

    <div class="alert alert-danger mt-2" *ngIf="error!=''">{{error}}</div>
  </form>
</div>

<div *ngIf="displayMode=='token'">
  <form #thisFormUnique="ngForm">
    <websilk-text-field id="txtUniqueId" name="txtUniqueId" [(ngModel)]="requestUnique.UniqueId" displayInline="false" labelText="Unique Token" required="true" pattern=""></websilk-text-field>

    <div>
      <button type="button" class="btn btn-gateways" *ngIf="!processing" (click)="PerformLoginUnique(thisFormUnique,$event)">Login</button>
      <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
      <a [routerLink]="['/reset-password']" class="btn btn-link float-right">
        Forgot Your Password?
      </a>
    </div>

    <div class="alert alert-danger mt-2" *ngIf="error!=''">{{error}}</div>
  </form>
</div>

export class InteractiveModuleResourceModel {
    Id: number;
    Title: string;
    Description: string;
    ResourceFilename: string;

    constructor() {
        this.Title = "";
        this.Description = "";
        this.ResourceFilename = "";
    }
}
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { forkJoin } from "rxjs";

import {
  StateService,
  InteractiveModuleService,
  InteractiveModuleEnrolmentService,
  StudentService,
} from "../../../../services";
import { FormFunctions } from "../../../../functions";

import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ModalInteractiveEnrolmentListComponent } from "../../../../components";

import {
  InteractiveModuleModel,
  InteractiveModuleEnrolmentModel,
  InteractiveModuleEnrolmentStudentModel,
  StudentModel,
  SearchStudentRequestModel,
  EnrolStudentRequest,
} from "../../../../models";
import { ModalAdminAddChildComponent } from "src/app/components";

@Component({
  selector: "page-admin-interactive-module-instance-enrolments",
  templateUrl:
    "./page-admin-interactive-module-instance-enrolments.component.html",
})
export class PageAdminInteractiveModuleInstanceEnrolmentsComponent
  implements OnInit
{
  loading: boolean = true;
  searching: boolean = false;
  instanceId: number;
  instance: InteractiveModuleModel;
  enrolments: InteractiveModuleEnrolmentModel[] = [];
  enrolmentStudents: InteractiveModuleEnrolmentStudentModel[] = [];
  searchStudentResults: StudentModel[] = [];
  searchStudentRequest: SearchStudentRequestModel =
    new SearchStudentRequestModel();

  constructor(
    public stateService: StateService,
    private formFunctions: FormFunctions,
    public interactiveModuleService: InteractiveModuleService,
    private interactiveModuleEnrolmentService: InteractiveModuleEnrolmentService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    public studentService: StudentService
  ) {
    var me = this;

    me.instanceId = me.activatedRoute.snapshot.params.instanceId;

    forkJoin(
      me.interactiveModuleService.FindById(me.instanceId),
      me.interactiveModuleEnrolmentService.FindByInstance(me.instanceId)
    ).subscribe(([data1, data2]) => {
      me.instance = data1;
      me.enrolments = data2;
      me.enrolmentStudents = me.enrolments
        .map((item) => item.InteractiveModuleEnrolmentStudents)
        .reduce(
          (prev: InteractiveModuleEnrolmentStudentModel[], curr) =>
            prev.concat(curr),
          []
        );
      me.loading = false;
    });
  }

  ngOnInit() {
    var me = this;
  }

  RefreshEnrolments() {
    var me = this;
    me.interactiveModuleEnrolmentService
      .FindByInstance(me.instanceId)
      .subscribe((data) => {
        me.enrolments = data;
        me.enrolmentStudents = me.enrolments
          .map((item) => item.InteractiveModuleEnrolmentStudents)
          .reduce(
            (prev: InteractiveModuleEnrolmentStudentModel[], curr) =>
              prev.concat(curr),
            []
          );
      });
  }
  GetEnrolmentCount(instance: InteractiveModuleModel) {
    let me = this;

    let total = me.enrolments
      .map((item) => item.InteractiveModuleEnrolmentStudents)
      .reduce((prev, curr) => prev + curr.length, 0);
    return total;
  }
  SearchStudents() {
    let me = this;
    me.searching = true;
    me.studentService.Search(me.searchStudentRequest).subscribe((data) => {
      me.searchStudentResults = data;
      me.searching = false;
    });
  }
  ClearSearch() {
    let me = this;
    me.searchStudentRequest.Firstname = "";
    me.searchStudentRequest.Surname = "";
    me.searchStudentRequest.DateOfBirth = null;
  }
  EnrolStudent(student: StudentModel) {
    let me = this;

    let request = new EnrolStudentRequest();
    request.StudentIds = [student.Id];
    request.ParentId = student.ParentId;
    request.ModuleId = me.instance.Id;
    request.OnAccount = false;
    request.ManualEnrolment = true;

    me.interactiveModuleEnrolmentService
      .PrepareEnrolStudent(request)
      .subscribe((data) => {
        me.RefreshEnrolments();
        me.ClearSearch();
        me.searchStudentResults = [];
      });
  }

  AddStudentModal() {
    let me = this;
    const modalRef = me.modalService.open(ModalAdminAddChildComponent, {
      scrollable: true,
      size: "lg",
    });
    modalRef.componentInstance.instanceId = me.instanceId;

    modalRef.result.then((result: any) => {
      if (result.success) {
        me.EnrolStudent(result.record);
      }
    });
  }

  DeleteEnrolment(studentEnrolment: InteractiveModuleEnrolmentStudentModel) {
    let me = this;
    if (confirm("Are you sure you want to remove this enrolment?")) {
      me.interactiveModuleEnrolmentService
        .DeleteStudent(studentEnrolment.Id)
        .subscribe(
          (data) => {
            alert("Enrolment removed");
            me.RefreshEnrolments();
          },
          (err) => {
            alert("There was a problem");
          }
        );
    }
  }

  ExportInvoice(enrolment: InteractiveModuleEnrolmentStudentModel) {
    let me = this;

    me.interactiveModuleEnrolmentService
      .ExportInvoice(enrolment.InteractiveModuleEnrolmentId)
      .subscribe(
        (data) => {
          var url = URL.createObjectURL(data);
          var link = document.createElement("a");
          link.setAttribute("href", url);
          link.setAttribute(
            "download",
            "invoice_li_" + enrolment.InteractiveModuleEnrolmentId + ".pdf"
          );
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        (err) => {
          alert("There was an issue exporting this invoice.");
        }
      );
  }
}

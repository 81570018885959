import { Component, Input, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'websilk-date-field',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WebsilkDateField),
      multi: true
    }
  ],
  template:
    `
        <div *ngIf="displayInline" class="form-group row">
        <label for="{{id}}" class="col-form-label {{inlineLabelClass}}" *ngIf="labelText!=''">{{labelText}}</label>
        <div class="{{inlineFieldClass}}">
          <div class="input-group">
            <input class="form-control" placeholder="dd/mm/yyyy" name="dp" [(ngModel)]="value" ngbDatepicker #d="ngbDatepicker" required="{{required}}">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                <i class="fa fa-calendar"></i>
              </button>
            </div>
            <span *ngIf="errorText!=''" class="error-label w-100">{{errorText}}</span>
            <span *ngIf="errorText=='' && required" class="error-label w-100">{{labelText}} is required</span>
          </div>
      
        </div>
      </div>
      
      <div *ngIf="!displayInline" class="form-group">
        <label for="{{id}}" class="form-label" *ngIf="labelText!=''">{{labelText}}</label>
        <div class="">
          <div class="input-group">
            <input class="form-control" placeholder="dd/mm/yyyy" name="dp" [(ngModel)]="value" ngbDatepicker #d="ngbDatepicker" required="{{required}}">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                <i class="fa fa-calendar"></i>
              </button>
            </div>
            <span *ngIf="errorText!=''" class="error-label w-100">{{errorText}}</span>
            <span *ngIf="errorText=='' && required" class="error-label w-100">{{labelText}} is required</span>
            </div>
        </div>
      </div>
        `
})
export class WebsilkDateField implements ControlValueAccessor {
  @HostBinding('attr.id')
  externalId = '';

  @Input() id: string;
  @Input('value') _value;
  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  @Input() inlineLabelClass: string = 'col-md-3 text-md-right';
  @Input() inlineFieldClass: string = 'col-md-9';
  @Input() errorText: string = '';
  @Input() labelText: string = '';
  @Input() placeholderText: string = '';
  @Input('required') _required = 'false';
  get required() {
    return (this._required == 'true');
  }
  @Input('displayInline') _displayInline = 'true';
  get displayInline() {
    return (this._displayInline == 'true');
  }

  constructor() { }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

}




import { YearLevelModel, InteractiveModuleStyleModel, AcademicFocusModel, LocationModel } from './';

export class InteractiveModuleTemplateModel {
    Id: number;
    AcademicFocusId: number;
    Title: String;
    UrlName: String;
    ShortDescription: String;
    Description: String;
    Requirements: String;
    Intention: String;
    TotalSessions: number;
    Price: number;
    InviteOnly: boolean;
    MaxEnrolmentsTotal: number;
    MaxEnrolmentsSchool: number;
    InteractiveModuleStyleId: number;
    StorageFolderName: string;
    TileImageName: string;
    LocationId: number;
    IsOnsite: boolean;
    DiscountForRemainingSessions: boolean;

    YearLevels: YearLevelModel[];
    InteractiveModuleStyle: InteractiveModuleStyleModel;
    AcademicFocus: AcademicFocusModel;
    Location: LocationModel;

    constructor() {
        this.Title = "";
        this.UrlName = "";
        this.ShortDescription = "";
        this.Description = "";
        this.Requirements = "";
        this.Intention = "";
        this.YearLevels = [];
        this.StorageFolderName = "";
        this.TileImageName = "";
        this.IsOnsite = false;
        this.DiscountForRemainingSessions = false;
    }
}
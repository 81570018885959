<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-3">
      <div class="form-group">
        <label class="control-label">State</label>
        <select class="form-control" [(ngModel)]="filterState">
          <option value="">Select state</option>
          <option *ngFor="let state of states" [value]="state.Id">{{state.Description}}</option>
        </select>
      </div>
    </div>

    <div class="col-3">
      <div class="form-group">
        <label class="control-label">School Name</label>
        <input type="text" class="form-control" [(ngModel)]="filterName">
      </div>
    </div>
    <div class="col-3"></div>
    <div class="col-3 text-right">
      <button class="btn btn-dark" (click)="Create()"><i class="fa fa-plus"></i> Create School</button>
    </div>

  </div>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12">

      <div class="card mt-3">
        <div class="card-header bg-dark text-light">
          <h5 class="card-title">List</h5>
        </div>
        <div class="card-body bg-light text-dark">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>School</th>
                <th>Suburb</th>
                <th>State</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="FilteredSchools().length==0">
                <td class="text-center" colspan="4">
                  <h5>No Schools Located</h5>
                  <button class="btn btn-dark" (click)="Create()"><i class="fa fa-plus"></i> Create School</button>
                </td>
              </tr>
              <tr *ngFor="let school of FilteredSchools()">
                <td>{{school.Name}}</td>
                <td>{{school.Suburb}}</td>
                <td>{{school.State}}</td>
                <td>
                  <button class="btn btn-sm" (click)="Edit(school)"><i class="fa fa-edit"></i> Edit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>

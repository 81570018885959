<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading && learningModule && !enrolmentComplete">

    <div class="col-sm-12">
      <div class="card mb-2">
        <div class="card-header bg-dark text-light">
          <h3 class="card-title">Module</h3>
        </div>
        <div class="card-body">
          <p>You are enrolling in the module <strong>{{learningModule.Title}}.</strong></p>
          <h4>Total Cost: AUD${{GetTotalPrice()|number:'1.2-2'}}</h4>
        </div>
      </div>
    </div>

    <div class="col-sm-12" *ngIf="alreadyEnrolled">
      <div class="alert alert-warning mt-2">
        <h4>Already Enrolled</h4>
        <p>You (or your children) are already enrolled in this module. Please visit <strong><a [routerLink]="['/learning-modules/',learningModule.UrlName]">{{learningModule.Title}}</a></strong> to access the content.</p>
      </div>
    </div>

    <div class="col-sm-12" *ngIf="!alreadyEnrolled">
      <div class="card mb-2">
        <div class="card-header bg-dark text-light">
          <h3 class="card-title">Children who will participate</h3>
        </div>
        <div class="card-body">
          <p>Please select the children who will participate in this module.</p>
          <table class="table">
            <tr>
              <th>Child</th>
              <th>Username</th>
              <th>Date of Birth</th>
              <th class="text-center">Participate?</th>
            </tr>
            <tr *ngFor="let child of children">
              <td>{{child.User.FirstName}} {{child.User.Surname}}</td>
              <td>{{child.User.Username}}</td>
              <td>{{child.DateOfBirth | date:'dd/MM/yyyy'}}</td>
              <td class="text-center"><input class="" type="checkbox" value="" id="defaultCheck1" [(ngModel)]="child.Selected"></td>
            </tr>
            <tr>
              <td colspan="4" class="text-right">
                <button class="btn btn-dark text-light" (click)="addChild()"><i class="fa fa-plus"></i> Add new child</button>
              </td>
            </tr>
          </table>
          <span class="text-danger d-block" *ngIf="FieldError('NoChildren')">You must select at least 1 child</span>
        </div>
      </div>

    </div>

    <div class="col-sm-12" *ngIf="!alreadyEnrolled">

      <div class="card mb-2">
        <div class="card-header bg-dark text-light">
          <h3 class="card-title">Payment</h3>
        </div>
        <div class="card-body">
          <p>Please enter your payment details below. A total of <strong>AUD${{GetTotalPrice()|number:'1.2-2'}}</strong> will be debited.</p>
          <div class="form-group">
            <label>Name on Card (*)</label>
            <input type="text" class="form-control" id="txtNameOnCard" name="txtNameOnCard" [(ngModel)]="enrolmentRequest.NameOnCard">
            <span class="text-danger" *ngIf="FieldError('NameOnCard')">Name on card required</span>
          </div>

          <div class="form-group">
            <label>Card Number (*)</label>
            <input type="text" class="form-control" id="txtCardNumber" name="txtCardNumber" [(ngModel)]="enrolmentRequest.CardNumber" maxlength="19">
            <span class="text-danger" *ngIf="FieldError('CardNumber')">Card Number required</span>
            <span class="text-danger" *ngIf="FieldError('InvalidCardNumber')">Card Number is invalid</span>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-4">
              <label>Expiry Month (*)</label>
              <select id="ddlExpiryMonth" name="ddlExpiryMonth" class="form-control" [(ngModel)]="enrolmentRequest.ExpiryMonth">
                <option value="" selected>Choose...</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              <span class="text-danger" *ngIf="FieldError('ExpiryMonth')">Expiry Month required</span>
            </div>
            <div class="form-group col-sm-4">
              <label>Expiry Year (*)</label>
              <select id="ddlExpiryYear" name="ddlExpiryYear" class="form-control" [(ngModel)]="enrolmentRequest.ExpiryYear">
                <option value="" selected>Choose...</option>
                <option *ngFor="let year of expiryYears" [value]="year">{{year}}</option>
              </select>
              <span class="text-danger" *ngIf="FieldError('ExpiryYear')">Expiry Year required</span>
            </div>
            <div class="form-group col-sm-4">
              <label>CVV (*)</label>
              <input type="text" class="form-control" id="txtCVV" name="txtCVV" [(ngModel)]="enrolmentRequest.Cvv" maxlength="4">
              <span class="text-danger" *ngIf="FieldError('Cvv')">CVV required</span>
            </div>
          </div>

        </div>

        <div class="card-footer">
          <button type="button" class="btn btn-lg btn-gateways w-100" (click)="BuyNow()" *ngIf="!processing">Buy Now</button>
          <button type="button" class="btn btn-lg btn-dark w-100" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i>&nbsp;Processing...</button>
        </div>

      </div>

      <div class="alert alert-danger" *ngIf="gatewayError">
        <span>There was a problem processing your payment.</span>
        <!-- <div *ngIf="responseTran">
          <strong>{{responseTran.ResponseText}}</strong>
        </div> -->
      </div>

    </div>
  </div>

  <div class="row" *ngIf="!loading && learningModule && enrolmentComplete">
    <div class="col-sm-12">
      <div class="alert alert-success">
        Congratulations, you have successfully enrolled in <strong>{{learningModule.Title}}</strong>.
      </div>

      <div class="card">
        <div class="card-header bg-dark text-light">
          <h3 class="card-title">
            Next Steps
          </h3>
        </div>
        <div class="card-body">
          <p>Now that you are enrolled, you can enjoy the <strong><a [routerLink]="['/learning-modules/',learningModule.UrlName]">{{learningModule.Title}}</a></strong> learning module.</p>
          <p>Both your account and the accounts for the children selected can access this Learning Module. The logon details for your children, as well as other management functions, can be found below and in your menu.</p>
          <p>As a parent, you also have the following capabilities</p>
          <ul>
            <li><a [routerLink]="['/parent']">My dashboard</a></li>
            <li><a [routerLink]="['/parent/edit']">My details</a></li>
            <li><a [routerLink]="['/parent/children']">My children</a></li>
            <li><a [routerLink]="['/parent/enrolments']">My enrolments</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <!-- REQUEST RESET -->
      <div class="card" *ngIf="!resetMode">

        <div class="card-header bg-dark text-white">Request Reset Password</div>
        <div class="card-body" *ngIf="!requestSuccess">
          <p>Please enter your username or email to request a password reset.</p>
          <form #thisForm="ngForm">
            <websilk-text-field id="txtUsername" name="txtUsername" [(ngModel)]="username" displayInline="false" labelText="Username / Email" required="true"></websilk-text-field>
            <div>
              <button class="btn btn-lg btn-gateways w-100" (click)="RequestPasswordReset(thisForm,$event)" *ngIf="!processing">Request Password Reset</button>
              <span class="btn btn-lg btn-dark w-100" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
            </div>
          </form>
        </div>

        <div class="card-body" *ngIf="requestSuccess">
          <div class="alert alert-success">
            A password reset request has been sent to the email registered on file. Please check your SPAM if it does not appear in your email within the next 5 minutes.
          </div>
        </div>
      </div>
      <!-- /REQUEST RESET -->
      <!-- REQUEST RESET -->
      <div class="card" *ngIf="resetMode">

        <div class="card-header bg-dark text-white">Reset Password</div>
        <div class="card-body" *ngIf="!requestSuccess || (requestSuccess && requestError)">
          <p>Please enter your new password below and confirm.</p>
          <form #thisResetForm="ngForm">
            <websilk-text-field id="txtPassword" name="txtPassword" [(ngModel)]="passwordReset.Password" displayInline="false" labelText="Password" required="true" pattern="" fieldType="password"></websilk-text-field>
            <websilk-text-field id="txtConfirmPassword" name="txtConfirmPassword" [(ngModel)]="passwordReset.ConfirmPassword" displayInline="false" labelText="Confirm" required="true" [pattern]="'^'+this.passwordReset.Password+'$'" fieldType="password" errorText="Must match password"></websilk-text-field>
            <div>
              <button class="btn btn-lg btn-gateways w-100" (click)="PasswordReset(thisResetForm,$event)" *ngIf="!processing">Reset Password</button>
              <span class="btn btn-lg btn-dark w-100" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
            </div>
            <div class="alert alert-danger mt-2" *ngIf="requestError">
              There was a problem processing your request. Please contact our offices.
            </div>
          </form>
        </div>

        <div class="card-body" *ngIf="requestSuccess">
          <div class="alert alert-success" *ngIf="!requestError">
            <p>Your password has been successfully reset.</p>
            <p>
              <a [routerLink]="['/login']">Proceed to Login</a>
            </p>
          </div>
        </div>

      </div>
      <!-- /REQUEST RESET -->
    </div>
  </div>
</div>

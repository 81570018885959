import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { forkJoin } from 'rxjs';
import { StateService, PresenterService, InteractiveModuleService, AuthService, InteractiveModuleTemplateService, ZoomAccountService } from '../../../../services';
import { PresenterModel, InteractiveModuleTemplateModel, InteractiveInstanceRequest, InteractiveInstanceRequestSession, UserModel, ZoomAccountModel } from '../../../../models';
import { FormFunctions, CustomDateParserFormatter } from '../../../../functions';
import { Validations } from '../../../../constants'


@Component({
    selector: 'page-admin-create-instances',
    templateUrl: './page-admin-create-instances.component.html',
    styleUrls: ['./page-admin-create-instances.component.scss']
})
export class PageAdminCreateInstancesComponent implements OnInit {
    loading: boolean = true;
    entity: InteractiveModuleTemplateModel;
    presenters: any[] = [];
    supervisors: any[] = [];
    zoomAccounts: ZoomAccountModel[] = [];
    request: InteractiveInstanceRequest = new InteractiveInstanceRequest();
    sessions: InteractiveInstanceRequestSession[] = [];
    repeatSessions: InteractiveInstanceRequestSession[] = [];
    showSuccess: boolean = false;
    showError: boolean = false;
    processing: boolean = false;

    constructor(public stateService: StateService, private modalService: NgbModal, private activatedRoute: ActivatedRoute, public validations: Validations, public formFunctions: FormFunctions, public presenterService: PresenterService, public interactiveModuleService: InteractiveModuleService, public authService: AuthService, public interactiveModuleTemplateService: InteractiveModuleTemplateService, public zoomAccountService: ZoomAccountService) {
        var me = this;
        var templateId = me.activatedRoute.snapshot.params.templateId;

        forkJoin(
            me.presenterService.FindAll(),
            me.authService.FindByRole('Supervisor'),
            me.interactiveModuleTemplateService.FindById(templateId),
            me.zoomAccountService.FindAll()
        ).subscribe(([data1, data2, data3, data4]) => {
            // Presenters
            me.presenters = data1.map(item => {
                return {
                    "id": item.Id,
                    "name": item.Firstname + ' ' + item.Surname
                }
            });
            me.supervisors = data2.map(item => {
                return {
                    "id": item.Id,
                    "name": item.FirstName + ' ' + item.Surname
                }
            });
            me.entity = data3;
            me.zoomAccounts = data4;

            me.InitialiseRequest();
            me.loading = false;
        });
    }

    ngOnInit() {

    }

    InitialiseRequest() {
        let me = this;
        me.request.InteractiveModuleTemplateId = me.entity.Id;
        me.request.Published = true;
        me.request.RepeatEvery = 0;
        me.request.Title = me.entity.Title;

        for (let i = 0; i < this.entity.TotalSessions; i++) {
            let newSession = new InteractiveInstanceRequestSession();
            me.sessions.push(me.formFunctions.DeepCopy(newSession));
            me.repeatSessions.push(me.formFunctions.DeepCopy(newSession));
        }
    }

    Create(form, e) {
        let me = this;
        if (this.formFunctions.isFormValid(form, e)) {
            me.request.Sessions = me.sessions;
            me.request.RepeatSessions = me.repeatSessions;

            me.processing = true;
            me.showError = false;
            me.showSuccess = false;

            this.interactiveModuleService.CreateFromTemplate(me.request).subscribe(data => {
                me.showSuccess = true;
                me.processing = false;
            }, err => {
                me.showSuccess = true;
                me.processing = false;
            });
        }
    }
    Time_keyUp(event: any) {
        // Always allow backspace
        if (event.key == "Backspace") {
            return;
        }
        // debugger;
        let newValue: String = event.target.value;

        // Only allow one colon, auto  add colon, and cut more than 4
        var colonCount = newValue.split(':').length - 1;
        newValue = newValue.split(':').join('');
        if (newValue.length == 2) {
            newValue = newValue + ':';
        }
        else if (newValue.length == 3) {
            newValue = newValue.substr(0, 1) + ':' + newValue.substr(1);
        }
        else if (newValue.length >= 4) {
            newValue = newValue.substr(0, 2) + ':' + newValue.substr(2, 2);
        }
        // item.SessionTime = newValue;
        event.target.value = newValue
    }

}

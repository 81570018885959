<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header bg-dark text-light">
          <span class="h5 card-title">New Enrolment</span>
        </div>
        <div class="card-body">

          <div class="row">

            <div class="col-sm-4">
              <div>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="searchStudentRequest.Firstname" placeholder="First name">
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="searchStudentRequest.Surname" placeholder="Surname">
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="searchStudentRequest.DateOfBirth" placeholder="dd/MM/yyyy">
                </div>
              </div>
              <div>
                <button class="btn btn-dark" (click)="SearchStudents()" *ngIf="!searching"><i class="fa fa-search"></i> Search</button>&nbsp;
                <button class="btn btn-light" (click)="ClearSearch()" *ngIf="!searching"><i class="fa fa-trash"></i> Clear</button>
                <span class="btn btn-dark disabled" *ngIf="searching"><i class="fa fa-spinner fa-spinning"></i> Searching...</span>
              </div>
            </div>

            <div class="col-sm-8">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Student</th>
                    <th>Date of Birth</th>
                    <th>Parent</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody *ngIf="searchStudentResults.length==0">
                  <tr>
                    <td colspan="5" class="text-center">
                      <h5>No Students Located</h5>
                      <button class="btn btn-dark" (click)="AddStudentModal()"><i class="fa fa-plus"></i> Create Student</button>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngIf="searchStudentResults.length>0">
                  <tr *ngFor="let result of searchStudentResults">
                    <td>{{result.User?.FirstName}} {{result.User?.Surname}}</td>
                    <td>
                      {{result.DateOfBirth|date:'dd/MM/yyyy'}}
                    </td>
                    <td>
                      {{result.Parent.User?.FirstName}} {{result.Parent.User?.Surname}}
                    </td>
                    <td>
                      <button class="btn btn-sm btn-gateways" (click)="EnrolStudent(result)">Enrol</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>

  <div class="row mt-3" *ngIf="!loading">
    <div class="col-sm-12">

      <div class="card">

        <div class="card-header bg-dark text-light">
          <span class="h5 card-title">Students</span>
        </div>

        <div class="card-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Student</th>
                <th>Date of Birth</th>
                <th>Parent</th>
                <th>Enrolment Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody *ngIf="enrolmentStudents.length==0">
              <tr>
                <td colspan="5" class="text-center">
                  <h5>No Enrolments</h5>
                  <button class="btn btn-dark"><i class="fa fa-plus"></i> Create</button>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="enrolmentStudents.length>0">
              <tr *ngFor="let enrolment of enrolmentStudents">
                <td>{{enrolment.Student.User?.FirstName}} {{enrolment.Student.User?.Surname}}</td>
                <td>
                  {{enrolment.Student.DateOfBirth|date:'dd/MM/yyyy'}}
                </td>
                <td>
                  {{enrolment.Student.Parent.User?.FirstName}} {{enrolment.Student.Parent.User?.Surname}}
                </td>
                <td>
                  {{enrolment.DateCreated|date:'dd/MM/yyyy'}}
                </td>
                <td>
                  <button class="btn btn-sm btn-dark mr-1" (click)="ExportInvoice(enrolment)"><i class="fa fa-file-pdf"></i></button>
                  <button class="btn btn-sm btn-danger" (click)="DeleteEnrolment(enrolment)"><i class="fa fa-trash"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

    </div>
  </div>
</div>

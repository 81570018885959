import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StateService, StudentService, ParentService } from '../../../services';

import { ModalResetPasswordComponent, ModalEditChildComponent, ModalAddChildComponent } from '../../../components';

import { StudentModel } from '../../../models';

@Component({
    selector: 'page-parent-children',
    templateUrl: './page-parent-children.component.html',
})
export class PageParentChildrenComponent implements OnInit {
    students: StudentModel[] = [];
    loading: boolean = true;

    constructor(public stateService: StateService, public studentService: StudentService, public parentService: ParentService, private modalService: NgbModal) {
        var me = this;

        me.LoadData();
    }

    ngOnInit() {
        let me = this;
    }
    LoadData() {
        let me = this;
        me.loading = true;
        me.parentService.FindMyStudents().subscribe(data => {
            me.students = data;
            me.loading = false;
        });
    }
    ResetPassword(student: StudentModel) {
        let me = this;
        const modalRef = me.modalService.open(ModalResetPasswordComponent);
        modalRef.componentInstance.user = student.User;
    }
    AddChild() {
        let me = this;
        const modalRef = me.modalService.open(ModalAddChildComponent, {
            scrollable: true
        });
        modalRef.componentInstance._includeExtra = 'true';
        modalRef.componentInstance.displayInline = 'false';
        modalRef.result.then((result) => {
            me.LoadData();
        });
    }
    EditChild(student: StudentModel) {
        let me = this;
        const modalRef = me.modalService.open(ModalEditChildComponent, {
            scrollable: true
        });
        modalRef.componentInstance.student = student;

        modalRef.result.then((result) => {
            me.LoadData();
        });
    }
}

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StateService, AuthService, CreditCouponService } from '../../../services';
import { CreditCouponModel } from '../../../models';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'page-parent-redeem-credit-coupon',
    templateUrl: './page-parent-redeem-credit-coupon.component.html',
})
export class PageParentRedeemCreditCouponComponent implements OnInit {
    myCoupons: CreditCouponModel[] = [];
    couponCode: string;
    myBalance: number = 0;
    loading: boolean = false;
    isSuccessful: boolean = false;
    isError: boolean = false;
    processing: boolean = false;

    constructor(public stateService: StateService, private modalService: NgbModal, public authService: AuthService, public creditCouponService: CreditCouponService) {
        var me = this;

        me.LoadData();
    }

    ngOnInit() {
        var me = this;
    }

    LoadData() {
        let me = this;

        forkJoin([
            me.creditCouponService.FindByUser(0)
        ]).subscribe(([data1]) => {
            me.myCoupons = data1;

            let sum = 0;
            me.myCoupons.forEach(o => sum += (o.Value - o.UsedValue));
            me.myBalance = sum;

            me.loading = false;
        });
    }

    RedeemCode() {
        let me = this;

        me.processing = true;
        me.isSuccessful = false;
        me.isError = false;

        me.creditCouponService.RedeemCode(me.couponCode).subscribe(data => {
            me.LoadData();
            me.isSuccessful = true;
            me.processing = false;
        }, err => {
            me.isError = true;
            me.processing = false;
        });

    }
}

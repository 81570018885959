import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import { StudentModel, StudentCreateRequest, LearningModuleEnrolmentStudentModel, SearchStudentRequestModel } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class StudentService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindAll(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<StudentModel[]>(this.appConfig.getConfig('apiUrl') + '/students', { withCredentials: true, headers: headers });
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<StudentModel>(this.appConfig.getConfig('apiUrl') + '/students/' + id, { withCredentials: true, headers: headers });
    }
    FindByParent(parentId: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<StudentModel[]>(this.appConfig.getConfig('apiUrl') + '/students/by-parent' + parentId, { withCredentials: true, headers: headers });
    }
    FindMyEnrolments(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<LearningModuleEnrolmentStudentModel[]>(this.appConfig.getConfig('apiUrl') + '/students/my-enrolments', { withCredentials: true, headers: headers });
    }
    Search(request: SearchStudentRequestModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<StudentModel[]>(this.appConfig.getConfig('apiUrl') + '/students/search', params, { withCredentials: true, headers: headers });
    }
    Create(request: StudentCreateRequest): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<StudentModel>(this.appConfig.getConfig('apiUrl') + '/students', params, { withCredentials: true, headers: headers });
    }
    CreateAdmin(request: StudentCreateRequest): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<StudentModel>(this.appConfig.getConfig('apiUrl') + '/students/create-admin', params, { withCredentials: true, headers: headers });
    }
    Update(request: StudentModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        var params = JSON.stringify(request);

        return this.http.post<StudentModel>(this.appConfig.getConfig('apiUrl') + '/students/' + request.Id, params, { withCredentials: true, headers: headers });
    }
}
<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading data... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">

    <div class="col-sm-12 pt-2" *ngIf="!entities.length">
      <div class="card h-100">
        <div class="card-body mt-1 text-center">
          <i class="fa fa-exclamation-triangle fa-2x"></i>
          <h3 class="card-title">No enrolments for today</h3>
          <p class="card-text">Your school does not have any enrolments scheduled for the requested date.</p>
        </div>
      </div>
    </div>

    <div class="col-sm-12" *ngIf="entities.length">
      <h2>Enrolments for {{requestDate|date:'dd/MM/yyyy'}}</h2>
      <table class="table">
        <thead>
          <tr>
            <th>Student</th>
            <th>Enrolled In</th>
            <th>Session Times</th>
            <th>Username</th>
            <th>Logon Token</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let entity of entities">
            <td>{{entity.Student.User?.FirstName}} {{entity.Student.User?.Surname}}</td>
            <td>{{entity.InteractiveModuleEnrolment.InteractiveModule.Title}}</td>
            <td>
              <span class="d-block" *ngFor="let session of GetSessions(entity)">
                <span class="d-inline-block">{{session.StartDateTime|date:'dd/MM/yyyy hh:mm aa'}} | </span>
                <a class="text-gateways d-inline-block ml-1" [href]="session.SessionLink" target="_blank"><i class="fa fa-external-link"></i>Join Session</a>
              </span>
            </td>
            <td>{{entity.Student.User?.Username}}</td>
            <td>{{entity.Student.User?.UniqueToken}}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

</div>

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService, AuthService, ZoomAccountService, InteractiveModuleSessionService } from '../../services';
import { ZoomAccountModel, CreateZoomSessionRequestModel } from '../../models';
import { FormFunctions, CustomDateParserFormatter } from '../../functions';
import { Validations } from '../../constants'

@Component({
    selector: 'modal-admin-create-zoom',
    templateUrl: './modal-admin-create-zoom.component.html'
})
export class ModalAdminCreateZoomComponent {
    @Input() sessionIds: number[];
    zoomAccounts: ZoomAccountModel[] = [];
    processing: boolean = false;
    success: boolean = false;
    error: boolean = false;
    errorMessage: string = "";
    request = new CreateZoomSessionRequestModel();

    constructor(public activeModal: NgbActiveModal, public stateService: StateService, public zoomAccountService: ZoomAccountService, public interactiveModuleSessionService: InteractiveModuleSessionService) {
        let me = this;
    }
    ngOnInit() {
        let me = this;

        me.request.SessionIds = me.sessionIds;
        me.zoomAccountService.FindAll().subscribe(data => {
            me.zoomAccounts = data;
        });
    }
    CreateZoomSessions() {
        let me = this;
        me.success = false;
        me.error = false;
        me.errorMessage = "";
        me.processing = true;

        me.interactiveModuleSessionService.CreateZoomSessions(me.request).subscribe(data => {
            if (data.Success) {
                me.success = true;
            }
            me.processing = false;
        }, err => {
            me.error = true;
            me.errorMessage = JSON.stringify(err);
            me.processing = false;
        });
    }
}
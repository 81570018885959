import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StateService, AuthService, ParentService } from '../../../services';
import { ModalResetPasswordComponent, ModalEditParentComponent } from '../../../components';

@Component({
    selector: 'page-parent-home',
    templateUrl: './page-parent-home.component.html',
})
export class PageParentHomeComponent implements OnInit {

    constructor(public stateService: StateService, private modalService: NgbModal, public authService: AuthService, public parentService: ParentService) {
        var me = this;
    }

    ngOnInit() {
        var me = this;
    }
    ResetPassword() {
        let me = this;
        me.authService.IsLoggedOn().subscribe(data => {
            const modalRef = me.modalService.open(ModalResetPasswordComponent);
            modalRef.componentInstance.user = data;
        });
    }
    EditDetails() {
        let me = this;
        me.parentService.FindMyDetails().subscribe(data => {
            const modalRef = me.modalService.open(ModalEditParentComponent, {
                scrollable: true
            });
            modalRef.componentInstance.entity = data;
        });
    }
}

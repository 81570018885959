import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable, Subject } from 'rxjs';
// services
import { AuthService, StateService } from '../services';

import { UserModel } from '../models';

@Injectable()
export class SignedInGuard implements CanActivate {
    constructor(public router: Router, public stateService: StateService, public authService: AuthService) {

    }
    canActivate() {
        var canActivate = this.stateService.userModel != null;
        this.authService.IsLoggedOn().subscribe(data => {
            if (!data) {
                this.stateService.changeUserModel(null);
            }
        }, err => {
            if (err.status == 401) {
                this.stateService.changeUserModel(null);
            }
        });

        return canActivate;

    }
}

import { LearningModuleEnrolmentModel, StudentModel } from './';

export class LearningModuleEnrolmentStudentModel {
    Id: number;
    LearningModuleEnrolmentId: number;
    StudentId: number;

    LearningModuleEnrolment: LearningModuleEnrolmentModel;
    Student: StudentModel;

    constructor() {

    }
}
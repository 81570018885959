import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { DatePipe } from "@angular/common";
import {
  NgModule,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// Modules
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { VgCoreModule, VgControlsModule } from "ngx-videogular";
import { RecaptchaModule } from "ng-recaptcha";
import { CKEditorModule } from "ng2-ckeditor";

import {
  NgbDateAdapter,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
// import { VgCoreModule } from 'videogular2/compiled/core';
// import { VgControlsModule } from 'videogular2/compiled/controls';

// Strategies
import {
  LocationStrategy,
  HashLocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
// App Settings
import { AppConfig, ServiceHelper } from "./config";
// App Guards
import {
  SignedInGuard,
  AdminRoleGuard,
  ParentRoleGuard,
  StudentRoleGuard,
  PresenterRoleGuard,
  SchoolRoleGuard,
  SupervisorRoleGuard,
} from "./guards";
// App Services
import {
  AuthService,
  RoleService,
  StateService,
  ParentService,
  StudentService,
  YearLevelService,
  SchoolService,
  GenericDataService,
  LearningModuleService,
  LearningModuleUnitService,
  LearningModuleEnrolmentService,
  LearningModuleEnrolmentStudentService,
  PaymentTransactionService,
  InteractiveModuleTemplateService,
  AcademicFocusService,
  PresenterService,
  InteractiveModuleService,
  InteractiveModuleTemplateResourceService,
  AWSS3Service,
  InteractiveModuleEnrolmentService,
  InteractiveModuleSessionService,
  ZoomAccountService,
  InteractiveModuleStyleService,
  ScriptService,
  AnalyticsService,
  TimetableService,
  LocationService,
  CreditCouponService,
} from "./services";

// Page Components
import {
  PageHomeComponent,
  PageLoginComponent,
  PageRegisterComponent,
  PageParentEnrolmentsComponent,
  PageParentHomeComponent,
  PageParentChildrenComponent,
  PageLearningModuleListComponent,
  PageLearningModuleItemComponent,
  PageGetStartedComponent,
  PageGetStartedPaymentComponent,
  PageStudentHomeComponent,
  PageForgotPasswordComponent,
  PageAdminInteractiveModuleTemplatesComponent,
  PageAdminDashboardComponent,
  PageAdminInteractiveModuleTemplateItemComponent,
  PageAdminAcademicFocusesComponent,
  PageAdminPresentersComponent,
  PageInteractiveModuleListComponent,
  PageInteractiveModuleItemComponent,
  PageInteractiveModuleEnrolComponent,
  PageInteractiveModuleEnrolFinaliseComponent,
  PageInteractiveInstanceComponent,
  PagePresenterHomeComponent,
  PageSchoolEnrolmentsComponent,
  PageSchoolEnrolmentsRoleComponent,
  PageAdminInteractiveModuleInstancesComponent,
  PageSupervisorHomeComponent,
  PageAdminInteractiveModuleInstanceEnrolmentsComponent,
  PageAdminZoomAccountsComponent,
  PageAdminCreateInstancesComponent,
  PageAdminInteractiveModuleStylesComponent,
  PageAdminUserListComponent,
  PageInteractiveModuleSessionCalendarComponent,
  PageAdminSessionListComponent,
  PageAdminAllEnrolmentsComponent,
  PageAdminSchoolsComponent,
  PageSupervisorProgramDetailComponent,
  PagePresenterProgramDetailComponent,
  PageAdminTimetablesComponent,
  PageAdminStudentsComponent,
  PageAdminParentsComponent,
  PageAdminLocationsComponent,
  PageParentRedeemCreditCouponComponent,
  PageAdminCreditCouponsComponent,
} from "./pages";

// Components
import {
  WebsilkTextField,
  WebsilkSelectField,
  WebsilkSelectAutoCompleteField,
  WebsilkLoading,
  LearningModuleCard,
  ModalBasicMessageComponent,
  WebsilkLoginComponent,
  WebsilkRegisterComponent,
  WebsilkDateField,
  ModalAddChildComponent,
  ModalResetPasswordComponent,
  ModalEditParentComponent,
  ModalEditChildComponent,
  ModalCreateInteractiveInstanceComponent,
  ModalAddTemplateResourceComponent,
  AdminTopMenuComponent,
  ModalInteractiveEnrolmentListComponent,
  InteractiveModuleInstanceGroupCardComponent,
  ModalAdminAddChildComponent,
  ModalEditUserComponent,
  ModalEditSessionComponent,
  ModalUploadImageComponent,
  AcademicFocusPill,
  InOutSchoolPill,
  ProgramStylePill,
  ModalAdminCreateZoomComponent,
  ModalAdminAssignSupervisorComponent,
  ModalEditSchoolComponent,
  ManageInteractiveSessionComponent,
  ModalAddUserRoleComponent,
  ModalAdminEditUserComponent,
  DeliveryPill,
  StripeCheckoutComponent,
} from "./components";

import { Validations } from "./constants";

import {
  FormFunctions,
  CustomDateAdapter,
  CustomDateParserFormatter,
} from "./functions";

// Load the app config in a factory
export function appConfigFactory(provider: AppConfig) {
  return () => provider.load();
}

@NgModule({
  declarations: [
    AppComponent,
    WebsilkTextField,
    WebsilkSelectField,
    WebsilkSelectAutoCompleteField,
    WebsilkLoading,
    LearningModuleCard,
    ModalBasicMessageComponent,
    WebsilkLoginComponent,
    WebsilkRegisterComponent,
    WebsilkDateField,
    ModalAddChildComponent,
    ModalResetPasswordComponent,
    ModalEditParentComponent,
    ModalEditChildComponent,
    ModalCreateInteractiveInstanceComponent,
    ModalAddTemplateResourceComponent,
    ModalInteractiveEnrolmentListComponent,
    ModalAdminAddChildComponent,
    ModalEditSessionComponent,
    AdminTopMenuComponent,
    InteractiveModuleInstanceGroupCardComponent,
    ModalEditUserComponent,
    ModalUploadImageComponent,
    AcademicFocusPill,
    InOutSchoolPill,
    ProgramStylePill,
    ModalAdminCreateZoomComponent,
    ModalAdminAssignSupervisorComponent,
    ModalEditSchoolComponent,
    ManageInteractiveSessionComponent,
    ModalAddUserRoleComponent,
    PageHomeComponent,
    PageLoginComponent,
    PageRegisterComponent,
    PageParentEnrolmentsComponent,
    PageParentHomeComponent,
    PageParentChildrenComponent,
    PageLearningModuleListComponent,
    PageLearningModuleItemComponent,
    PageGetStartedComponent,
    PageGetStartedPaymentComponent,
    PageStudentHomeComponent,
    PageForgotPasswordComponent,
    PageAdminDashboardComponent,
    PageAdminInteractiveModuleTemplatesComponent,
    PageAdminInteractiveModuleTemplateItemComponent,
    PageAdminAcademicFocusesComponent,
    PageAdminPresentersComponent,
    PageInteractiveModuleListComponent,
    PageInteractiveModuleItemComponent,
    PageInteractiveModuleEnrolComponent,
    PageInteractiveModuleEnrolFinaliseComponent,
    PageInteractiveInstanceComponent,
    PagePresenterHomeComponent,
    PageSchoolEnrolmentsComponent,
    PageSchoolEnrolmentsRoleComponent,
    PageAdminInteractiveModuleInstancesComponent,
    PageSupervisorHomeComponent,
    PageAdminInteractiveModuleInstanceEnrolmentsComponent,
    PageAdminZoomAccountsComponent,
    PageAdminCreateInstancesComponent,
    PageAdminInteractiveModuleStylesComponent,
    PageAdminUserListComponent,
    PageInteractiveModuleSessionCalendarComponent,
    PageAdminSessionListComponent,
    PageAdminAllEnrolmentsComponent,
    PageAdminSchoolsComponent,
    PageSupervisorProgramDetailComponent,
    PagePresenterProgramDetailComponent,
    PageAdminTimetablesComponent,
    PageAdminStudentsComponent,
    PageAdminParentsComponent,
    ModalAdminEditUserComponent,
    PageAdminLocationsComponent,
    DeliveryPill,
    PageParentRedeemCreditCouponComponent,
    PageAdminCreditCouponsComponent,
    StripeCheckoutComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    VgCoreModule,
    VgControlsModule,
    RecaptchaModule,
    CKEditorModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigFactory,
      deps: [AppConfig],
      multi: true,
    },
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe,
    SignedInGuard,
    AdminRoleGuard,
    ParentRoleGuard,
    StudentRoleGuard,
    PresenterRoleGuard,
    SchoolRoleGuard,
    SupervisorRoleGuard,
    AppConfig,
    Validations,
    FormFunctions,
    ServiceHelper,
    AuthService,
    RoleService,
    StateService,
    ParentService,
    StudentService,
    YearLevelService,
    SchoolService,
    GenericDataService,
    LearningModuleService,
    LearningModuleUnitService,
    LearningModuleEnrolmentService,
    LearningModuleEnrolmentStudentService,
    PaymentTransactionService,
    InteractiveModuleTemplateService,
    AcademicFocusService,
    PresenterService,
    InteractiveModuleService,
    InteractiveModuleTemplateResourceService,
    AWSS3Service,
    InteractiveModuleEnrolmentService,
    InteractiveModuleSessionService,
    ZoomAccountService,
    InteractiveModuleStyleService,
    ScriptService,
    AnalyticsService,
    TimetableService,
    LocationService,
    CreditCouponService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

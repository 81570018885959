import { Component, OnInit } from '@angular/core';

import { StateService, AWSS3Service } from '../../../services';

import { } from '../../../models';
import { } from 'src/app/components';

@Component({
    selector: 'page-admin-dashboard',
    templateUrl: './page-admin-dashboard.component.html',
})
export class PageAdminDashboardComponent implements OnInit {
    loading: boolean = true;
    selectedFiles: FileList;

    constructor(public stateService: StateService, public aWSS3Service: AWSS3Service) {
        var me = this;
        me.loading = false;
    }

    ngOnInit() {
        var me = this;
    }
    upload() {
        const file = this.selectedFiles.item(0);
        this.aWSS3Service.UploadFile("test", file, file.name).on('httpUploadProgress', function (evt) {
            console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
        }).send(function (err, data) {
            if (err) {
                console.log('There was an error uploading your file: ', err);
                return false;
            }
            console.log('Successfully uploaded file.', data);
            return true;
        });
    }

    selectFile(event) {
        this.selectedFiles = event.target.files;
    }
}

export class LocationModel {
    Id: number;
    Title: string;
    Address1: string;
    Address2: string;
    Suburb: string;
    State: string;
    Postcode: string;

    constructor() {
        this.Title = "";
        this.Address1 = "";
        this.Address2 = "";
        this.Suburb = "";
        this.State = "";
        this.Postcode = "";
    }
}
<div class="modal-header bg-dark text-white">
  <h4 class="modal-title">Edit Session</h4>
  <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="alert alert-success" *ngIf="showSuccess">
    <span>Session updated successfully</span>
  </div>

  <form #thisForm="ngForm" [class.d-none]="showSuccess">
    <div class="form-group">
      <label class="control-label" for="selPresenter">Presenter</label>
      <select class="form-control" id="selPresenter" name="selPresenter" [(ngModel)]="entity.PresenterId">
        <option value="">Please Select...</option>
        <option *ngFor="let presenter of presenters" [value]="presenter.Id">{{presenter.Firstname}} {{presenter.Surname}}</option>
      </select>
    </div>
    <div class="form-group">
      <label class="control-label" for="selPresenter">Supervisor</label>
      <select class="form-control" id="selPresenter" name="selSupervisor" [(ngModel)]="entity.SupervisorUserId">
        <option value="">Please Select...</option>
        <option *ngFor="let supervisor of supervisors" [value]="supervisor.Id">{{supervisor.FirstName}} {{supervisor.Surname}}</option>
      </select>
    </div>
  </form>

</div>
<div class="modal-footer">
  <button class="btn btn-gateways" (click)="SubmitRequest(thisForm,$event)" *ngIf="!processing && !showSuccess"><i class="fa fa-save"></i> Submit</button>
  <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')" *ngIf="!processing">Close</button>
</div>

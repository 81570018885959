import { Component, Input, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'websilk-text-field',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WebsilkTextField),
      multi: true
    }
  ],
  template:
    `
    <div *ngIf="displayInline" class="form-group row">
      <label for="{{id}}" class="col-form-label {{inlineLabelClass}}" *ngIf="labelText!=''">{{labelText}}</label>
      <div class="{{inlineFieldClass}}">
        <input *ngIf="!multiLine" type="{{fieldType}}" id="{{id}}" name="{{id}}" class="form-control" placeholder="{{placeholderText}}" [(ngModel)]="value" pattern="{{pattern}}" required="{{required}}" maxlength="{{maxLength}}">
        <textarea *ngIf="multiLine" id="{{id}}" name="{{id}}" class="form-control" placeholder="{{placeholderText}}" [(ngModel)]="value" pattern="{{pattern}}" required="{{required}}" maxlength="{{maxLength}}"></textarea>
        <span *ngIf="errorText!=''" class="error-label">{{errorText}}</span>
        <span *ngIf="errorText=='' && required" class="error-label">{{labelText}} is required</span>
      </div>
      <label *ngIf="trailingText!=''" class="col-form-label {{trailingTextInlineClass}}">{{trailingText}}</label>
    </div>

    <div *ngIf="!displayInline" class="form-group">
      <label for="{{id}}" class="form-label" *ngIf="labelText!=''">{{labelText}}</label>
      <div [class.input-group]="prependText!=''">
        <div class="input-group-prepend" *ngIf="prependText!=''"><span class="input-group-text">$</span></div>
        <input *ngIf="!multiLine" type="{{fieldType}}" id="{{id}}" name="{{id}}" class="form-control" placeholder="{{placeholderText}}" [(ngModel)]="value" pattern="{{pattern}}" required="{{required}}" maxlength="{{maxLength}}">
        <textarea *ngIf="multiLine" id="{{id}}" name="{{id}}" class="form-control" placeholder="{{placeholderText}}" [(ngModel)]="value" pattern="{{pattern}}" required="{{required}}" maxlength="{{maxLength}}"></textarea>
        <div *ngIf="trailingText!=''">{{trailingText}}</div>
        <span *ngIf="errorText!=''" class="error-label">{{errorText}}</span>
        <span *ngIf="errorText=='' && required" class="error-label">{{labelText}} is required</span>
      </div>
  </div>
  `
})
export class WebsilkTextField implements ControlValueAccessor {
  @HostBinding('attr.id')
  externalId = '';

  @Input() id: string;
  @Input('value') _value;
  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  @Input() inlineLabelClass: string = 'col-md-3 text-md-right';
  @Input() inlineFieldClass: string = 'col-md-9';
  @Input() errorText: string = '';
  @Input() labelText: string = '';
  @Input() placeholderText: string = '';
  @Input() pattern: string = '';
  @Input() trailingText: string = '';
  @Input() trailingTextInlineClass: string = '';
  @Input('required') _required = 'false';
  get required() {
    return (this._required == 'true');
  }

  @Input('multiLine') _multiLine = 'false';
  get multiLine() {
    return (this._multiLine == 'true');
  }


  @Input() maxLength: string = '';
  @Input() fieldType: string = 'text';
  @Input('displayInline') _displayInline = 'true';
  get displayInline() {
    return (this._displayInline == 'true');
  }

  @Input() prependText: string = '';

  constructor() { }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

}




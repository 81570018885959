<div class="container mt-4">
  <div class="row justify-content-center" *ngIf="status=='success'">
    <div class="col-md-8">
      <div class="alert alert-success">
        <i class="fa fa-tick fa-2x"></i>
        <span>Registration successful. Please log on below with your email and specified password.</span>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-6">
      <websilk-login id="wsLogin" (onSuccess)="loginCallback($event)"></websilk-login>
    </div>
  </div>
</div>

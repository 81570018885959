import { UserModel } from './';

export class CreditCouponModel {
    Id: number;
    Code: string;
    Value: number;
    UsedValue: number;
    AssignedUserId: number;

    AssignedUser: UserModel;

    constructor() {
        this.Code = "";
    }
}
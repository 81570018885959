<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12 pt-2">
      <ngb-tabset [destroyOnHide]="false">
        <ngb-tab>
          <ng-template ngbTabTitle>Interactive Programs</ng-template>
          <ng-template ngbTabContent>
            <div class="col-sm-12 mb-2">
              <div class="row">
                <div class="col-sm-8">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="chkFutureOnly" [(ngModel)]="individualFutureOnly" (change)="IndividualFutureChanged()">
                    <label class="form-check-label" for="chkFutureOnly">Showing upcoming sessions</label>
                  </div>
                </div>
                <div class="col-sm-4 text-right">
                  <button class="btn btn-dark" (click)="LoadData()"><i class="fa fa-refresh"></i> Refresh</button>
                </div>
              </div>
            </div>

            <div class="col-sm-12 pt-2" *ngIf="!iEnrolments.length">
              <div class="card h-100">
                <div class="card-body mt-1 text-center">
                  <i class="fa fa-exclamation-triangle fa-2x"></i>
                  <h3 class="card-title">No enrolments?</h3>
                  <p class="card-text">It doesn't seem you are currently enrolled in any interactive programs.</p>
                </div>
              </div>
            </div>

            <div class="card bg-light mb-2" *ngFor="let iEnrolment of iEnrolments">
              <div class="card-body">
                <h4 class="text-purple">{{iEnrolment.InteractiveModule.InteractiveModuleTemplate.Title}}</h4>

                <p>
                  {{iEnrolment.InteractiveModule.ShortDescription}}
                </p>

                <div class="card bg-white font-weight-bold mt-2 mb-2" *ngIf="iEnrolment.InteractiveModule.Location">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <div>
                          <i class="fa fa-building mr-2"></i>{{iEnrolment.InteractiveModule.Location.Title}}
                        </div>
                        <div class="">
                          <i class="fa fa-map-marker mr-2"></i><span>{{iEnrolment.InteractiveModule.Location.Address1}} {{iEnrolment.InteractiveModule.Location.Address2}}, {{iEnrolment.InteractiveModule.Location.Suburb}} {{iEnrolment.InteractiveModule.Location.State}} {{iEnrolment.InteractiveModule.Location.Postcode}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <a [routerLink]="['/interactive-modules/instance/',iEnrolment.InteractiveModule.Id]" class="btn btn-sm btn-gateways text-white">View Details</a>
                </div>

                <div class="mt-2">
                  <span class="badge badge-dark mr-2" *ngFor="let studentEnrolment of iEnrolment.InteractiveModuleEnrolmentStudents">{{studentEnrolment.Student.User?.FirstName}} {{studentEnrolment.Student.User?.Surname}}</span>
                </div>

                <div class="card bg-white font-weight-bold mt-2 mb-2" *ngFor="let session of iEnrolment.InteractiveModule.InteractiveModuleSessions; let i = index">

                  <div class="card-body">

                    <div class="row">

                      <div class="col-sm-7">
                        <div>
                          <div class="d-inline-block pr-4">
                            <i class="fa fa-calendar"></i> {{session.StartDateTime|date:'dd/MM/yyyy'}}
                          </div>
                          <div class="d-inline-block pr-4">
                            <i class="fa fa-clock"></i> {{session.StartDateTime|date:'hh:mm aa'}} - {{session.FinishDateTime|date:'hh:mm aa'}}
                          </div>
                          <div class="d-inline-block">
                            Presenter: {{session.Presenter.Firstname}} {{session.Presenter.Surname}}
                          </div>
                          <!-- <hr class="mt-1 mb-1" *ngIf="iEnrolment.InteractiveModule.InteractiveModuleSessions.length > 1 && i!=(iEnrolment.InteractiveModule.InteractiveModuleSessions.length-1)" /> -->
                        </div>
                      </div>

                      <div class="col-sm-5 text-right">
                        <div class="d-inline-block" *ngIf="!iEnrolment.InteractiveModule.IsOnsite">
                          <a class="btn btn-sm btn-dark text-white" [href]="session.SessionLink">Join session</a>
                        </div>
                        <div class="d-inline-block" *ngIf="iEnrolment.InteractiveModule.IsOnsite">
                          <delivery-pill [IsOnsite]="true"></delivery-pill>
                        </div>
                      </div>

                    </div>

                  </div>

                </div>
              </div>
            </div>

          </ng-template>
        </ngb-tab>

        <ngb-tab>
          <ng-template ngbTabTitle>On Demand Programs</ng-template>
          <ng-template ngbTabContent>
            <div class="row">
              <div class="col-sm-12 pt-2" *ngIf="!lEnrolments.length">
                <div class="card h-100">
                  <div class="card-body mt-1 text-center">
                    <i class="fa fa-exclamation-triangle fa-2x"></i>
                    <h3 class="card-title">No enrolments?</h3>
                    <p class="card-text">It doesn't seem you are currently enrolled in any modules.</p>
                  </div>
                </div>
              </div>

              <div class="col-sm-4 mt-2 mb-2" *ngFor="let lEnrolment of lEnrolments">
                <learning-module-card [model]="lEnrolment.LearningModule"></learning-module-card>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>



  </div>
</div>




<!-- <div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-12 pt-2">


      <ngb-tabset [destroyOnHide]="false">
        <ngb-tab>
          <ng-template ngbTabTitle>Interactive Programs</ng-template>
          <ng-template ngbTabContent>
            <div class="col-sm-12 pt-2" *ngIf="!iEnrolments.length">
              <div class="card h-100">
                <div class="card-body mt-1 text-center">
                  <i class="fa fa-exclamation-triangle fa-2x"></i>
                  <h3 class="card-title">No enrolments?</h3>
                  <p class="card-text">It doesn't seem you are currently enrolled in any interactive programs.</p>
                </div>
              </div>
            </div>

            <div class="col-sm-12 mt-2 mb-2" *ngFor="let iEnrolment of iEnrolments">
              <div class="card">
                <div class="card-header text-light bg-dark">
                  <h5 class="card-title">{{iEnrolment.InteractiveModule.InteractiveModuleTemplate.Title}}</h5>
                </div>
                <div class="card-body p-0">
                  <div class="row m-0">
                    <div class="col-sm-6 p-4">
                      <p>
                        {{iEnrolment.InteractiveModule.ShortDescription}}
                      </p>
                      <ul>
                        <li *ngFor="let studentEnrolment of iEnrolment.InteractiveModuleEnrolmentStudents">{{studentEnrolment.Student.User?.FirstName}} {{studentEnrolment.Student.User?.Surname}}</li>
                      </ul>
                    </div>
                    <div class="col-sm-6 pr-0">
                      <ul class="list-group square-corners">
                        <li class="list-group-item square-corners" *ngFor="let session of iEnrolment.InteractiveModule.InteractiveModuleSessions; let i=index">
                          <strong class="d-block">Session {{i+1}}</strong>
                          <span class="d-block">{{session.StartDateTime|date:'dd/MM/yyyy'}} {{session.StartDateTime|date:'HH:mm'}} - {{session.FinishDateTime|date:'HH:mm'}}</span>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
                <div class="card-footer p-0">
                  <a [routerLink]="['/interactive-modules/instance/',iEnrolment.InteractiveModule.Id]" class="btn btn-lg btn-gateways w-100 btn-square-top">View Details</a>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-tab>

        <ngb-tab>
          <ng-template ngbTabTitle>On Demand Programs</ng-template>
          <ng-template ngbTabContent>
            <div class="row">
              <div class="col-sm-12 pt-2" *ngIf="!lEnrolments.length">
                <div class="card h-100">
                  <div class="card-body mt-1 text-center">
                    <i class="fa fa-exclamation-triangle fa-2x"></i>
                    <h3 class="card-title">No enrolments?</h3>
                    <p class="card-text">It doesn't seem you are currently enrolled in any modules.</p>
                  </div>
                </div>
              </div>

              <div class="col-sm-4 mt-2 mb-2" *ngFor="let lEnrolment of lEnrolments">
                <learning-module-card [model]="lEnrolment.LearningModule"></learning-module-card>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>

    </div>
    
  </div>
</div> -->

<!-- <div class="row jumbotron mb-0" *ngIf="featureModule" [style.background-image]="getFeatureBackground(featureModule)">

  <div class="container text-white">

    <h1 class="display-4">{{featureModule.Title}}</h1>
    <p class="lead">{{featureModule.ShortDescription}}</p>
    <hr class="my-4">
    <a class="btn btn-lg btn-gateways" role="button" [routerLink]="['/learning-modules',featureModule.UrlName]">Learn More</a>
  </div>
</div>

<div class="bg-light mt-0 pt-4 pb-4" *ngIf="learningModules">
  <div class="container">
    <h1 class="text-center">On Demand Programs</h1>
    <div class="row">
      <div class="col-sm-4 mt-2 mb-2" *ngFor="let learningModule of learningModules">
        <learning-module-card [model]="learningModule"></learning-module-card>
      </div>
    </div>
  </div>
</div> -->


<div class="row pb-3 mb-0" style="background-color:#4a2261;">
  <div class="container">
    <div class="row">
      <div class="col-2"></div>
      <div class="col-8">
        <img class="w-100" src="/assets/images/gateways-online.png">
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</div>

<div class="row bg-card-grey">
  <div class="container">

    <div class="row mt-5">
      <div class="col-12 text-center">
        <h2 class="text-dark font-weight-bold">Explore our Live &amp; Interactive programs</h2>
        <p>Live and interactive programs will allow your child to learn in new and exciting ways...</p>
      </div>
    </div>

    <div class="row mt-5 mb-5">
      <div class="col-3 mb-2" *ngFor="let item of academicFocuses">
        <div class="card h-100 text-dark bg-card-grey" style="border:0 none;">
          <div class="card-body text-center">
            <img [src]="GetImageUrl(item)" style="height:60px;">
            <h5 class="card-title font-weight-bold mt-2">{{item.Title}}</h5>
            <p class="card-text">{{item.Summary}}</p>
            <div class="mt-3">
              <button class="btn btn-gateways" (click)="NavigateToItem(item)">Browse</button>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>

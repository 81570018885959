<div class="modal-header bg-dark text-white">
  <h4 class="modal-title">Enrolments</h4>
  <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <table class="table">
    <thead>
      <tr>
        <th>Student</th>
        <th>D.O.B</th>
        <th>Username</th>
        <th>Token</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let enrolment of EnrolmentStudents()">
        <td>
          {{enrolment.Student.User?.FirstName}} {{enrolment.Student.User?.Surname}}
        </td>
        <td>
          {{enrolment.Student.DateOfBirth|date:'dd/MM/yyyy'}}
        </td>
        <td>{{enrolment.Student.User?.Username}}</td>
        <td>{{enrolment.Student.User?.UniqueToken}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
</div>

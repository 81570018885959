import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import {
    InteractiveModuleSessionModel,
    CreateZoomSessionRequestModel,
    GenericResponseModel,
    AssignSupervisorRequestModel
} from '../models';
import { AppConfig } from '../config';

@Injectable()
export class InteractiveModuleSessionService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindAll(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleSessionModel[]>(this.appConfig.getConfig('apiUrl') + '/interactive-module-sessions', { withCredentials: true, headers: headers });
    }
    FindBySupervisor(supervisorUserId: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleSessionModel[]>(this.appConfig.getConfig('apiUrl') + '/interactive-module-sessions/by-supervisor/' + supervisorUserId, { withCredentials: true, headers: headers });
    }
    FindByDateRange(startDate: Date, endDate: Date): Observable<any> {
        let me = this;
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleSessionModel[]>(this.appConfig.getConfig('apiUrl') + '/interactive-module-sessions/by-date-range/' + me.FormatDateString(startDate) + '/' + me.FormatDateString(endDate), { withCredentials: true, headers: headers });
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<InteractiveModuleSessionModel>(this.appConfig.getConfig('apiUrl') + '/interactive-module-sessions/' + id, { withCredentials: true, headers: headers });
    }
    CreateZoomSessions(item: CreateZoomSessionRequestModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<GenericResponseModel>(this.appConfig.getConfig('apiUrl') + '/interactive-module-sessions/create-zoom-sessions', params, { withCredentials: true, headers: headers });
    }
    AssignSupervisor(item: AssignSupervisorRequestModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<GenericResponseModel>(this.appConfig.getConfig('apiUrl') + '/interactive-module-sessions/assign-supervisor', params, { withCredentials: true, headers: headers });
    }
    Create(item: InteractiveModuleSessionModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<InteractiveModuleSessionModel>(this.appConfig.getConfig('apiUrl') + '/interactive-module-sessions', params, { withCredentials: true, headers: headers });
    }
    Edit(item: InteractiveModuleSessionModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<InteractiveModuleSessionModel>(this.appConfig.getConfig('apiUrl') + '/interactive-module-sessions/' + item.Id, params, { withCredentials: true, headers: headers });
    }
    UpdatePresenter(item: InteractiveModuleSessionModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<InteractiveModuleSessionModel>(this.appConfig.getConfig('apiUrl') + '/interactive-module-sessions/update-presenter', params, { withCredentials: true, headers: headers });
    }
    FormatDateString(date: Date) {
        let useDate = new Date(date);
        let useMonthStr = (useDate.getMonth() + 1) < 10 ? '0' + (useDate.getMonth() + 1) : (useDate.getMonth() + 1);
        let useDayStr = useDate.getDate() < 10 ? '0' + useDate.getDate() : useDate.getDate();

        let dateStr = useDate.getFullYear() + '-' + useMonthStr + '-' + useDayStr;
        return dateStr;
    }
}
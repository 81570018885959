import { Component, OnInit } from '@angular/core';

import { StateService, InteractiveModuleStyleService } from '../../../services';
import { FormFunctions } from '../../../functions';
import { InteractiveModuleStyleModel } from '../../../models';

@Component({
    selector: 'page-admin-interactive-module-styles',
    templateUrl: './page-admin-interactive-module-styles.component.html',
})
export class PageAdminInteractiveModuleStylesComponent implements OnInit {
    loading: boolean = true;
    processing: boolean = false;
    entities: InteractiveModuleStyleModel[] = [];
    entity: InteractiveModuleStyleModel = new InteractiveModuleStyleModel();

    constructor(public stateService: StateService, private formFunctions: FormFunctions, public interactiveModuleStyleService: InteractiveModuleStyleService) {
        var me = this;
        me.LoadEntities();
    }

    ngOnInit() {
        var me = this;
    }

    LoadEntities() {
        let me = this;
        me.loading = true;
        me.interactiveModuleStyleService.FindAll().subscribe(data => {
            me.entities = data;
            me.loading = false;
        });
    }

    Edit(item: InteractiveModuleStyleModel) {
        let me = this;
        var clone = <InteractiveModuleStyleModel>me.formFunctions.DeepCopy(item);
        me.entity = clone;
    }
    Save(form, e) {
        let me = this;
        if (me.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            if (me.entity.Id) {
                me.interactiveModuleStyleService.Edit(me.entity).subscribe(data => {
                    me.processing = false;
                    me.entity = new InteractiveModuleStyleModel();
                    me.LoadEntities();
                });
            } else {
                me.interactiveModuleStyleService.Create(me.entity).subscribe(data => {
                    me.processing = false;
                    me.entity = new InteractiveModuleStyleModel();
                    me.LoadEntities();
                });
            }
        }
    }
    Cancel() {
        let me = this;
        me.entity = new InteractiveModuleStyleModel();
        me.LoadEntities();
    }
}

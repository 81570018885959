import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { StateService, LearningModuleService, ParentService, StudentService, AuthService } from '../../../services';

import { LearningModuleModel, LearningModuleTypeModel, StudentModel, ParentModel, Authenticate } from '../../../models';
import { identifierModuleUrl } from '@angular/compiler';

@Component({
    selector: 'page-get-started-list',
    templateUrl: './page-get-started.component.html',
})
export class PageGetStartedComponent implements OnInit {
    loading: boolean = true;
    learningModule: LearningModuleModel;
    children: StudentModel[] = [];

    constructor(private activatedRoute: ActivatedRoute, private router: Router, public stateService: StateService, public learningModuleService: LearningModuleService, public parentService: ParentService, public studentService: StudentService, public authService: AuthService) {
        var me = this;
        let urlName = me.activatedRoute.snapshot.params.urlName;
        // Get the learning module to register for
        me.learningModuleService.FindByUrl(urlName).subscribe(data => {
            me.learningModule = data;
            me.loading = false;
            me.checkLoggedIn();
        });
    }

    ngOnInit() {
        var me = this;
    }

    checkLoggedIn() {
        let me = this;
        me.authService.IsLoggedOn().subscribe(data => {
            // Already logged in so no need for this screen
            me.navigateFinalise();
        }, err => {
            // Do nothing here
        });
    }

    getCurrentUrl() {
        var me = this;
        return "/learning-module/get-started/" + me.learningModule.UrlName;
    }

    GetParentAndChildren() {
        var me = this;
        // Get the current parent and children (if logged on)
        me.parentService.FindMyStudents().subscribe(data => {
            me.children = data;
        });
    }
    loginCallback(userId) {
        var me = this;
        me.navigateFinalise();
    }
    registerCallback(event) {
        var me = this;

        // Need to log in the new user for the final step
        let parentId = event[0];
        let userName = event[1];
        let password = event[2];

        let authModel = new Authenticate();
        authModel.UserName = userName;
        authModel.Password = password;
        me.authService.Login(authModel).subscribe(data => {
            me.stateService.changeUserModel(data);
            me.navigateFinalise();
        });
    }
    navigateFinalise() {
        var me = this;
        me.router.navigate(['/learning-modules/get-started/finalise', me.learningModule.UrlName]);
    }
}

import { LearningModuleTypeModel, YearLevelModel, PresenterModel } from './';

export class LearningModuleModel {
    Id: number;
    PresenterId: number;
    Title: string;
    UrlName: string;
    StorageFolderName: string;
    CoverImageName: string;
    ShortDescription: string;
    Description: string;
    Author: string;
    DisplayImage: string;
    Price: number;
    LearningModuleType: LearningModuleTypeModel;
    Presenter: PresenterModel;
    YearLevels: YearLevelModel[];

    constructor() {
        this.Title = "";
        this.UrlName = "";
        this.StorageFolderName = "";
        this.ShortDescription = "";
        this.CoverImageName = "";
        this.Description = "";
        this.Author = "";
        this.DisplayImage = "";
        this.YearLevels = [];
    }
}
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import { AcademicFocusModel, UpdateImageRequestModel } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class AcademicFocusService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindAll(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<AcademicFocusModel[]>(this.appConfig.getConfig('apiUrl') + '/academic-focuses', { withCredentials: true, headers: headers });
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<AcademicFocusModel>(this.appConfig.getConfig('apiUrl') + '/academic-focuses/' + id, { withCredentials: true, headers: headers });
    }
    UpdateImage(item: UpdateImageRequestModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<AcademicFocusModel>(this.appConfig.getConfig('apiUrl') + '/academic-focuses/update-image', params, { withCredentials: true, headers: headers });
    }
    Create(item: AcademicFocusModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<AcademicFocusModel>(this.appConfig.getConfig('apiUrl') + '/academic-focuses', params, { withCredentials: true, headers: headers });
    }
    Edit(item: AcademicFocusModel) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<AcademicFocusModel>(this.appConfig.getConfig('apiUrl') + '/academic-focuses/' + item.Id, params, { withCredentials: true, headers: headers });
    }
}
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import { GenericResponseModel, Timetable, TimetableType } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class TimetableService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }

    FindByType(type: TimetableType): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<Timetable>(this.appConfig.getConfig('apiUrl') + '/timetables/' + type, { withCredentials: true, headers: headers });
    }
    FindAll(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<Timetable>(this.appConfig.getConfig('apiUrl') + '/timetables/', { withCredentials: true, headers: headers });
    }
    Create(item: Timetable) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<Timetable>(this.appConfig.getConfig('apiUrl') + '/timetables/', params, { withCredentials: true, headers: headers });
    }
    Edit(item: Timetable) {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(item);

        return this.http.post<Timetable>(this.appConfig.getConfig('apiUrl') + '/timetables/' + item.Id, params, { withCredentials: true, headers: headers });
    }
    Delete(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.delete<GenericResponseModel>(this.appConfig.getConfig('apiUrl') + '/timetables/' + id, { withCredentials: true, headers: headers });
    }
}
import { UserModel } from './';

export class ParentModel {
    Id: number;
    Address1: string;
    Address2: string;
    Address3: string;
    Suburb: string;
    State: string;
    Postcode: string;
    Country: string;
    PhoneNumber: string;

    UserId: number;
    User: UserModel;

    constructor() {
        this.Address1 = "";
        this.Address2 = "";
        this.Address3 = "";
        this.Suburb = "";
        this.State = "";
        this.Postcode = "";
        this.Country = "";
        this.PhoneNumber = "";
    }
}
<div class="modal-header bg-dark text-white">
  <h4 class="modal-title">New Instance</h4>
  <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <h4>New instance for {{entity.Title}}</h4>
  <form #thisForm="ngForm">

    <div class="alert alert-success" *ngIf="showSuccess">
      Your new instances have been created.
    </div>

    <div class="alert alert-danger" *ngIf="showError">
      There was a problem processing your request, please contact your systems administrator
    </div>

    <div *ngIf="!showSuccess && !showError">
      <p>Repeating options (i.e. running once a week for 4 weeks in a block would be repeat every "7" days for 4 iterations)</p>
      <websilk-text-field id="txtTitle" name="txtTitle" [(ngModel)]="request.Title" labelText="Title" placeholderText="Title" required="true" displayInline="false"></websilk-text-field>
      <websilk-select-field id="selSupervisor" name="selSupervisor" [bindItems]="supervisors" [(ngModel)]="request.SupervisorUserId" labelText="Supervisor" idKey="id" descKey="name" required="true" displayInline="false" errorText="Supervisor is required"></websilk-select-field>
      <websilk-text-field id="txtRepeatEvery" name="txtRepeatEvery" [(ngModel)]="request.RepeatEvery" labelText="Repeat Every" trailingText="days" placeholderText="Days" required="true" pattern="^\d+$" displayInline="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-3" trailingTextInlineClass="col-sm-3" errorText="Required, 0 is default"></websilk-text-field>
      <websilk-text-field id="txtRepeatFor" name="txtRepeatFor" [(ngModel)]="request.RepeatFor" labelText="Repeat" trailingText="time" placeholderText="Frequency" required="true" pattern="^\d+$" displayInline="true" inlineLabelClass="col-sm-3 text-sm-left" inlineFieldClass="col-sm-3" trailingTextInlineClass="col-sm-3" errorText="Required, 0 is default"></websilk-text-field>
      <div class="form-group row">
        <label class="col-form-label col-sm-3">
          Repeat For Day?
        </label>
        <div class="col-sm-3">
          <input class="form-check-input" type="checkbox" [(ngModel)]="request.AreRepeatSessions" id="chkRepeatSessions" name="chkRepeatSessions">
        </div>
      </div>
      <!-- Primary Sessions -->
      <div>
        <p>Please enter the session times for this instance</p>
        <table class="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Duration</th>
              <th>Presenter</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let session of sessions;let i = index">
              <td>
                <websilk-date-field id="dtSessionDate_{{i}}" name="dtSessionDate_{{i}}" [(ngModel)]="session.SessionDate" labelText="" required="true" displayInline="false" errorText="Session Date is required"></websilk-date-field>
              </td>
              <td>
                <websilk-text-field id="txtSessionTime_{{i}}" name="txtSessionTime_{{i}}" [(ngModel)]="session.SessionTime" labelText="" required="true" pattern="^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" displayInline="false" errorText="Session Time is required"></websilk-text-field>
              </td>
              <td>
                <websilk-text-field id="txtSessionDurationMinutes_{{i}}" name="txtSessionDurationMinutes_{{i}}" placeholderText="Minutes" [(ngModel)]="session.SessionDurationMinutes" labelText="" required="true" pattern="^\d+$" displayInline="false" errorText="Duration is required"></websilk-text-field>
              </td>
              <td>
                <websilk-select-field id="selPresenter_{{i}}" name="selPresenter_{{i}}" [bindItems]="presenters" [(ngModel)]="session.PresenterId" labelText="" idKey="id" descKey="name" required="true" displayInline="false" errorText="Presenter is required"></websilk-select-field>
              </td>
            </tr>
            <tr>
              <td colspan="4">

              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Repeat Sessions (i.e. AM and PM) -->
      <div *ngIf="request.AreRepeatSessions">
        <p>Please enter the session times for the repeat instance (i.e. PM times)</p>
        <table class="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Duration</th>
              <th>Presenter</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let session of repeatSessions;let i = index">
              <td>
                <websilk-date-field id="dtRepeatSessionDate_{{i}}" name="dtRepeatSessionDate_{{i}}" [(ngModel)]="session.SessionDate" labelText="" required="true" displayInline="false" errorText="Session Date is required"></websilk-date-field>
              </td>
              <td>
                <websilk-text-field id="txtRepeatSessionTime_{{i}}" name="txtRepeatSessionTime_{{i}}" [(ngModel)]="session.SessionTime" labelText="" required="true" pattern="^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" displayInline="false" errorText="Session Time is required"></websilk-text-field>
              </td>
              <td>
                <websilk-text-field id="txtRepeatSessionDurationMinutes_{{i}}" name="txtSessionDurationMinutes_{{i}}" placeholderText="Minutes" [(ngModel)]="session.SessionDurationMinutes" labelText="" required="true" pattern="^\d+$" displayInline="false" errorText="Duration is required"></websilk-text-field>
              </td>
              <td>
                <websilk-select-field id="selRepeatPresenter_{{i}}" name="selRepeatPresenter_{{i}}" [bindItems]="presenters" [(ngModel)]="session.PresenterId" labelText="" idKey="id" descKey="name" required="true" displayInline="false" errorText="Presenter is required"></websilk-select-field>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer" *ngIf="!showSuccess && !showError">
  <button type="button" class="btn btn-gateways" (click)="Create(thisForm,$event)" *ngIf="!processing">Create</button>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')" *ngIf="!processing">Close</button>
  <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
</div>
<div class="modal-footer" *ngIf="showSuccess || showError">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>

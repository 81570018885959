import { Component, OnInit } from '@angular/core';

import { StateService, AuthService, RoleService } from '../../../services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ModalEditUserComponent, ModalResetPasswordComponent, ModalAddUserRoleComponent, ModalAdminEditUserComponent } from '../../../components';

import { UserModel, RoleModel } from '../../../models';

@Component({
    selector: 'page-admin-user-list',
    templateUrl: './page-admin-user-list.component.html',
})
export class PageAdminUserListComponent implements OnInit {
    loading: boolean = true;
    loadingUsers: boolean = false;
    entities: UserModel[] = [];
    filteredEntities: UserModel[] = [];
    roles: RoleModel[] = [];
    selectedRole: string = "";
    filterName: string = "";

    constructor(public stateService: StateService, public authService: AuthService, public roleService: RoleService, private modalService: NgbModal) {
        var me = this;

        me.roleService.FindAll().subscribe(data => {
            me.roles = data;
            me.loading = false;
        });
    }

    ngOnInit() {
        var me = this;
    }

    SearchUsers() {
        let me = this;

        me.loadingUsers = true;
        me.authService.FindByRole(me.selectedRole).subscribe(data => {
            me.entities = data;
            if (data.length <= 100) {
                me.filteredEntities = data;
            } else {
                me.filteredEntities = [];
            }
            me.loadingUsers = false;
        });
    }

    FilterEntities() {
        let me = this;
        let items = me.entities;

        if (me.filterName.length < 3) {
            me.filteredEntities = [];
            return
        }

        if (me.filterName) {
            items = items.filter(o => (o.FirstName + ' ' + o.Surname).toLowerCase().includes(me.filterName.toLowerCase()) || o.Email.toLowerCase().includes(me.filterName.toLowerCase()));
        }

        me.filteredEntities = items;
    }

    CreateUser() {
        // Launch Modal
        let me = this;
        const modalRef = me.modalService.open(ModalEditUserComponent, {
            scrollable: true,
            size: 'lg'
        });
        modalRef.result.then((result: any) => {
            if (result.success) {
                me.SearchUsers();
            }
        });
    }

    AddRole(entity: UserModel) {
        // Launch Modal
        let me = this;
        const modalRef = me.modalService.open(ModalAddUserRoleComponent, {
            scrollable: true
        });
        modalRef.componentInstance.user = entity;

        modalRef.result.then((result: any) => {
            me.SearchUsers();
        });
    }

    ResetPassword(entity: UserModel) {
        let me = this;
        const modalRef = me.modalService.open(ModalResetPasswordComponent, {
            scrollable: true
        });
        modalRef.componentInstance.user = entity;

        modalRef.result.then((result) => {

        });
    }

    EditUser(entity: UserModel) {
        let me = this;
        const modalRef = me.modalService.open(ModalAdminEditUserComponent, {
            scrollable: true,
            size: 'lg'
        });

        modalRef.componentInstance.entity = entity;

        modalRef.result.then((result: any) => {
            if (result.success) {
                me.SearchUsers();
            }
        });
    }
}

<div class="container">
  <websilk-loading *ngIf="loading" displayText="Loading modules... Please wait"></websilk-loading>

  <div class="row">
    <div class="col-sm-12">
      <a class="text-dark" [routerLink]="['/admin/interactive-module-templates']"><i class="fa fa-arrow-left"></i>&ngsp;Back to list</a>
    </div>
  </div>

  <form #thisForm="ngForm">
    <div class="row" *ngIf="!loading">
      <div class="col-sm-12">
        <div #failMessage class="alert alert-danger" [class.d-block]="editFail" [class.d-none]="!editFail">
          There was a problem saving your changes.
        </div>
        <div #successMessage class="alert alert-success" [class.d-block]="editSuccess" [class.d-none]="!editSuccess">
          Your changes have been successfully saved.
        </div>
        <div class="card">
          <div class="card-header bg-dark text-light">
            <h5 class="card-title">
              Module details
            </h5>
          </div>
          <div class="card-body bg-light text-dark">
            <div class="row">
              <div class="col-sm-12">
                <websilk-text-field id="txtTitle" name="txtTitle" [(ngModel)]="entity.Title" displayInline="false" labelText="Title" required="true" pattern=""></websilk-text-field>
                <div class="form-group" *ngIf="editMode">
                  <label class="form-label">Url Name</label>
                  <div>
                    <strong class="">{{entity.UrlName}}</strong>
                  </div>
                </div>

                <websilk-select-field id="selStyle" name="selStyle" [bindItems]="arrStyles" [(ngModel)]="entity.InteractiveModuleStyleId" labelText="Style" idKey="Id" descKey="Title" required="true" displayInline="false"></websilk-select-field>

                <div class="form-check mt-3 mb-3">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="entity.IsOnsite" id="chkIsOnsite" name="chkIsOnsite">
                  <label class="form-check-label" for="chkIsOnsite">
                    Is OnSite Program?
                  </label>
                  <small class="d-block">Leave this option un-ticked if it is an OnLine program</small>
                </div>

                <div class="form-check mt-3 mb-3">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="entity.IsOnsite" id="chkDiscount" name="chkDiscount">
                  <label class="form-check-label" for="chkDiscount">
                    Discount cost for late registrations
                  </label>
                  <small class="d-block">This will allow late registrations and only charge the parent for the sessions remaining.</small>
                </div>

                <websilk-select-field id="selLocation" name="selLocation" [bindItems]="arrLocations" [(ngModel)]="entity.LocationId" labelText="Location" idKey="Id" descKey="Title" required="false" displayInline="false" *ngIf="entity.IsOnsite"></websilk-select-field>
                <websilk-text-field id="txtShortDescription" name="txtShortDescription" [(ngModel)]="entity.ShortDescription" displayInline="false" labelText="Short Description" required="true" pattern=""></websilk-text-field>

                <div class="form-group">
                  <label class="control-label">Description</label>
                  <ckeditor id="ckeDescription" name="ckeDescription" required="true" [(ngModel)]="entity.Description" [config]="ckeConfig" [readonly]="false"></ckeditor>
                </div>
                <div class="form-group">
                  <label class="control-label">Requirements</label>
                  <ckeditor id="ckeRequirements" name="ckeRequirements" [(ngModel)]="entity.Requirements" [config]="ckeConfig" [readonly]="false"></ckeditor>
                </div>
                <div class="form-group">
                  <label class="control-label">Outcomes</label>
                  <ckeditor id="ckeIntention" name="ckeIntention" [(ngModel)]="entity.Intention" [config]="ckeConfig" [readonly]="false"></ckeditor>
                </div>

                <websilk-select-field id="selAcademicFocus" name="selAcademicFocus" [bindItems]="arrAcademicFocuses" [(ngModel)]="entity.AcademicFocusId" labelText="Academic Focus" idKey="Id" descKey="Title" required="true" displayInline="false"></websilk-select-field>
              </div>
              <div class="row m-0 p-0 w-100">
                <div class="col-sm-10">
                  <label class="form-label d-block">Year Levels</label>
                  <div class="form-check form-check-inline" *ngFor="let yearLevel of arrYearLevels">
                    <input class="form-check-input" [id]="'yearlevel_' + yearLevel.Id" [name]="'yearlevel_' + yearLevel.Id" type="checkbox" (change)="YearLevelChange(yearLevel)" [checked]="YearLevelChecked(yearLevel)">
                    <label class="form-check-label" [for]="'yearlevel_' + yearLevel.Id">{{yearLevel.Title}}</label>
                  </div>
                </div>
                <div class="col-sm-2 text-right">
                  <button class="btn btn-sm btn-dark mt-3" (click)="UpdateYearLevels()" *ngIf="!savingYears"><i class="fa fa-save"></i> Save year levels</button>
                  <span class="btn btn-sm btn-dark mt-3" *ngIf="savingYears"><i class="fa fa-spin fa-spinner"></i> Saving...</span>
                </div>
              </div>
              <div class="col-sm-6">
                <websilk-text-field id="txtPrice" name="txtPrice" [(ngModel)]="entity.Price" displayInline="false" labelText="Price" prependText="$" errorText="Please enter a valid monetary amount" required="true" pattern="^\$?(\d{1,3},?(\d{3},?)*\d{3}(\.\d{1,3})?|\d{1,3}(\.\d{2})?)$"></websilk-text-field>
              </div>
              <div class="col-sm-6">
                <websilk-text-field id="txtTotalSessions" name="txtTotalSessions" [(ngModel)]="entity.TotalSessions" displayInline="false" labelText="Total Sessions" required="true" pattern="" multiLine="false"></websilk-text-field>
              </div>
              <div class="col-sm-12">
                <websilk-select-field id="selInviteOnly" name="selInviteOnly" [bindItems]="arrTrueFalse" [(ngModel)]="entity.InviteOnly" labelText="Invite Only" idKey="Id" descKey="Description" required="true" displayInline="false"></websilk-select-field>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <websilk-text-field id="txtMaxEnrolmentsTotal" name="txtMaxEnrolmentsTotal" [(ngModel)]="entity.MaxEnrolmentsTotal" displayInline="false" labelText="Maximum Enrolments" required="true" pattern="" multiLine="false"></websilk-text-field>
              </div>
            </div>

            <div class="row" *ngIf="entity">
              <div class="col-sm-12">
                <img [src]="GetImageUrl(entity)" width="150px">
                <div class="mt-2">
                  <button class="btn btn-dark" *ngIf="entity.Id" (click)="ChangeImage()">Change Image</button>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-sm-6 text-left">
              <button type="button" class="btn btn-gateways" (click)="Save(thisForm,$event, successMessage, failMessage)" *ngIf="!processing">Save</button>
              <button type="button" class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i>&nbsp;Processing...</button>
            </div>
            <div class="col-sm-6 text-right">
              <a class="btn btn-dark" [routerLink]="['/admin/interactive-module-templates']">Cancel</a>
            </div>
          </div>


        </div>

      </div>
    </div>

  </form>

  <div class="row mt-3" *ngIf="!loading">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header bg-dark text-light">
          <h5 class="card-title">
            Resources
          </h5>
        </div>
        <div class="card-body bg-light text-dark">
          <div class="mb-2">
            <button class="btn btn-gateways" (click)="NewResource()">Add Resource</button>
          </div>
          <table class="table table-striped">
            <thead>
              <tr>
                <td>Title</td>
                <td>Description</td>
                <td>Filename</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let resource of resources; let i=index">
                <td>{{resource.Title}}</td>
                <td>{{resource.Description}}</td>
                <td>{{resource.ResourceFilename}}</td>
                <td>
                  <button class="btn btn-sm btn-danger" (click)="DeleteResource(resource)"><i class="fa fa-trash"></i> Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import { LearningModuleUnitModel } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class LearningModuleUnitService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindByLearningModule(learningModuleId: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<LearningModuleUnitModel[]>(this.appConfig.getConfig('apiUrl') + '/learning-module-units/by-learning-module/' + learningModuleId, { withCredentials: true, headers: headers });
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<LearningModuleUnitModel>(this.appConfig.getConfig('apiUrl') + '/learning-module-units/' + id, { withCredentials: true, headers: headers });
    }
}
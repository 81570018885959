export class AcademicFocusModel {
    Id: number;
    Title: string;
    Summary: string;
    StorageFolderName: string;
    TileImageName: string;

    constructor() {
        this.Title = "";
        this.Summary = "";
        this.StorageFolderName = "";
        this.TileImageName = "";
    }
}
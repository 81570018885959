import { UserModel } from './';
export class PresenterModel {
    Id: number;
    Firstname: string;
    Surname: string;
    Summary: string;
    UserId: number;

    User: UserModel;

    constructor() {
        this.Firstname = "";
        this.Surname = "";
        this.Summary = "";
    }
}